//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet'; 
import { connect } from 'react-redux';

//===============================================================================================================
// #2 - Import * Utils
//===============================================================================================================
import maxText from "../../utils/MaxText";

//===============================================================================================================
// #3 - Import * UI Components
//===============================================================================================================
import { Filter, FilterHead } from "../../components/Filters";
import OfficialStore from '../../components/OfficialStore';

//===============================================================================================================
// #4 - Import * Material UI Components
//===============================================================================================================
import Grid from "@material-ui/core/Grid";

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";

//===============================================================================================================
// #6 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronLeft, faTimes, faCheck } from "@fortawesome/pro-light-svg-icons";
import { faListUl, faStore } from "@fortawesome/pro-regular-svg-icons";


function OfficialStores (props) {

    // * Helpes
    const maxChars = 81;

    // * States
    const [categoriesPanel, setCategoriesPanel] = useState(false);
    const [brandsPanel, setBrandsPanel] = useState(false);

    // * Functions
    const handleCategoriesPanel = () => setCategoriesPanel(!categoriesPanel);
    const handleBrandsPanel = () => setBrandsPanel(!brandsPanel);

    return (

        <section id="view_partners">
            <Helmet title={`${props.site_properties?.title} - Parceiros`} />
            
            <section className="_wrap-help-search">
                <div className="_med-container">
                    <h1 className="_title">
                        Encontre uma loja parceira
                    </h1>
                    
                    <div className="_help-search">
                        {/* Search form */}
                        <div className="search-menu">
                            
                            <form className="form">
                                <div className="form-group search">
                                    <input type="text" placeholder="Digite o que esta buscando" />
                                    <button type="submit">
                                        <Icon icon={faSearch} className="fa-question-circle"/>
                                    </button>
                                </div>
                            </form>
                            
                        </div>{/* END ./ Search form */}
                    </div>
                </div>
            </section>
                
            <section className="_wrap-content">
                <div className="_med-container">

                    <div className="filter">
                    <form  className="_form-order-by">
                        <div className="_active-filters--mobile">
                            <button type="button" onClick={handleCategoriesPanel}>
                            Categorias
                            <Icon icon={faListUl} />
                            </button>
                            <button type="button" onClick={handleBrandsPanel}>
                            Marcas
                            <Icon icon={faStore} />
                            </button>
                        </div>

                        {/* Categories Filter */}
                        <div className={categoriesPanel ? "_filter-order _filter-show" : "_filter-order"}>
                            <FilterHead title="Filtrar">
                                <button type="button" className="_back-history" onClick={handleCategoriesPanel}>
                                    <Icon icon={faChevronLeft} />
                                </button>
                            </FilterHead>
                            
                            <Filter filterTitle="Categorias" open={true}>

                                <button type="button" className="_filter-selected">
                                    bebidas
                                    <Icon icon={faTimes} />
                                </button>
                                
                                <label className="_filter-label">
                                    <input type="radio" name="filter-input-name" />
                                    <span className="_filter-title">Beleza e bem estar</span>
                                </label>

                                <label className="_filter-label">
                                    <input type="radio" name="filter-input-name" />
                                    <span className="_filter-title">Consultoria e mentoria</span>
                                </label>
                                
                            </Filter>
                            <div className="_apply-finter-button">

                            <Button type="submit" color="primary" autoFocus className="apply-filters" >
                                Aplicar filtro <Icon icon={faCheck} />
                            </Button>

                            </div>
                                
                        </div>

                        {/* Brands Filter */}
                        <div className={brandsPanel ? "_filter-order _filter-show" : "_filter-order"}>
                            <FilterHead title="Filtrar">
                                <button type="button" className="_back-history" onClick={handleBrandsPanel}>
                                    <Icon icon={faChevronLeft} />
                                </button>
                            </FilterHead>
                        
                            <Filter filterTitle="Marcas" open={true}>

                                <button type="button" className="_filter-selected">
                                    Marisa
                                    <Icon icon={faTimes} />
                                </button>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={4}>
                                        
                                        <label className="_filter-label _brand">
                                            <input type="radio" name="filter-input-name" />
                                            <div className="brand-image">
                                                <img src="https://i.pinimg.com/280x280_RS/98/b5/f4/98b5f4291cde124776dad3a5c4ef13ca.jpg" alt="..." />
                                                <span className="mark">
                                                    <Icon icon={faCheck} />
                                                </span>
                                            </div>
                                            <span className="_filter-title">Marisa</span>
                                        </label>

                                    </Grid>

                                    <Grid item md={12} xs={4}>
                                        
                                        <label className="_filter-label _brand">
                                            <input type="radio" name="filter-input-name" />
                                            <div className="brand-image">
                                                <img src="https://corporate.showmetech.com.br/wp-content/uploads/2020/09/aiqfome.png" alt="..." />
                                                <span className="mark">
                                                    <Icon icon={faCheck} />
                                                </span>
                                            </div>
                                            <span className="_filter-title">Aiqfome</span>
                                        </label>

                                    </Grid>
                                </Grid>
                            
                            </Filter>
                            <div className="_apply-finter-button">

                            <Button type="submit" color="primary" autoFocus className="apply-filters">
                                Aplicar filtro <Icon icon={faCheck} />
                            </Button>
                            </div>
                            </div>
                            
                            <div className="_apply-filters">
                                <Button type="submit" color="primary" autoFocus className="apply-filters">
                                    Aplicar filtros <Icon icon={faCheck} />
                                </Button>
                            </div>
                        </form>

                    </div>

                    <div className="_content">

                        <div className="_title">
                            <h1> Os melhores cupons de desconto! </h1>
                        </div>
                        
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <OfficialStore
                                    link="/lojas-oficiais"
                                    banner="https://blogpromonauta.com.br/wp-content/uploads/2020/04/20MAR_-_MAR_-_TodosOsSonhos_-_Banner.png"
                                    logo="https://i.ibb.co/9YcqrdT/download.png"
                                    title="Marisa"
                                    percentOff="25%"
                                >

                                    <div className="text">
                                        <p>
                                            {maxText("Desconto de 25% em compras sem valor mínimo no site da Marisa!", maxChars)}
                                        </p>
                                    </div>

                                    <Button type="button" color="primary" autoFocus className="see-offer" >
                                        <Link to="/desconto">Ver ofertas</Link>
                                    </Button>
                                </OfficialStore>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <OfficialStore
                                    link="/lojas-oficiais"
                                    banner="https://i0.wp.com/frigideira.aiqfome.com/wp-content/uploads/2019/06/banner-frigideira4.png"
                                    logo="https://codigovalido.com.br/wp-content/uploads/2019/05/cupom-aiqfome.png"
                                    title="Aiqfome"
                                    percentOff="25%"
                                >

                                    <div className="text">
                                        <p>
                                            {maxText("Desconto de 25% em todos os produtos.", maxChars)}
                                        </p>
                                    </div>

                                    <Button type="button" color="primary" autoFocus className="see-offer" >
                                        <Link to="/desconto">Ver ofertas</Link>
                                    </Button>
                                </OfficialStore>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <OfficialStore
                                    link="/lojas-oficiais"
                                    banner="https://s3-sa-east-1.amazonaws.com/tabloidedigitalpf/BANNER_DESEMBRO_18.jpg"
                                    logo="https://play-lh.googleusercontent.com/iSzN4QGxOdFOQJASEjw4-0wEp6ga6EGU0Zq1ZH7sVrpQ79P5US52JBKWnPPbTKCCYkY"
                                    title="Ponto Frio"
                                    percentOff="10%"
                                >

                                    <div className="text">
                                        <p>
                                            {maxText("Desconto de 10% em toda a loja!", maxChars)}
                                        </p>
                                    </div>

                                    <Button type="button" color="primary" autoFocus className="see-offer" >
                                        <Link to="/desconto">Ver ofertas</Link>
                                    </Button>
                                </OfficialStore>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>
                
        </section>

    );

};

const mapStateToProps = (state) => ({
    site_properties: state.websiteReducer.site_properties
})
  
export default connect(mapStateToProps, {})(OfficialStores);