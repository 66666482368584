
//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {connect} from 'react-redux'

//===============================================================================================================
// #2 - Import * Account Layout Base
//===============================================================================================================
import Layout from "../../components/account/Layout";

//=====================================================================================
// #2 - Import * JQuery
//=====================================================================================
import $ from "jquery";
import "jquery-mask-plugin";

//===============================================================================================================
// #3 - Import * Sweet Alert
//===============================================================================================================
import Swal from "sweetalert2";
import Swal1 from "sweetalert";

//===============================================================================================================
// #4 - Import * React Hook Form
//===============================================================================================================
import { useForm } from "react-hook-form";

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";

//=====================================================================================
// #3 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================

import formatTitle from "../../utils/FormatTitle";

//=====================================================================================
// #6 - Import * Material UI Components
//===================================================================================== 
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  useMediaQuery,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";

//=====================================================================================
// #7 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { ListContent, ListItem } from "../../components/ui/list-content";
import { CurrentPage } from "../../components/ui/current-page";
import { NothingMessage } from "../../components/ui/nothing-message";

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faCreditCardBlank as faCreditCardLt, faUser, faCalendarStar, faCheck, faLockAlt, faChevronLeft, faCreditCardBlank, faCalendarAlt, faEdit, faTrashAlt, faUserLock } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV, faEllipsisH, faPlus, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #11 - Credit Card Flags
//===============================================================================================================
import visa from "../../assets/images/credit-card/visa.png";
import mastercard from "../../assets/images/credit-card/mastercard.png";
import americanexpress from "../../assets/images/credit-card/americanExpress.png";
import dinersclub from "../../assets/images/credit-card/DinersClub.png";

//===============================================================================================================
// #16 - Import * Services
//===============================================================================================================
import ScamberFormaPagamento from '../../services/ScamberFormasPagamento';
import Endereco from "../../services/Endereco";
import Scamber from '../../services/Scamber';
import { setScamber } from '../../redux/actions/scamberActions';

const scamber = new Scamber();
const endereco = new Endereco();
let scamberFormaPagamento = new ScamberFormaPagamento();

//===============================================================================================================
// #16 - Default
//===============================================================================================================
const useStyles = makeStyles({
    center: {
        margin: "auto",
    },
    resize:{
        fontSize: '10px'
    },
    inputSize: {
        height: 50
    }
});

//===============================================================================================================
// #13 - Modal - Address
//===============================================================================================================
const ModalAddress = forwardRef((props, ref) => {
  // * States
  const [show, setShow] = useState(false);
  
  // * Function
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
          <section className="_view-head">
            <div className="_view-head-content">
              <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
              </Button>
              <Modal.Title> {props.title} </Modal.Title>
            </div>
          </section>
        </Modal.Header>
        <Modal.Body>
          { props.children }
        </Modal.Body>
      </Modal>
    </>
  );
});

//===============================================================================================================
// #14 - Modal - Add Credit Card
//===============================================================================================================
const ModalCreditCard = forwardRef((props, ref) => {
// Bootstrap Modal
const [show, setShow] = useState(false);

// Handle Modal
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

useImperativeHandle(ref, () => ({
    openModal: () => handleShow(),
    closeModal: () => handleClose(),
}));

return (
    <>
    <Modal show={show} onHide={handleClose} className="full-width" centered>
        <Modal.Header closeButton>
        <section className="_view-head">
            <div className="_view-head-content">
            <Button className="_back-history btn" onClick={handleClose}>
                <Icon icon={faChevronLeft} />
            </Button>
            <Modal.Title> {props.title} </Modal.Title>
            </div>
        </section>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
    </Modal>
    </>
);
});

//===============================================================================================================
// #14 - Component - Credit Card
//===============================================================================================================
const PaymentCard = (props) => {

    // * Variables
    let crediCardFlag;
    const flag = props.flagName;

    // * States
    const [options, setOptions] = useState(false);

    // * Functions
    const handleOptions = () => {
        setOptions(!options);
    }

    const creditCardFlag = (src) => {
        return `${src}`;
    }
    
    switch (flag) {
        case "mastercard":
            crediCardFlag = creditCardFlag(mastercard);
            break;
            case "visa":
            crediCardFlag = creditCardFlag(visa);
            break;
            case "americanexpress":
            crediCardFlag = creditCardFlag(americanexpress);
            break;
            case "dinersclub":
            crediCardFlag = creditCardFlag(dinersclub);
            break;

        default:
            creditCardFlag(visa);
    }

    return (

        <div className="payment-card">

            <div className="_cols">

                <div className="_col">

                    {options && (
                    <div className="actions">
                        <Button 
                            className="payment-action"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={props.edit}
                        >
                            <Icon icon={faEdit} />
                        </Button>
                        <Button 
                            className="payment-action"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={props.delete}
                        >
                            <Icon icon={faTrashAlt} />
                        </Button>
                    </div>
                    )}

                    <div className="payment-preview-info">
                        <div className="icon">
                            <img src={crediCardFlag} />
                        </div>
                        <ListContent>
                            <ListItem title={formatTitle(props.flagName)}> {props.creditCardNumber} </ListItem>
                        </ListContent>
                    </div>
                </div>

                <div className="_col">
                    <span className="tag">
                        
                        {props.type === "debit" && ("débito") || props.type === "credit" && ("crédito")}
                    </span>
                    <Button 
                        className="payment-action"
                        color="primary"
                        autoFocus
                        type="button"
                        onClick={handleOptions}
                    >
                        <Icon icon={options ? faEllipsisH : faEllipsisV} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

function PaymentMethods (props) {

    // * Refs
    const refCreditCardModal        = useRef();
    const refEditCreditCardModal    = useRef();
    const refAddressModal           = useRef();

    // Styles
    const matches   = useMediaQuery("(min-width:600px)");
    const classes   = useStyles();

    // * States
    const [creditCardType, setCreditCardType] = useState(null);
    const [valueField, setValue]              = useState("");
    const [valueFieldCpf, setValueCpf]        = useState("");
    const [paymentMethod, setPaymentMethod]   = useState([]);
    const [selectedCard, setSelectedCard]     = useState("");
    const [month, setMonth]                   = useState("");
    const [year, setYear]                     = useState("");
    const [cvv, setCvv]                       = useState("");
    const [cep, setCep]                       = useState("");
    const [checkedAddress, setCheckedAddress] = useState();
  

    // * React Hook Form
    const { register, handleSubmit, errors, formState, reset } = useForm({mode: "onChange"});

    // * Apply Input Mask
    useEffect(() => {
      $(".cep").mask("00000-000");
      $(".house_number").mask("00000");
    });

    const loadScamberData = () => {
        Swal.fire("Aguarde...");
        Swal.showLoading();
        scamber
          .findScamber()
          .then((scamberData) => {
            Swal.close();
            if (scamberData.status === 200)
              props.setScamber(scamberData.data.payload);
          })
          .catch((error) => {
            Swal.fire({
              type: "info",
              html: error,
              title: "",
            });
          });
    };
  
    // * Functions - React Mask
    const handleCepChange = (e) => setCep(e.target.value);

    // * Functions - Modals
    const closeModalCreditCard      = () => refCreditCardModal.current.closeModal();

    // * Delete Credit Card
    const deleteCreditCard = (id, numero) => {

        scamberFormaPagamento.deleteCardPaymentForm(id)
          .then((response)=>{
            Swal1({
              title: "Deletar forma de pagamento?",
              text: `Ao clicar em confirmar, seu cartão
              Mastercard com final ${numero.substring(numero.length -4,numero.length)} será excluido
              da sua conta.`,
              icon: "warning",
              buttons: ["Cancelar", "Confirmar"],
              dangerMode: true,
            }).then(function (isConfirm) {
              if (isConfirm) {
                  loadPaymentForms();
                  Swal1({
                      title: "Tudo pronto!",
                      text: `Forma de pagamento removida`,
                      icon: "success",
                      buttons: [false, "Ok"],
                      dangerMode: true,
                    })
              }
            });
            console.log(response.data.payload)
          })
          .catch((error)=> {
            console.log(error,' ahhahahah')
            scamberFormaPagamento.formatErrorMessage(error.response, 'PMDECPF')
          })
    }

    let loadPaymentForms = () =>{
      scamberFormaPagamento.find()
        .then((response)=>{
          setPaymentMethod(response.data.payload)
        })
        .catch((error)=> {
          scamberFormaPagamento.formatErrorMessage(error.response, 'PMSETPM')
        })
    }
  
    useEffect(() => {
      loadPaymentForms();
    },[]);

    // * Edit Credit Card
    const onSubmitEditCreditCard  = (creditCard) => {

      let formattedObject = {
        token_cartao: '',
        cardInfos: {
          bandeira:      creditCardType? creditCardType: "none",
          numero_cartao: creditCard.numero_cartao,
          nome:          creditCard.nome,
          mes_expiracao: creditCard.mes_expiracao,
          ano_expiracao: creditCard.ano_expiracao,
          cvv:           creditCard.cvv,
          id_endereco:   props.scamber?.endereco[0]._id,
          cpf:           creditCard.numero_cpf
        }
      }
  
      scamberFormaPagamento.updateCardPaymentForm(formattedObject, selectedCard)
        .then((response)=>{
          Swal.fire({
            icon: "success",
            title: "Cartão de crédito alterado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((eComplete) => {
              if (eComplete) {
                closeModalCreditCard();
                loadPaymentForms();
              }
            });
          })
        .catch((error)=> {
          scamberFormaPagamento.formatErrorMessage(error.response, 'PMUPCPF')
        })

    };

    let openModalWithID = (id, index) =>{
      setSelectedCard(id)
      reset(paymentMethod[0].cartoes[index]);
      refEditCreditCardModal.current.openModal()
    }

    // * Add New Credit Card
    const onSubmitCreditCard  = (creditCard) => {
      
      let formattedObject = {
        token_cartao: '',
        cardInfos: {
          bandeira:      creditCardType? creditCardType: "none",
          numero_cartao: creditCard.numero_cartao,
          nome:          creditCard.nome_add,
          mes_expiracao: creditCard.mes_expiracao_add,
          ano_expiracao: creditCard.ano_expiracao_add,
          cvv:           creditCard.cvv_add,
          id_endereco:   props.scamber?.endereco[0]._id,
          cpf:           creditCard.numero_cpf_add
        }
      }
  
      scamberFormaPagamento.createPaymentForm(formattedObject)
        .then((response)=>{
          Swal.fire({
            icon: "success",
            title: "Cartão de crédito adicionado!",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "fechar",
          }).then((eComplete) => {
              if (eComplete) {
                closeModalCreditCard();
                
                loadPaymentForms();
              }
            });
          })
        .catch((error)=> {
         scamberFormaPagamento.formatErrorMessage(error.response, 'PMCRPF')
        })
    };
  
    const findAddressByCEP = () => {
      if (cep.length === 9) {
        endereco
          .findCep(cep)
          .then((address) => {
            let formatedAddress = {
              cep: address.data.cep,
              estado: address.data.estado,
              logradouro: address.data.logradouro,
              cidade: address.data.localidade,
              estado: address.data.uf,
              bairro: address.data.bairro,
              complemento: address.data.complemento,
            };
  
            if (address.data) {
              reset(formatedAddress);
            }
          })
          .catch((error) =>
            Swal.fire("info", "Não foi possível consultar o endereço", "")
          );
      }
    };
  
    // * Create New Address
    const onSubmitAddress = (data) => {
      
      data.principal = checkedAddress;
      // * Close Modal
      refAddressModal.current.closeModal();
  
      endereco
        .create(data)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Endereço adicionado!",
              icon: "success",
              timer: 4000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "Ok",
            }).then((its_ok) => {
              // * Success Message
              loadScamberData();
              setTimeout(() => refCreditCardModal.current.openModal(), 500);
            });
          }
        })
        .catch((error) =>
          endereco.formatErrorMessage(error.response, 'PMCREN'),
        );
  
      setCheckedAddress(false);
    };

    // * Check Credit Card Flag Type
    const checkCreditCardType = (e) =>{

        let cardnumber = e.target.value.replace(' ','').replace(/[^0-9]+/g,'');
        let emptyFields = 16 - cardnumber.length;
        let zeros = "";
        for(let count = 0; count < emptyFields; count++){
        zeros += "0"
        }

        var cards = {
            visa           : /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard     : /^5[1-5][0-9]{14}/,
            DinersClub     : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            americanExpress: /^3[47][0-9]{13}/,
        };  

        for (var flag in cards) {
            if(cards[flag].test(parseInt((cardnumber + zeros)))) {
                return setCreditCardType(flag);
            }
        }       

        return setCreditCardType(null);

    } 
   
    // * Set props -> setScamber
    const loadingScamberData = (scamber) => {
      props.setScamber(scamber);
    }

    // * if exists address
    const checkAdress = () => {
      if (props.scamber?.endereco.length) {
        refCreditCardModal.current.openModal();
      } else {
        refAddressModal.current.openModal();
      }

    };

    return (
        <>
        <ModalCreditCard ref={refCreditCardModal} title="Adicionar forma de pagamento">  
        <form onSubmit={handleSubmit(onSubmitCreditCard)}>
          <h1 className="_content-title">
            Não faremos nenhuma cobrança automática em seu cartão. Ele só será utilizado com sua autorização.
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={americanexpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={dinersclub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <InputMask
                    mask="9999 9999 9999 9999"
                    defaultValue={valueField}
                    onChange={e => setValue(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="Número do cartão *"
                  type="text"
                  defaultValue={valueField}
                  variant="outlined"
                  name="numero_cartao"
                  onInput={checkCreditCardType}
                  onPaste={checkCreditCardType}
                  size={matches ? "small" : "medium" }
                  error={!!errors.numero_cartao}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          creditCardType?
                            <div className="creadit-card--flag">
                              <img style={{width:"15.75px"}} src={require(`../../assets/images/credit-card/${creditCardType}.png`)} alt="Flag credit card" />
                            </div>
                          :
                          <Icon icon={faCreditCardLt} />
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>         
              <Grid item xs={4}>
              <InputMask
                    mask="99"
                    defaultValue={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-month"
                  label="Mês **"
                  type="text"
                  defaultValue={month}
                  variant="outlined"
                  name="mes_expiracao_add"
                  size={matches ? "small" : "medium" }
                  error={!!errors.mes_expiracao_add}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarStar} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
              <InputMask
                    mask="9999"
                    defaultValue={year}
                    onChange={e => setYear(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-year"
                  label="Ano *"
                  type="text"
                  defaultValue={year}
                  variant="outlined"
                  name="ano_expiracao_add"
                  size={matches ? "small" : "medium" }
                  error={!!errors.ano_expiracao_add}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
              <InputMask
                    mask="999"
                    defaultValue={cvv}
                    onChange={e => setCvv(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="Cvv *"
                  type="text"
                  defaultValue={cvv}
                  variant="outlined"
                  name="cvv_add"
                  size={matches ? "small" : "medium" }
                  error={!!errors.cvv_add}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 3,
                    maxLength: 4
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faLockAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  variant="outlined"
                  name="nome_add"
                  size={matches ? "small" : "medium" }
                  error={!!errors.nome_add}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <InputMask
                    mask="999.999.999-99"
                    defaultValue={valueFieldCpf}
                    onChange={e => setValueCpf(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="CPF *"
                  type="text"
                  defaultValue={valueFieldCpf}
                  variant="outlined"
                  name="numero_cpf_add"
                  size={matches ? "small" : "medium" }
                  error={!!errors.numero_cpf_add}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUserLock} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
            </Grid>

            {paymentMethod[0]?.scamber[0]?.endereco?.length ? (
            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">{'CEP: ' + props.scamber?.endereco[0]?.cep}</div>
                  <div className="_location">
                    {props.scamber?.endereco[0]?.logradouro + ', n' + props.scamber?.endereco[0]?.numero + ' - ' + props.scamber?.endereco[0]?.bairro +
                      ', ' + props.scamber?.endereco[0]?.cidade + ' - ' +props.scamber?.endereco[0]?.estado}
                  </div>
                </div>
              </div>
            </div>
            ) : null}
            
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
        </form>
        </ModalCreditCard>

        <ModalCreditCard ref={refEditCreditCardModal} title="Editar forma de pagamento">
        <form onSubmit={handleSubmit(onSubmitEditCreditCard)}>
          <h1 className="_content-title">
            Altere os dados do seu cartão.
          </h1>
          <ul className="_payment-methods-list">
            <li>
              <div className="creadit-card--flag">
                <img src={visa} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={mastercard} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={americanexpress} alt="" />
              </div>
            </li>
            <li>
              <div className="creadit-card--flag">
                <img src={dinersclub} alt="" />
              </div>
            </li>
          </ul>

          {/*  Form Body */}
          <section className="_form-body _credit-card-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <InputMask
                    mask="9999 9999 9999 9999"
                    defaultValue={valueField}
                    onChange={e => setValue(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="Número do cartão *"
                  type="text"
                  defaultValue={valueField}
                  variant="outlined"
                  name="numero_cartao"
                  onInput={checkCreditCardType}
                  onPaste={checkCreditCardType}
                  size={matches ? "small" : "medium" }
                  error={!!errors.numero_cartao}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {
                          creditCardType?
                            <div className="creadit-card--flag">
                              <img style={{width:"15.75px"}} src={require(`../../assets/images/credit-card/${creditCardType}.png`)} alt="Flag credit card" />
                            </div>
                          :
                          <Icon icon={faCreditCardLt} />
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>         
              <Grid item xs={4}>
              <InputMask
                    mask="99"
                    defaultValue={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-month"
                  label="Mês *"
                  type="text"
                  variant="outlined"
                  name="mes_expiracao"
                  size={matches ? "small" : "medium" }
                  error={!!errors.mes_expiracao}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarStar} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
              <InputMask
                    mask="9999"
                    defaultValue={year}
                    onChange={e => setYear(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-year"
                  label="Ano *"
                  type="text"
                  variant="outlined"
                  name="ano_expiracao"
                  size={matches ? "small" : "medium" }
                  error={!!errors.ano_expiracao}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faCalendarAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={4}>
              <InputMask
                    mask="999"
                    defaultValue={year}
                    onChange={e => setYear(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="Cvv *"
                  type="text"
                  variant="outlined"
                  name="cvv"
                  size={matches ? "small" : "medium" }
                  error={!!errors.cvv}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faLockAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-name"
                  label="Nome do titular cartão *"
                  variant="outlined"
                  name="nome"
                  size={matches ? "small" : "medium" }
                  error={!!errors.nome}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 4,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
              <InputMask
                    mask="999.999.999-99"
                    defaultValue={valueFieldCpf}
                    onChange={e => setValueCpf(e.target.value)}
                  >
                <TextField
                  id="outlined-cc-number"
                  label="CPF *"
                  type="text"
                  defaultValue={valueFieldCpf}
                  variant="outlined"
                  name="numero_cpf"
                  size={matches ? "small" : "medium" }
                  error={!!errors.numero_cpf}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon={faUserLock} />
                      </InputAdornment>
                    ),
                  }}
                />
                </InputMask>
              </Grid>
            </Grid>

            {paymentMethod[0]?.scamber[0]?.endereco.length ? (
            <div className="_set-user-location">
              <div className="_wrap-location">
                <div className="_icon">
                  <Icon icon={faMapMarkerAlt} />
                </div>

                <div className="_wrap-current-location">
                  <div className="_location-title">{`CEP: ${paymentMethod[0]?.scamber[0]?.endereco[0]?.cep}`}</div>
                  <div className="_location">
                  {`${paymentMethod[0]?.scamber[0]?.endereco[0]?.logradouro}, 
                      n${paymentMethod[0]?.scamber[0]?.endereco[0]?.numero} - ${paymentMethod[0]?.scamber[0]?.endereco[0]?.bairro},
                      ${paymentMethod[0]?.scamber[0]?.endereco[0]?.cidade} - ${paymentMethod[0]?.scamber[0]?.endereco[0]?.estado}`}
                  </div>
                </div>
              </div>
            </div>
            ) : null}
            
          </section>
          {/*  END ./ Form Body */}

          <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
        </form>
        </ModalCreditCard>

        <ModalAddress setScamber={loadingScamberData} ref={refAddressModal} title="Adicionar endereço">

        <form onSubmit={handleSubmit(onSubmitAddress)}>
          <h1 className="_content-title">
            Antes de adicionar um metodo de pagamento é necessário adicionar um endereço a sua conta.<br/>
          </h1>
          {/*  Form Body */}
          <section className="_form-body">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-number"
                  label="Cep *"
                  type="text"
                  onChange={handleCepChange}
                  onBlur={findAddressByCEP}
                  variant="outlined"
                  name="cep"
                  size={matches ? "small" : "small"}
                  error={!!errors.cep}
                  inputProps={{ className: "cep" }}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 8,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Estado *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="estado"
                  error={!!errors.estado}
                  fullWidth
                  inputProps={{ maxLength: 2 }}
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-neighborhood"
                  label="Cidade *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="cidade"
                  error={!!errors.cidade}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-address"
                  label="Endereço *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="logradouro"
                  error={!!errors.logradouro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="outlined-address-number"
                  label="Número *"
                  type="number"
                  variant="outlined"
                  inputProps={{ className: "house_number" }}
                  size={matches ? "small" : "small"}
                  name="numero"
                  error={!!errors.numero}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  size={matches ? "small" : "small"}
                  name="bairro"
                  error={!!errors.bairro}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-complement"
                  label="Complemento"
                  variant="outlined"
                  name="complemento"
                  size={matches ? "small" : "small"}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-cc-number"
                  label="Apelido *"
                  type="text"
                  variant="outlined"
                  name="apelido"
                  placeholder="Exemplo: Casa, Trabalho.."
                  size={matches ? "small" : "small"}
                  error={!!errors.apelido}
                  fullWidth
                  inputRef={register({
                    required: true,
                    minLength: 2,
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <div className="mark-default-address">
              <label>
                <input
                  onChange={() => setCheckedAddress(!checkedAddress)}
                  type="checkbox"
                  name="mark_default_address"
                />

                <div className="text">Marcar como meu novo endereço padrão</div>

                <div className="check">
                  <Icon icon={faCheck} />
                </div>
              </label>
            </div>
          </section>

          <Button
            type="submit"
            className={
              "btn btn-primary btn-fluid btn-submit-modal " + classes.center
            }
            color="primary"
            autoFocus
            style={{ height: matches ? 35 : 40 }}
            disabled={
              !formState.dirty || (formState.dirty && !formState.isValid)
            }
          >
            Confirmar
            <Icon icon={faCheck} />
          </Button>
          {/*  END ./ Form Body */}
        </form>

        </ModalAddress>

        <Layout>
            <div id="view_payment-methods">
                <div className="_block-title">
                    <CurrentPage icon={<Icon icon={faCreditCardBlank} />} title="Formas de pagamento" />

                    <div className="_action">
                        <Button className="btn-add-payment" onClick={checkAdress}>
                            Adicionar
                            <Icon icon={faPlus} />
                        </Button>
                    </div>
                </div>
            

            <section className="_columns">

                <div className="_column">

                    <section className="_block">
                        {paymentMethod[0]?.cartoes?.length ?  
                          paymentMethod[0]?.cartoes.map((item,index)=>
                            <PaymentCard key={index}
                              delete={() => deleteCreditCard(item._id, item.numero_cartao)}
                              edit={ (e) => openModalWithID(item._id, index) }
                              flagName={item.bandeira === 'visa' ? 'visa' : item.bandeira === 'mastercard'? 'mastercard' : item.bandeira === 'diners' || item.bandeira === 'DinersClub' ? 'dinersclub' : item.bandeira === 'amex' || item.bandeira === 'americanExpress'? 'americanexpress' : 'visa'}
                              creditCardNumber={item.numero_cartao}
                              type="credit"
                            />        
                          )
                        :<NothingMessage>
                          Você não tem nenhum metodo de pagamento cadastrado.<br/> para cadastrar um novo clique no botão adicionar.
                        </NothingMessage>}
                    </section>
                </div>
            </section>

            </div>
        </Layout>
        </>
    )
}

const mapStateToProps = state => {
	return {
        scamber: state.scamberReducer.scamber,
	}
}

export default withRouter(connect(mapStateToProps, { setScamber })(PaymentMethods));