//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { Component } from "react";

//===============================================================================================================
// #2 - Import * Account Layout Base
//===============================================================================================================
import Layout from "../../components/account/Layout";

//===============================================================================================================
// #3 - Import * Account Layout Base
//===============================================================================================================
import Helmet from 'react-helmet';

//===============================================================================================================
// #6 - Import * Utils
//===============================================================================================================
import formatTitle from "../../utils/FormatTitle";

//===============================================================================================================
// #4 - Import * Account Layout Base
//===============================================================================================================
import { connect } from 'react-redux';
import config from '../../configs/config.json';

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";

//===============================================================================================================
// #5 - Import * UI Components
//===============================================================================================================
import { PricePoints } from "../../components/ui/price";
import {
  Accordion,
} from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { CurrentPage } from "../../components/ui/current-page";

//===============================================================================================================
// #6 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faClone, faUsers, faCalendarAlt, faCoins } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faWhatsapp, faFacebookF,  faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #6 - Import * React Share
//===============================================================================================================
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";


//===============================================================================================================
// #6 - History Block
//===============================================================================================================
const HistoryBlock = (props) => {

  return (

    <div className="_history-block">
      <div className="_cols">
        <div className="_col">
          <h1 className="title"> {formatTitle(props.title)} </h1>
          <div className="items">
            
            <div className="item">
              <div className="icon">
                <Icon icon={faCalendarAlt} />
              </div>
              <h4 className="title">
                {props.date}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCoins} />
              </div>
              <h4 className="title">
                <PricePoints value={props.points} className={props.color} />
              </h4>
            </div>
            <div className="item">
              <div className={ props.type === "finalizada" && ("icon green") || props.type === "aguardando" && ("icon nude") }>
                <Icon icon={faCircle} />
              </div>
              <h4 className="title">
                { props.type === "finalizada" && (formatTitle(props.type)) || props.type === "aguardando" && (`${formatTitle(props.type)} ativação`) }
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class InviteFriends extends Component {

  constructor(){
    super();
    this.state = {
      copyicon: false
    }
  }

  componentWillMount(){
    if(!this.state.inviteUrl && this.props.scamber){
      if(config.nodejs.server.indexOf('localhost') !== -1){
        this.setState({inviteUrl: `http://localhost:3000/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev') !== -1 && config.nodejs.server.indexOf('/dev2') === -1){
        this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev2') !== -1){
        this.setState({inviteUrl: `https://d2.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1){
        this.setState({inviteUrl: `https://h1.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else if(config.nodejs.server.indexOf('apihml2') !== -1){
        this.setState({inviteUrl: `https://h2.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else{
        this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
      }
    }
  }

  componentDidUpdate(nextProps){
    if(nextProps.scamber !== this.props.scamber){
      if(config.nodejs.server.indexOf('localhost') !== -1){
        this.setState({inviteUrl: `http://localhost:3000/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev') !== -1 && config.nodejs.server.indexOf('/dev2') === -1){
        this.setState({inviteUrl: `https://d1.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('/dev2') !== -1){
        this.setState({inviteUrl: `https://d2.scamb.com.vc/in/${this.props?.scamber._id}`})
      }else if(config.nodejs.server.indexOf('apihml') !== -1 && config.nodejs.server.indexOf('apihml2') === -1){
        this.setState({inviteUrl: `https://h1.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else if(config.nodejs.server.indexOf('apihml2') !== -1){
        this.setState({inviteUrl: `https://h2.scamb.com.vc/in/${this.props?.scamber._id}`})
      } else{
        this.setState({inviteUrl: `https://scamb.com.vc/in/${this.props?.scamber._id}`})
      }
    }
  }

  handleCopy = () => this.setState({copyicon: !this.state.copyicon})

  copyText = () =>{
    let textArea = document.createElement("textarea");
    //let inviteLink = document.getElementById("inviteLink");

    textArea.textContent = this.state.inviteUrl;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy')
    textArea.remove();

    this.handleCopy();

    setTimeout(() => {
      this.setState({copyicon: false})
    }, 1500);
  }

  shareWhatsapp = () => {
    return window.open(`https://api.whatsapp.com/send/?phone&text=Você já conhece o Scamb? Cadastre-se com meu link e ganhe pontos Scamb para usar dentro do site: ${this.state.inviteUrl}`, "_blank");
  }

  render() {
    return (
        <Layout>

        <Helmet title={`${this.props.site_properties?.title} - Convidar amigos`} />

          <div id="view_invite-friends">

            <CurrentPage icon={<Icon icon={faUsers} />} title="Programa de indicação" />


            <section className="_columns">
              <div className="_column hero">
                <section className="_block">

                  <hgroup className="title">
                    <h1>Convide amigos e ganhe pontos</h1>
                    <h2>Copie seu link e envie para seus amigos</h2>
                  </hgroup>

                  <div className="_invite-field">
                    <form className="_form">
                      <div className="form-group">
                        <input
                          id="inviteLink"
                          type="text"
                          className="form-control"
                          placeholder={this.state.inviteUrl}
                          value={this.state.copyicon ? "Link copiado!" : this.state.inviteUrl}
                          disabled="disabled"
                        />
                        <button onClick={this.copyText} type="button" className={!this.state.copyicon ? "btn" : "btn check"}>
                          <Icon icon={!this.state.copyicon ? faClone : faCheck} />
                        </button>
                      </div>
                    </form>

                    <nav className="_list-socials">
                      <ul>
                          <li>
                              <Button type="button" onClick={this.shareWhatsapp}>
                                  <Icon icon={faWhatsapp} />
                              </Button>
                          </li>
                          <li>
                              <FacebookShareButton
                                quote={`Você já conhece o Scamb? Cadastre-se com meu link e ganhe pontos Scamb para usar dentro do site: ${this.state.inviteUrl}`}
                                url="http://scamb.com.vc"
                            >
                                <Icon icon={faFacebookF} />
                            </FacebookShareButton>
                          </li>
                          <li>
                              <TwitterShareButton
                                    title={`Você já conhece o Scamb? Cadastre-se com meu link e ganhe pontos Scamb para usar dentro do site: ${this.state.inviteUrl}`}
                                    url="http://scamb.com.vc"
                                >
                                  <Icon icon={faTwitter} />
                              </TwitterShareButton>
                          </li>

                          <li>
                            <a href={` http://www.linkedin.com/shareArticle?mini=true
                              &url=${this.state.inviteUrl}
                              &summary=Você%20já%20conhece%20o%20Scamb?%20Cadastre-se%20com%20meu%20link%20e%20ganhe%20pontos%20Scamb%20para%20usar%20dentro%20do%20site: ${this.state.inviteUrl}
                              &source=scamb.com.vc
                            `} target="_blank">
                                  <Icon icon={faLinkedinIn} />
                              </a>
                          </li>
                      </ul>
                    </nav>
                  </div>

                </section>
              </div>

              {/*<div className="_column _points">

                <section className="_block">

                  <h6 className="_block-title"> Usuários cadastrados com meu link </h6>

                  <ListContent bgColor="#FFFFFF">
                    <ListItem >
                      <PricePoints value={this.props?.scamber?.convidados? this.props?.scamber?.convidados.length : ''} />
                      
                    </ListItem>
                  </ListContent>
              
                </section>
              </div>*/}

              {/*<div className="_column _activity">

                <section className="_block">

                  <h6 className="_block-title"> Detalhes </h6>
                  <HistoryBlock type="finalizada" title="Alessandra fernandes" date="13/10/2020" points="+10" color="green"/>
                  <HistoryBlock type="finalizada" title="Mariana melo" date="12/10/2020" points="+10" color="green"/>
                  <HistoryBlock type="aguardando" title="Juliana moraes" date="09/10/2020" points="+10" color="nude"/>
              
                </section>
              </div>*/}
            </section>

            <section className="_wrap-banner-fixed _is-hidden">

                <section className="_banner-fixed">
                  <div className="_texts-banner">
                    <h1 className="_the-title _color _white _fw700">
                      Convide amigos e ganhe pontos
                    </h1>
                    <span className="_subtitle _color _white _fw700">
                      Copie seu link e envie para seus amigos
                    </span>
                  </div>

                  <div className="_invite-label">
                    <form className="_form">
                      <div className="form-group">
                        <input
                          id="inviteLink"
                          type="text"
                          className="form-control"
                          placeholder={this.state.inviteUrl}
                          value={this.state.inviteUrl}
                          disabled="disabled"
                        />
                        <button onClick={this.copyText} type="button" className="btn">
                          <Icon icon={faClone} />
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
			  </section>

            <ul className="_list-info  _is-hidden">
              <li>
                <div className="key">
                  Link de compartilhamento
                  <div id="inviteLink" className="val link">
                    {this.state.inviteUrl}
                    <button onClick={this.copyText} type="button" className="btn">
                      <Icon icon={faClone} />
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div className="key">
                  Usuários cadastrados com seu link
                    <div className="val">{this.props?.scamber?.convidados? this.props?.scamber?.convidados.length : ''}</div>
                </div>
              </li>
              <li>
                <div className="key">
                  Pontos Recebidos:
                  <div className="_product-price _1x">
                    <div className="ui-item__price">
                      <span class="price-tag-fraction">0</span>
                      <span class="price-tag-symbol">pts</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
      scamber: state.scamberReducer.scamber,
      site_properties: state.websiteReducer.site_properties
  }
}

export default connect(mapStateToProps)(InviteFriends);
