let site_properties = undefined;

const PathFinder = () => {
    let subdomain;
  
    let hostname = window.location.hostname;
    let hostnamePaths = hostname.split('.');
    subdomain = hostnamePaths[0];
  
    if('localhost' === subdomain || '10' === subdomain) {
        let pageToOpen = localStorage.getItem('website');
        subdomain = pageToOpen? pageToOpen:'scamb';
        subdomain = subdomain === 'marcas'? 'shopping': subdomain;
    } else if('h1' === subdomain || 'h2' === subdomain || 'd1' === subdomain || 'd2' === subdomain) {
        subdomain = 'scamb';
    } else if('marcas' === subdomain) {
        subdomain = 'shopping';
    }

    return subdomain;
}

const getSiteProperties = () => {
    let path = PathFinder();

    try {
        if(!site_properties) {
            site_properties = require(`./${path}/site_properties`);
        }
        
        return site_properties
    } catch(e) {
        return;
    }

}
  
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  
export {PathFinder, getSiteProperties};

