import Service from './Service';

class QuickLink extends Service {

  constructor() {
    super();
  }

  getAvailableQuickLink(website) {
    return this.axiosInstance.get(`/quicklink${website? '?website=' + website:''}`);
  }
}
export default QuickLink;