import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link, Redirect, Router } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import SweetAlert from "sweetalert";
import { connect } from 'react-redux';

// Bootstrap
import { Button } from "react-bootstrap";
import RecoveryPassword from '../../services/RecoveryPassword';

// Material - UI
import {
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  IconButton,
  Slide,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import $ from 'jquery';

// Forms
import { useForm } from "react-hook-form";

// Icons
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faEye,
  faEyeSlash,
  faTimes,
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

import Authentication from "../../services/Authentication";
import EventEmitter from "../../services/Events";
import Helmet from 'react-helmet';
import Scamber from '../../services/Scamber';

import Cookie from './../../utils/Cookie';


// Styles
const useStyles = makeStyles({
  center: {
    margin: "auto",
  },
  resize: {
    fontSize: "10px",
  },
  inputSize: {
    height: 50,
  },
  svgSize: {
    width: "18px !important",
    height: "auto !important",
  },
});

// Modal Dialog Base
const ModalDialog = forwardRef((props, ref) => {
  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Material ui Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Form
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChnage",
  });

  const onSubmitRecovery = (data) => {

    Swal.fire('Aguarde...');
    Swal.showLoading();
    let recoveryPassword = new RecoveryPassword();
    setOpen(false);
    recoveryPassword.requestRecoveryPassword({ 'email': data.login_usermail }).then((response) => {
      if (response.status === 200) {

        Swal.fire({
          icon: "success",
          title:
            "As instruções para redefinição de senha foram enviadas para o seu e-mail",
          timer: 6000,
          timerProgressBar: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "fechar",
        }).then((eComplete) => {
          if (eComplete) {
          }
        });
      }
    }).catch((error) =>
      Swal.fire({
        type: "info",
        html: error.response.data.message,
        title: "",
      }));

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open ? true : false}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <FontAwesomeIcon
                icon={matches ? faTimes : faChevronLeft}
              ></FontAwesomeIcon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <p className="_sub-title">
              Forneça seu e-mail e enviaremos um link para definição de nova senha. <br />Não se esqueça de verificar a caixa de spam.
            </p>
            <form
              id="recovery-password"
              className="_form"
              onSubmit={handleSubmit(onSubmitRecovery)}
            >
              <Grid container spacing={2} justify="center">
                <Grid item md={7} xs={12}>
                  <TextField
                    id="outlined-email-recovery"
                    label="E-Mail *"
                    type="email"
                    variant="outlined"
                    size={matches ? "small" : "medium"}
                    name="login_usermail"
                    // error={!!errors.login_usermail}
                    helperText={
                      errors.create_acc_email && errors.create_acc_email.message
                    }
                    fullWidth
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Endereço de email inválido :(",
                      },
                    })}
                  />
                </Grid>
              </Grid>

              <div className="form-group-button">
                <Button
                  type="submit"
                  className={"btn" + classes.center}
                  color="primary"
                  autoFocus
                  onClick={() => handleSubmit()}
                  style={{ height: matches ? 35 : 40 }}
                  disabled={
                    !formState.dirty || (formState.dirty && !formState.isValid)
                  }
                >
                  Enviar
                </Button>
              </div>
            </form>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

// Material ui Dialog Slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Login(props) {
  // Ref Modals
  const refModalRecoveryPassword = React.createRef();

  // Styles
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  // Password
  let [password, setPassword] = useState(false);

  const handlePassword = () => setPassword(!password);

  // Redirect
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  // Form
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChnage",
  });

  // Instantiate authentication
  let authentication = new Authentication();
  let scamber = new Scamber();
  let recoveryPassword = new RecoveryPassword();

  const resendValidationEmail = () => {
    const scamber = new Scamber();

    $('.email-error').addClass('d-none');
    $('.email-success').addClass('d-none');

    scamber.resendEmailValidation(email).then(() => {

      $('.email-success').removeClass('d-none');

      setTimeout(() => {
        $('.email-success').addClass('d-none');
      }, 3000)

    }).catch((error) => {

      $('.email-error').removeClass('d-none')

      setTimeout(() => {
        $('.email-success').addClass('d-none');
      }, 3000)

    })
  }

  const onSubmit = (userAndPassword, e) => {
    e.preventDefault();

    Swal.fire("Efetuando Login ... Aguarde");
    Swal.showLoading();
    authentication
        .doLogin(userAndPassword)
        .then((isAuthenticated) => {
          Cookie.setCookie('token',  isAuthenticated.data.payload.token, 30);
          EventEmitter.dispatch("loginSucess");

          Swal.close();
          if (isAuthenticated.status === 200) {
            if(props.location.state && props.location.state.cart){
              props.history.push('/comprar');
            } else {
              props.history.push('/');
            }
          }
        }).catch((error) => {

      if (error.response.status === 401) {
        Swal.fire({
          title: error.response.data.message
        })
      };

      if (error.response.status === 406) {

        Swal.fire({
          icon: "info",
          title: "Confirme sua conta",
          html:
              "Um código de 6 digitos foi enviado anteriormente para o email " +
              `<b>${email}</b> \n <p>Digite o código no campo abaixo para confirmar sua conta, 
            caso o código esteja inválido ou não tenha recebido, clique no botão abaixo.</p>
            <p><a id="resend-email" href="#!">Não recebi meu código de 6 dígitos</a></p>
            <p class="email-success d-none">Email de validação reenviado com sucesso!</p>
            <p class="email-error d-none">Não foi possível reenviar o email de validação!</p>`,
          input: "text",
          inputPlaceholder: "Digite seu código aqui",
          showCancelButton: true,
          confirmButtonText: "confirmar",
          cancelButtonText: "cancelar",
          inputValidator: (value) => {
            if (!value) {
              return "Código está incorreto";
            } else {
              scamber.validate(value).then((response) => {
                if (response.status === 200) {

                  const authentication = new Authentication();

                  authentication.doLogin({ email: userAndPassword.email, password: userAndPassword.password }).then((isAuthenticated) => {

                    if (isAuthenticated.status === 200) {
                      Cookie.setCookie('token',  isAuthenticated.data.payload.token, 30);

                      EventEmitter.dispatch('loginSucess');
                      //props.history.push('/');

                      if(props.location.state && props.location.state.cart){
                        props.history.push('/comprar');
                      } else {
                        setTimeout(() => window.location = "/cadastro-realizado", 100);
                      }

                      // Swal.fire({
                      //   icon: "success",
                      //   title: "Conta validada com sucesso!",
                      //   text: "Clique no botão abaixo para editar suas preferências",
                      //   confirmButtonText: "continuar",
                      // }).then((isConfirm) => {
                      //   if (isConfirm) {
                      //     if(props.location.state && props.location.state.cart){
                      //       props.history.push('/comprar');
                      //     } else {
                      //       setTimeout(() => window.location = "/cadastro-realizado", 1000);
                      //     }
                      //   }
                      // });

                    }

                  }).catch((error) => scamber.formatErrorMessage(error.response, 'VALLOG'));

                }
              }).catch((error) => scamber.formatErrorMessage(error.response, 'VALSCA'))


            }
          },
        });

        $("#resend-email").on('click', function () {
          console.log('entrou')
          resendValidationEmail();
        });

      } else {
        scamber.formatErrorMessage(error.response, 'SCALOG')
      }
    });
  };

  const fristAccess = (e) => {
    e.preventDefault();

    SweetAlert({
      title: "Primeiro Acesso",
      text: "Sua primeira vez acessando sua conta ? Nós enviamos um email para você criar sua senha, caso não tenha recebido o mesmo basta clicar no botão 'Reenviar Email' que vamos enviar um novo email pra você!",
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: "E-mail/Login",
        },
      },
      buttons: ['Cancelar', 'Reenviar Email'],
    }).then(function (value) {
      Swal.fire("Enviando Email ... Aguarde");
      Swal.showLoading();
      if(value) {
        recoveryPassword.requestRecoveryPassword({ email: value, fristAccess: true }).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title:
                  "As instruções para redefinição de senha foram enviadas para o seu e-mail",
              timer: 6000,
              timerProgressBar: true,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "fechar",
            }).then((eComplete) => {
              if (eComplete) {
              }
            });
          }
        }).catch((error) => {
          Swal.fire({
            type: "info",
            html: error.response.data.message,
            title: "",
          });
        });
      }
    });
  }

  // Handle with facebook login
  let callFacebookLogin = (event) => {
    event.preventDefault();
    authentication.doFacebookLogin();
  };

  useEffect(() => {
    let paramsString = props.location.search;
    const params = new URLSearchParams(paramsString);
    let tokenFacebook = params.get("code");

    if (tokenFacebook) {
      Swal.fire({
        html: "Aguarde efetuando login...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      Swal.showLoading();
      authentication
        .confirmFacebookLogin(tokenFacebook)
        .then((isAuthenticated) => {
          Swal.close();
          //window.history.pushState('','','/login');
          Cookie.setCookie('token',  isAuthenticated.data.payload.token, 30);
          EventEmitter.dispatch("loginSucess");
          if (isAuthenticated.status === 200) props.history.push("/meu-perfil");
        })
        .catch((error) => {
          Swal.close();
          if (error.response.status === 400)
            Swal.fire({
              type: "info",
              html: "Falha ao autenticar com Facebook.",
              title: "",
            });
        });
    }
  }, []);

  return (
    <>
      <ModalDialog
        ref={refModalRecoveryPassword}
        dialogTitle="Recuperar senha"
        className="_recovery-password"
      ></ModalDialog>

      <div id="view_login">
        <Helmet title={`${props.site_properties?.title} - Login`} />
        <section className="_wrap-auth-screen">
          <div className="auth-screen">
            <h1 className="_auth-screen-title headline _color _black _fw700 text-center">
              Acesse sua conta Scamb
            </h1>

            <div className="_wrap-auth-facebook">
              <a
                onClick={callFacebookLogin}
                href="#!"
                className="_auth-facebook-btn"
              >
                <span className="btn-icon">
                  <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
                </span>
                Entrar com o facebook
              </a>
            </div>

            <div className="_or">
              <div className="divider _color _gray _13px">
                <span className="text">ou</span>
              </div>
            </div>

            <div className="_wrap-form">
              <form className="_form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-login"
                      label="Login *"
                      type="text"
                      // value={email}
                      // onChange={(e)=>setEmail(e.target.value)}
                      variant="outlined"
                      size={matches ? "small" : "medium"}
                      name="email"
                      error={!!errors.email}
                      fullWidth
                      inputRef={register({
                        required: true,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-password"
                      label="Senha *"
                      type={password ? "text" : "password"}
                      variant="outlined"
                      name="password"
                      // value={senha}
                      // onChange={(e)=>setSenha(e.target.value)}
                      size={matches ? "small" : "medium"}
                      error={!!errors.password}
                      fullWidth
                      inputRef={register({
                        required: true,
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handlePassword}
                              edge="end"
                            >
                              <FontAwesomeIcon
                                className={classes.svgSize}
                                icon={password ? faEye : faEyeSlash}
                              ></FontAwesomeIcon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <div className="_wrap-recovery">
                  <div className="_checkbox form-check">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" />
                      <span className="check">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                      </span>

                      <div className="_alt-text">Permanecer logado</div>
                    </label>
                  </div>
                    
                  <div className="_container-recoverry-password">
                    <a
                      onClick={() => refModalRecoveryPassword.current.openModal()}
                      className="_link-recovery-password"
                    >
                      Esqueci minha senha
                    </a>
                    {props.website === 'shopping' && (
                      <a 
                        onClick={(e) => fristAccess(e)} 
                        className="_link-frist-access"
                      >
                        Primeiro acesso
                      </a>
                    )}
                  </div>
                </div>

                <div className="form-group-button">
                  <Button
                    type="submit"
                    className={classes.center}
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                    disabled={
                      !formState.dirty ||
                      (formState.dirty && !formState.isValid)
                    }
                  >
                    Fazer login
                  </Button>
                </div>
              </form>
            </div>

            <div className="_have-account _color _gray _13px _fw700 text-center">
              Ainda não possui uma conta?{" "}
              <Link to={{pathname: 'criar-conta', state: props.location.state && props.location.state.cart ? { cart: true } : undefined }}>Criar Conta</Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties,
  website: state.websiteReducer.website
})

export default connect(mapStateToProps, {})(Login);