import Service from './Service';

class PedidoShopping extends Service {

    constructor() {
        super()
    }

    createOrderShopping(paymentInfos){
        return this.axiosInstance.post(`/ordershopping`, paymentInfos);
    }

    finishOrderShopping(orderID) {
        return this.axiosInstance.post(`/ordershopping/finish/${orderID}`, {});
    }
}

export default PedidoShopping;