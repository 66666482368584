//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { Component } from "react";

//===============================================================================================================
// #1 - Import * React Router Dom
//===============================================================================================================
import { withRouter } from "react-router-dom";

//===============================================================================================================
// #1 - Import * React Helmet
//===============================================================================================================
import Helmet from "react-helmet";

//===============================================================================================================
// #1 - Import * Material UI
//===============================================================================================================
import Grid from '@material-ui/core/Grid';

//===============================================================================================================
// #1 - Import * Redux
//===============================================================================================================
import { connect } from "react-redux";

//===============================================================================================================
// #1 - Import * Site Properties
//===============================================================================================================
import { getSiteProperties } from "../../../PathFinder";

//===============================================================================================================
// #1 - Import * Quick Categories 
//===============================================================================================================
import QuickCategories from "./quick-categories";

//===============================================================================================================
// #1 - Import * Banners 
//===============================================================================================================
import { banners } from "./slider/data/banners";
import SliderBanners from "../../../components/design/layout/slider";

//===============================================================================================================
// #1 - Import * Styled Components 
//===============================================================================================================
import { ContainerCards, ContainerPartners, WrapHeadline } from "./styles";

//===============================================================================================================
// #1 - Import * UI Components
//===============================================================================================================
import Card from "./components/card";
import Partner from "./components/partners";
import { Headline } from "../../../components/design/foundation/headline";

//===============================================================================================================
// #1 - Import * Images
//===============================================================================================================
import Button from '../../../components/design/form/button'

import ScambChoices from './scamb-choises';
import Showcases from './scamb-showcases';
import QuickLink from './../../../services/QuickLink';


class Home extends Component {

  constructor() {
    super();
    this.state = {
      quickLinks: []
    }
  }

  quickLinkData = async () => {
    let quickLink = new QuickLink();

    try {
      const response = await quickLink.getAvailableQuickLink(this.props.website);
      const data = await response.data.payload;

      this.setState({ quickLinks: data });

    } catch (error) {

      quickLink.formatErrorMessage(error.response, "QLCTGS");

    }

  }

  componentDidMount() {
    this.quickLinkData();
  }

  render() {

    return (
      <div id="view_home">
        <Helmet title={`${getSiteProperties()?.title} - Home`} />

        {/* Link Rapidoas Para Categorias */}
        {this.state.quickLinks?.filter(quick => quick.type_filter === 'link').length > 0 && (
          <QuickCategories quickLinks={this.state.quickLinks.filter(quick => quick.type_filter === 'link')}></QuickCategories>
        )}

        <ContainerCards withContainer={true}>

          <WrapHeadline>

            <Headline
              type="big"
              title="Marcas"
              titleColor="#14233e"
              subTitleColor="#646466"
              withLink={false}
              textAlign="center"
            >
              As marcas também têm peças paradas: amostras, peças piloto, coleções passadas...
              o Marcas que Circulam é uma área exclusiva para marcas bacanas colocarem essas
              coisas para circular!<br /> Aqui você encontra achados, paga em reais e
              ganha pontos para continuar circulando no Scamb!
            </Headline>

            <Button
              type="default"
              buttonSize="medium"
              buttonColor="#14233e"
              textColor="#FFFFFF"
              buttonText="Saiba Mais!"
              buttonLink={"/sobre-o-scamb"}
              maxWidth={180}
            />

          </WrapHeadline>

        </ContainerCards>

        {/* Carousel De Banners */}
        <SliderBanners sliderData={banners}></SliderBanners>

        <ContainerPartners withContainer={true}>

          <Headline
            type="medium"
            title="Marcas"
            titleColor="#515157"
            subTitleColor="#646466"
            subTitle="Veja as marcas que circulam no Scamb"
            withLink={true}
            //linkText="Ver mais.."
            textAlign="left"
          />

          <Grid container className="Container" spacing={2}>

            {this.state.quickLinks?.filter(quick => quick.type_filter === 'banner').map(bannerQuickLink => (
              <Partner images={bannerQuickLink.banners} store={bannerQuickLink.url}></Partner>
            ))}
          </Grid>
        </ContainerPartners>

        <Showcases id="vitrines"></Showcases>

        <ScambChoices></ScambChoices>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scamber: state.scamberReducer.scamber,
    website: state.websiteReducer.website
  };
};

export default withRouter(connect(mapStateToProps)(Home));
