//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

//=====================================================================================
// #2 - Import * Account Layout Base
//=====================================================================================
import Layout from "../../components/account/Layout";

//=====================================================================================
// #3 - Import * Sweet Alert
//=====================================================================================
import Swal from 'sweetalert2';
import SweetAlert from 'sweetalert';

// Material - UI
import { useMediaQuery } from "@material-ui/core";
import CoverIMG from "./../../assets/images/cover.png";
import Avatar from "./../../assets/images/avatar.png";

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../../components/ui/advertiser-card";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { CurrentPage } from "../../components/ui/current-page";
import { NothingMessage } from "../../components/ui/nothing-message";

//=====================================================================================
// #3 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faTrashAlt, faCheck } from "@fortawesome/pro-light-svg-icons";


//=====================================================================================
// #7 - Import * Bootstrap Components
//=====================================================================================
import Button from 'react-bootstrap/Button'

//=====================================================================================
// #8 - Cart component
//=====================================================================================
import Favorite from '../../services/Favorito';
import Scamber  from '../../services/Scamber';
import Cart from '../../services/Cart';
import Anuncios from '../../services/Ad';
import Favorito from "../../services/Favorito";

import EventEmitter from './../../services/Events';

//=====================================================================================
// #4 - Fake Products
//=====================================================================================

function ShoppingCart (props) {

    // Styles
    const matches = useMediaQuery("(min-width:600px)");
    const history = useHistory();

    const [informacoesCart, setInformacoesCart] = useState({});
    const [cartItens, setCartItens] = useState([]);

    const map_products = props?.cart?.itens.map((product) => {
        return {
            name: product.titulo,
            id: product._id,
            price: product.pontos_por?.$numberDecimal
        }
    });

    const cart_id = props?.cart?._id;

    const products_price = map_products?.map((item) => +item.price)

    const products_total_price = products_price?.reduce((acc, curr) => acc + curr)

    const remove_item = (id) => {

        const findIdx = map_products?.findIndex((product) => product.id === id)

        return map_products?.slice(findIdx, 1)
    }

    // Delete Cart Item
    const deleteCartItem = (itemASerRemovido, index) => {

        SweetAlert({
                title: "Deletar produto?",
                icon: "warning",
                buttons: ["Cancelar", "Confirmar"],
            }).then((isConfirm) => {
                if (isConfirm) {
                    remove_item(itemASerRemovido);
                    
                    cart.deleteCart(itemASerRemovido, index, props.website)
                        .then((result)=>{
                            
                            Swal.fire({title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true})
                            EventEmitter.dispatch('cartChange');
                        })
                        .catch((error)=>{
                            favorite.formatErrorMessage(error.response, 'SCDECA')
                    })
                }
        });

    }

    let cart     = new Cart();
    let anuncios = new Anuncios();
    let scamber  = new Scamber();
    let favorite = new Favorito();

     // *  Redirect To Checkout
     const redirectCheckout = (e) => {  
        cart.changeStatusAdsOfCart({status: 'bloqueado'}, props.website)
        .then((response)=>{
            setTimeout(() => window.location.href = `/comprar`, 300);
        })
        .catch((error) =>  {
            scamber.formatErrorMessage(error.response, 'SCCHST')
        });
        
    }

    const redirectLogin = (e) => {
        e.preventDefault();
        props.history.push({ pathname: '/login', state: { cart: true } });
    }


    return (
        
        <Layout>

        <section id="view_shopping-cart">

            <CurrentPage icon={<Icon icon={faShoppingBag} />} title="Minha Sacola" />

            <div className="shopping-cart-items">

            {props.cart?.scamber_vendedor? 

                <AdvertiserCard 
                    storeLogo={props.cart.scamber_vendedor?.imagens?.avatar ? scamber.loadImage(props.cart.scamber_vendedor?.imagens?.avatar) : Avatar}
                    storeLink={`/loja/${props.cart.scamber_vendedor?.minha_loja?.url}`} 
                    storeTitle={props.cart.scamber_vendedor?.minha_loja?.titulo}
                    //storeLocation={cartObject.scamber_vendedor?.endereco[0].cidade + ' - ' + cartObject.scamber_vendedor?.endereco[0].estado} 
                    button={true}
                />

            :<></>}

            {props.cart &&
                props.cart?.itens?.map((product, index) => (
                    
                    <ProductThumbnail
                        image={scamber.loadImage(product.fotos[0]? product.fotos[0] : CoverIMG )}
                        title={product.titulo}
                        // oldPrice={product.}
                        price={product.pontos_por?.$numberDecimal}
                        freightType={product.operador_logistico? 'correios' : 'entrega_maos' }
                        productUrl={`/produto?id=${product._id}`}
                    >
                        
                    <Button 
                        className="trash-item"
                        color="primary"
                        autoFocus
                        type="button"
                        onClick={()=>deleteCartItem(product._id, index)}
                    >
                    <Icon icon={faTrashAlt} />
                    </Button>
                    </ProductThumbnail>

                ))
            }

            </div>
            
            {props.cart?.scamber_vendedor?
            <section className="checkout-button">
                <Button
                    className="accept"
                    onClick={(e) => props.scamber ? redirectCheckout() : redirectLogin(e)}
                    color="primary"
                    autoFocus
                    style={{ height: matches ? 35 : 40 }}
                    type="submit"
                >
                Finalizar compra
                <Icon icon={faCheck} />
                </Button>
                <a id="gmb" hidden href="#!">asdadasdasdasd</a>
            </section>
            :<> 
                <NothingMessage>
                    Você não tem produtos adicionados a sua sacola.<br/> <Link to="/">Navegue pelo site</Link> para adicionar produtos a sacola.
                </NothingMessage>
            </>}
            

        </section>

        </Layout>
        
    )
    
}

const mapStateToProps = state => {
	return {
        scamber: state.scamberReducer.scamber,
        cart: state.cartReducer.cart,
        website: state.websiteReducer.website
	}
}

export default withRouter(connect(mapStateToProps, { })(ShoppingCart));