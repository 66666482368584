
//=====================================================================================
// #1 - Import * Banners Images
//=====================================================================================

// Banner #1 Mob & Desk
import Persons00 from "../../../../../assets/images/banners/persons/banner_novo_01.jpg";
import Persons00Mob from "../../../../../assets/images/banners/persons/banner_novo_mob_01.jpg";

// Banner #1 Mob & Desk
import Persons01 from "../../../../../assets/images/banners/persons/pessoas_01.jpg";
import Persons01Mob from "../../../../../assets/images/banners/persons/mobile_pessoas_01.jpg";

// Banner #1 Mob & Desk
import Persons02 from "../../../../../assets/images/banners/persons/pessoas_02.jpg";
import Persons02Mob from "../../../../../assets/images/banners/persons/mobile_pessoas_02.jpg";

// Banner #1 Mob & Desk
import Persons03 from "../../../../../assets/images/banners/persons/pessoas_03.jpg";
import Persons03Mob from "../../../../../assets/images/banners/persons/mobile_pessoas_03.jpg";

const generateRandomNumber = () => Math.floor(Math.random() * 100) + 1;

//=====================================================================================
// #2 - Banners Json
//=====================================================================================
export const banners = [
  // {
  //   image_desk: Persons00,
  //   image_mob: Persons00Mob,
  //   link: `/resultados?tag=top20&limit=8&aleatory=${generateRandomNumber()}`,
  // },
  {
    image_desk: Persons01,
    image_mob: Persons01Mob,
    link: "/sobre-o-scamb",
  },
  {
    image_desk: Persons02,
    image_mob: Persons02Mob,
    link: "/criar-conta",
  },
  {
    image_desk: Persons03,
    image_mob: Persons03Mob,
    link: "/criar-conta",
  }
];