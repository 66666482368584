import React, { useEffect, useState } from "react";
import categories from "../../../../../data/categories/data-desk.json";
import {withRouter} from "react-router-dom";

//===============================================================================================================
// #4 - Import * Font Awesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

const MegaMenuList = ({ children }) => {
    return (
        <nav id="categories-menu-list">
            <ul className="categories-menu-list">{children}</ul>
        </nav>
    );
};

const CategoryMenuItem = ({ children, text, link, onClick, onMouseOver }) => {
    return (
        <li>
            <a
                href={link ? link : "#!"}
                className="text"
                onClick={onClick}
                onMouseOver={onMouseOver}
            >
                <div className="category-name">{text}</div>
                <div className="icon">
                <Icon icon={faChevronDown} />
                </div>
            </a>

            {children}
        </li>
    );
};

const MegaMenuContent = ({ children, title, onClick }) => {
    return (
        <div className="wrapper" onClick={onClick}>
            <div className="mega-menu-content">
                <div className="list">
                    <h1 className="menu-title">{title}</h1>
                    <ul>{children}</ul>
                </div>
            </div>
        </div>
    );
};

const MegaMenuItem = ({ link, text, className, onClick }) => {
    return (
        <li>
            <a href={link ? link : "#!"} className={`category-name ${className}`} onClick={onClick}>
                {text}
            </a>
        </li>
    );
};

function MegaMenu(props) {

    // * States
  //Lista Original de categorizações
  const [listCategorization, setListCategorization] = useState([]);

  //Lista ativa e visivel para o usuario no momento
  const [listActiveCategorization, setListActiveCategorization] = useState([]);

  //Parametros selecionados pelo usuario
  const [listSelectedCategorization, setListSelectedCategorization] = useState(
    []
  );

  //Label final concatenato de categorizações
  const [allCategorizationLabel, setAllCategorizationLabel] = useState("");

  //Label categorização selecionada
  const [category, setCategory] = useState("");

  const [menu, setMenu] = useState({});

  useEffect(() => {
    setListCategorization(categories);
    setListActiveCategorization(categories);
  }, []);

  useEffect(() => {
    setAllCategorizationLabel(
      listSelectedCategorization.reduce((label, selected, index) => {
        return (label += ` ${index > 0 ? ">" : ""} ${
          selected.categorization.nome
        }`);
      }, "")
    );
  }, [
    listSelectedCategorization.length,
    listSelectedCategorization[listSelectedCategorization.length - 1]
  ]);

  const historyPushCat = (lastId) => {
        props.history.push({
        pathname: "/resultados",
        search: `?category=${lastId}&limit=8&categoryList=${lastId}`
        });
    };

    const saveSelectedCategorizations = (categorizations, callback) => {
        const listCat = JSON.parse(JSON.stringify(categorizations));

        localStorage.setItem(
            "categorizations",
            JSON.stringify(listCat)
        );

        callback();
    };


    const applyFilter = (categorization, lastCategory) => {

        if(lastCategory) {
            categorization.push({ _id: lastCategory._id, nome: lastCategory.nome});
        }

        saveSelectedCategorizations(categorization, () => {
            historyPushCat(categorization[categorization.length - 1]._id);
        });


    }

  const handleMenu = (menuName, firstLevel) => {
    // let menuopen = {};

    // if (!firstLevel) {
    //   menuopen = Object.assign({}, menu);
    // }

    // if (menu[menuName] !== null) {
    //   menuopen[menuName] = !menu[menuName];
    // } else {
    //   menuopen[menuName] = true;
    // }

    // setMenu(menuopen);
  };

  const mapping = (categoriesList, firstLevel, level, categorization) => {

    if (firstLevel) {
      return (
        <MegaMenuList>
          {categoriesList.map((list, index) => {
            if (list?.tipos_produtos?.length > 0) {
              return (
                <>
                  <CategoryMenuItem
                    text={list.nome}
                    link="#!!"
                    onClick={() => handleMenu(list._id, firstLevel)}
                  >
                      <div className="wrap-main-list">
                      <div className="main-list">
                            {mapping(list.tipos_produtos, false, level + 1, list.filtros_dependencias ? list.filtros_dependencias.concat({_id: list._id, nome: list.nome}) : [{_id: list._id, nome: list.nome}])}
                      </div>
                      </div>
                  </CategoryMenuItem>
                </>
              );
            } else if (list?.categorias?.length > 0) {
              return (
                <>
                  <CategoryMenuItem
                    text={list.nome}
                    link="#!!"
                    onClick={() => handleMenu(list._id, firstLevel)}
                  >
                      <div className="wrap-main-list">
                      <div className="main-list">
                    {mapping(list.categorias, false, level + 1, list.filtros_dependencias ? list.filtros_dependencias.concat({_id: list._id, nome: list.nome}) : [{_id: list._id, nome: list.nome}])}
                    
                      </div>
                      </div>
                  </CategoryMenuItem>
                </>
              );
            } else if (list?.produtos?.length > 0) {
              //  console.log(list)
              return (
                <>
                  <CategoryMenuItem
                    text={list.nome}
                    link="#!!"
                    onClick={() => handleMenu(list._id, firstLevel)}
                  >
                    <MegaMenuContent
                      title=""
                      onClick={() => handleMenu(list._id, firstLevel)}
                    >
                        <div className="wrap-main-list">
                        <div className="main-list">
                      {mapping(list.produtos, false, level + 1, list.filtros_dependencias ? list.filtros_dependencias.concat({_id: list._id, nome: list.nome}) : [{_id: list._id, nome: list.nome}] )}
                        </div>
                        </div>
                    </MegaMenuContent>
                  </CategoryMenuItem>
                </>
              );
            }
          })}
          
        </MegaMenuList>
      );
    } else {
      return categoriesList.slice(0, 8).map((list, index) => {
        if (list?.tipos_produtos?.length > 0) {
          return (
            <>
              {/* menu[fatherName] */}
              <MegaMenuContent
                title={list.nome}
                onClick={() => handleMenu(list._id, firstLevel)}
              >
                {mapping(list.tipos_produtos, false, level + 1, categorization.concat([{_id: list._id, nome: list.nome}]))}
              </MegaMenuContent>
            </>
          );
        } else if (list?.categorias?.length > 0) {
          // console.log(list)

          return (
            <>
              {/* menu[fatherName] */}
              <MegaMenuContent
                title={list.nome}
                onClick={() => handleMenu(list._id, firstLevel)}
              >
                {mapping(list.categorias, false, level + 1, categorization.concat([{_id: list._id, nome: list.nome}]))}
              </MegaMenuContent>
            </>
          );
        } else if (list?.produtos?.length > 0) {
          // console.log(list)
          return (
            <>
              {/* menu[fatherName] */}
              <MegaMenuContent
                title={list.nome}
                onClick={() => handleMenu(list._id, firstLevel)}
              >
                {mapping(list.produtos, false, level + 1, categorization.concat([{_id: list._id, nome: list.nome}]))}
              </MegaMenuContent>
            </>
          );
        } else {
   
          if(index >= 7) {
              
              return  (<>
                <MegaMenuItem link="#!" className="item" text={list.nome} onClick={() => {applyFilter(categorization, list); console.log(categorization)}} />
                <li><a href="#!" onClick={() => {applyFilter(categorization); console.log(categorization)}}>Ver todos</a></li>
              </>)

          } else {
            // categorization.concat([list]);

            return (<MegaMenuItem link="#!" className="item" text={list.nome} onClick={() =>  { applyFilter(categorization, list); console.log(categorization)}} />)
          }
        }
      });
    }
  };


    return (
        <>
            {mapping(listCategorization, true, 1)}
        </>
    );
}

export default withRouter(MegaMenu);