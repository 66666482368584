//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";

import Button from '../../../../../components/design/form/button'

//===============================================================================================================
// #2 - Import * Styled Components
//===============================================================================================================
import { Container, Title, Text } from "./styles";

function Card ({ title, text, link, children }) {

    return (
        <Container className="m-card">

            <Title>
                {title}
            </Title>

            <Text>
                {children}
            </Text>

            <Button
                type="default"
                buttonSize="medium"
                buttonColor="#14233e"
                textColor="#FFFFFF"
                buttonText="Saiba Mais!"
                buttonLink={link}
                maxWidth={180}
            />
        </Container>
    )
}

export default Card;