import React, { Component } from "react";
import Breadcrumb from "../components/Breadcrumbs";
import { connect } from 'react-redux';

// Bootstrap
import Table from "react-bootstrap/Table";
import Helmet from "react-helmet";


class PrivacyPolicies extends Component {
  render() {
    return (
      <section id="view_common-page">
        <Helmet title={`${this.props.site_properties?.title} - Termos e condições`} />
        <Breadcrumb></Breadcrumb>

        <div className="_med-container">
          <div className="_terms-conditions-content">
            <h1 className="headline">Política de Privacidade da Informação</h1>

            <div className="_tag-date">Ultima Atualização: 16/12/2020</div>

            <div className="_wrap-topic _first">
              <p className="_topic-content">
                Scamb Atividades de Internet e Prestação de Serviço Ltda - CNPJ
                nº 39.479.710/0001-81.
                <br />
                <br />
                Em razão da natureza jurídica dos serviços que presta, o site
                www.scamb.com.vc recolhe e em alguns casos, revela informações
                fornecidas pelos seus usuários. Esta Política de Privacidade da
                Informação, descreve as informações que a plataforma coleta
                sobre seus usuários e as regras a serem adotadas, a fim de
                garantir aos mesmos total transparência no tratamento das
                informações disponibilizadas.
                <br />
                <br />
                Este documento é parte integrante e indissociável dos Termos e
                Condições Gerais de Uso da plataforma, portanto, a aceitação
                daquele documento no momento do cadastro pelo usuário, implica,
                automaticamente, na concordância com esta política.
                <br />
                <br />O usuário está ciente e concorda que ao realizar o
                cadastro na plataforma suas informações pessoais serão
                coletadas, conforme as seguintes regras:
              </p>

              <h1 className="_topic-title">
                <strong>1.</strong> DA INFORMAÇÃO E DA SEGURANÇA.
              </h1>
              <p className="_topic-content">
                <strong>1.1.</strong> Ao efetuar o cadastro, como condição para
                utilização dos serviços oferecidos pela plataforma, os usuários
                devem informar seus dados pessoais exatos e verdadeiros. Para
                tanto, a plataforma poderá recolher e armazenar as seguintes
                informações: nome completo, número do documento de identificação
                e informação de contato (como endereço de e-mail, endereço de
                residência ou domicílio e número de telefone). No momento do
                cadastro o usuário poderá inserir as informações dos dados
                bancários, contudo, essas informações somente serão utilizadas
                quando da efetivação de alguma transação.
              </p>
              <ul>
                <li>
                  <strong>1.1.1.</strong> A plataforma se assegura ao direito de
                  buscar a veracidade dos dados pessoais informados pelo
                  usuário, quem desde já autoriza, junto a entidades públicas,
                  companhias especializadas ou bancos de dados. O resultado
                  dessa busca será confidencial à plataforma.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>1.2.</strong> O usuário a qualquer tempo poderá
                solicitar à plataforma a extinção de seu cadastro, seguindo as
                regras constantes dos Termos e Condições Gerais de Uso do Scamb,
                podendo solicitar, inclusive, a remoção dos seus dados pessoais
                da base de dados da plataforma.
              </p>
              <p className="_topic-content">
                <strong>1.3.</strong> A plataforma poderá recolher e armazenar
                informações sobre as atividades dos usuários, tais como: URL, o
                navegador que utilizam no momento do acesso, o IP de acesso, as
                páginas visitadas, as buscas realizadas, os anúncios visitados,
                dentre outras informações que poderão ser armazenadas.
              </p>
              <p className="_topic-content">
                <strong>1.4.</strong> Ao efetivar o cadastro, a plataforma NÃO
                alugará, compartilhará ou venderá os dados pessoais informados
                pelo usuário, EXCETO na forma estabelecida neste documento.
              </p>
              <p className="_topic-content">
                <strong>1.5.</strong> Os usuários devidamente cadastrados terão
                uma senha de acesso para movimentação dentro da plataforma, que
                será criada pelo próprio usuário no momento cadastral, a qual é
                pessoal e intransferível, portanto, deve ser mantida sob
                absoluta confidencialidade e, em nenhum caso, deverá ser
                compartilhada com terceiros.
              </p>
              <ul>
                <li>
                  <strong>1.5.1.</strong> Em nenhuma hipótese, a plataforma,
                  diretamente ou através de seus funcionários, solicitará ao
                  usuário o compartilhamento de sua senha pessoal.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>1.6.</strong> Para facilitar a interação, os usuários da
                plataforma têm acesso a certos dados informados por outros
                usuários, tais como nome e localização, com a única finalidade
                de possibilitar a conclusão da transação.
              </p>
              <p className="_topic-content">
                <strong>1.7.</strong> A plataforma se obriga a observar todas as
                regras aplicáveis para garantir a segurança dos dados informados
                pelos usuários, inclusive a confidencialidade das informações
                pessoais, protegendo de perda, acesso ou compartilhamento não
                autorizado, nos termos da Lei nº 12.965/2014.
              </p>
              <ul>
                <li>
                  <strong>1.7.1.</strong> Apesar disso, a plataforma não se
                  responsabiliza por interceptações ilegais ou violação de
                  sistemas ou bases de dados por parte de pessoas não
                  autorizadas, bem como não se responsabilizará pela indevida
                  utilização da informação obtida através desses meios.
                </li>
              </ul>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>2.</strong> DO CANCELAMENTO E RETIFICAÇÃO DE DADOS.
              </h1>

              <p className="_topic-content">
                <strong>2.1.</strong> Ao efetuar o cadastro na plataforma, o
                usuário tem pleno conhecimento sobre seu direito de acessar,
                cancelar e atualizar seus dados a qualquer momento. Sendo que se
                responsabiliza e responde pela veracidade, exatidão e
                autenticidade dos dados informados à plataforma, inclusive, se
                comprometendo a mantê-los devidamente atualizados, sempre que
                sofrerem qualquer alteração.
              </p>
              <ul>
                <li>
                  <strong>2.1.1.</strong> Após a efetivação do cadastro, o
                  usuário poderá alterar, além dos dados pessoais, sua senha de
                  acesso pessoal.
                </li>
                <li>
                  <strong>2.1.2.</strong> O usuário se obriga a atualizar seus
                  dados regularmente, para possibilitar a operação na plataforma
                  junto a outros usuários. Para fazer qualquer modificação na
                  informação fornecida quando do cadastramento, o usuário deve
                  editar seu perfil criado na plataforma.
                </li>
                <li>
                  <strong>2.1.3.</strong> Em certos casos, serão mantidos nos
                  arquivos da plataforma, os dados tenham sido solicitados para
                  remoção com o objetivo de utilização na solução de
                  reclamações, ou seja, quando solicitados, os dados serão
                  removidos da plataforma, mas não serão imediatamente retirados
                  dos arquivos por razões legais e técnicas.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>2.2.</strong> A plataforma se assegura no direito de
                manter seus usuários atualizados sobre promoções, novidades,
                alterações, anúncios, entre outros, através do e-mail cadastrado
                na plataforma. Todavia, a qualquer tempo, os usuários podem
                optar pelo não recebimento de tais comunicações, para tanto,
                podem alterar suas preferências de e-mails em seu perfil na
                plataforma.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>3.</strong> DO REQUERIMENTO DAS AUTORIDADES
              </h1>

              <p className="_topic-content">
                <strong>3.1.</strong> A plataforma sempre que for solicitada,
                prestará informações para as autoridades competentes,
                objetivando o melhor cumprimento da legislação a qual está
                submetida, inclusive, quanto às matérias de proteção de direitos
                de propriedade industrial e intelectual, prevenção de fraudes,
                entre outros.
              </p>
              <ul>
                <li>
                  <strong>3.1.1.</strong> A plataforma poderá compartilhar os
                  dados informados por seus usuários à requerimento das
                  autoridades judiciais ou governamentais, ainda que inexista
                  ordem judicial.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>3.2.</strong> A plataforma também se reserva ao direito
                de comunicar os dados de seus usuários a outros usuários,
                entidades ou terceiros, quando haja motivos suficientes para
                considerar que a atividade de um usuário como suspeita de tentar
                ou de cometer um delito ou prejuízo a outras pessoas. Este
                direito será utilizado quando se considerar apropriado ou
                necessário para manter a integridade e a segurança, bem como
                para fazer cumprir os Termos e Condições Gerais de Uso.
              </p>
            </div>

            <div className="_wrap-topic">
              <h1 className="_topic-title">
                <strong>4.</strong> DA POLÍTICA DOS COOKIES.
              </h1>

              <p className="_topic-content">
                <strong>4.1.</strong> O usuário conhece e aceita que a
                plataforma pode utilizar um sistema de monitoramento mediante
                utilização de cookies. Os cookies são pequenos arquivos que se
                instalam no disco rígido, com uma duração limitada de tempo que
                ajudam a personalizar os serviços. Também serão ofertadas
                algumas funcionalidades que só estarão disponíveis pelo emprego
                dos cookies.
              </p>
              <ul>
                <li>
                  <strong>3.1.1.</strong> A plataforma poderá compartilhar os
                  dados informados por seus usuários à requerimento das
                  autoridades judiciais ou governamentais, ainda que inexista
                  ordem judicial.
                </li>
              </ul>
              <p className="_topic-content">
                <strong>4.2.</strong> Também será usada a informação obtida por
                intermédio dos cookies para analisar as páginas navegadas pelo
                usuário, as buscas realizadas, melhorar as iniciativas
                comerciais e promocionais, mostrar publicidade ou promoções,
                aperfeiçoar a oferta de conteúdos e bens, personalizar tais
                conteúdos, apresentação e serviços.
              </p>
              <p className="_topic-content">
                <strong>4.3.</strong> A instalação e permanência dos cookies no computador do usuário depende exclusivamente de sua vontade e pode ser eliminado de seu computador a qualquer tempo.
              </p>
              <p className="_topic-content">
                <strong>4.4.</strong> O usuário está ciente que a política estabelecida nesta cláusula, abrange a utilização de cookies pela plataforma e não a utilização de cookies por parte de terceiros. 
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(PrivacyPolicies);
