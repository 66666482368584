//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";
import styled from "styled-components";

//=====================================================================================
// #2 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * LazyLoad
//=====================================================================================
import { LazyLoadImage } from "react-lazy-load-image-component";

import useMediaQuery from '@material-ui/core/useMediaQuery';

//=====================================================================================
// #4 - Import * React Slick
//=====================================================================================
import Slider from "react-slick";

//=====================================================================================
// #1 - Import * React Router Dom
//=====================================================================================
import Pattern from "../../../../assets/images/after-mobile-banner.png";

//=====================================================================================
// #2 - Import * Styled Components
//=====================================================================================
import { WrapSlider } from "./styles";

//=====================================================================================
// #6 - Setting The Parameters
//=====================================================================================
const params = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
};

const SlickSlider = styled(Slider)`
    margin-left: auto;
    margin-right: auto;
    max-width: 1340px;
    
    ${props => props.theme.media("xs", `
        padding-top: 0;
        padding-bottom: 20px;
    `)}
    
    ${props => props.theme.media("sm", `
        padding-top: 0;
        padding-bottom: 30px;
    `)}

    .slick-list {

        .slick-track {

            .slick-slide {

                div {

                    .slider-item {

                       picture {

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border: 0;
                            max-width: 100%;
                            display: inline-block;
                            vertical-align: middle;
                        }
                       } 
                    }
                }
            }
        }
    }

    &::after {
        display: block;
        content: "";
        background-image: url(${Pattern});
        background-repeat: repeat-x;
        background-position: 0;
        background-size: contain;
        height: 8px;
        width: 90.55%;
        margin: -0.0625rem auto 0;
    }

    .slick-dots {
        bottom: 0;
    }
`

export const SliderBanners = ({ sliderData }) => {

    return (
        <WrapSlider>
            <SlickSlider {...params} className="slider-container">
                {sliderData.map((item, index) => (
                    <a href={item?.link} className="slider-item" key={index} onClick={item?.onClickEvent}>
                        <picture>
                            <source media="(max-width: 650px)" srcSet={item?.image_mob} />
                            <LazyLoadImage src={`${item?.image_desk}`} alt="Scamb" />
                        </picture>
                    </a>
                ))}
            </SlickSlider>
        </WrapSlider>
    )
}

export default SliderBanners;