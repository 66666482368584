//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//=====================================================================================
// #2 - Import * Services
//=====================================================================================
import QuickLink from '../../../../services/QuickLink';
import Showcase from "../../../../services/Showcase";

//=====================================================================================
// #3 - Import * UI Components
//=====================================================================================
import { Container } from "../../../../components/design/layout/container";

//=====================================================================================
// #4 - Instantiate Services
//=====================================================================================
const quickLink = new QuickLink();
const showcase = new Showcase();

//=====================================================================================
// #5 - Quick Links Structure Marking component
//=====================================================================================
const ContentQuickLinks = ({ children }) => {

    return (
      <div className="wrap-links">
        {children}
      </div>
    )
}

//=====================================================================================
// #6 - Quick Links Children Structure Marking component
//=====================================================================================
export const QuickLinkComponent = ({images, title, filters}) => {

  const openFilters = (filter) => {
    localStorage.setItem("categorizations", JSON.stringify(filter.categoryList));
    const categoriaList = filter.categoryList.length > 0 && filter.categoryList[filter.categoryList.length - 1]._id;
    const key = filter.filterbyKey;
    const condition = filter.condition;
    const brand = filter.brand;
    
    window.location.href = `/resultados?pageNumber=1${key ? "&key="+key : '' }${categoriaList ? '&categoryList='+categoriaList : '' }${brand ? '&brand='+brand : ''}${condition ? '&condition='+condition : ''}`;
  }

  return (
    <div className="quick-link" onClick={() => openFilters(filters)}>
      <div className="image">
        <img src={quickLink.loadImage(images[0])} alt="link rapido" />
      </div>
      <h2 className="title">{title ? title : "..."}</h2>
    </div>
  )
}

function QuickCategories(props) {

    // * States
    /*const [quickLinks, setQuickLinks] = React.useState([]);

    // * Effect
    React.useEffect(() => {

        const quickLinkData = async () => {

            try {
                const response = await quickLink.getAvailableQuickLink(props.website);
                const data = await response.data.payload;
    
                setQuickLinks((responsePaylod) => [...responsePaylod, data]);
                
            } catch(error){

                showcase.formatErrorMessage(error.response, "QLCTGS");

            }

        }

        // * Calls To Functions
        quickLinkData();

    }, []);*/
    
    return (

        <Container className="quick-links" withContainer={true} >

            <ContentQuickLinks>
                {props.quickLinks?.map(({_id, banners, title, filters}) => (
                    <QuickLinkComponent
                        key={_id}
                        images={banners}
                        title={title}
                        filters={filters}
                    />
                ))}
            </ContentQuickLinks>

        </Container>
    )
}


const mapStateToProps = (state) => {
  return {
    website: state.websiteReducer.website
  };
};

export default withRouter(connect(mapStateToProps)(QuickCategories));