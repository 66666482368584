import styled from "styled-components";

export const Popup = styled.div`
    background-color: ${({ theme }) => theme.color.violet};
    border-radius: 0.25rem;
    padding: 0.9375rem;
    max-width: 300px;
    width: 100%;
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-box-shadow: 0 6px 8px 4px rgba(100,100,100,0.1);
    box-shadow: 0 6px 8px 4px rgba(100,100,100,0.1);
`;

export const WrapIcon = styled.div`
    background-color: #37435B;
    height: 46px;
    width: 46px;
    min-width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    position: relative;

    svg {
        height: 17px;
        width: auto !important;
        position: relative;
        z-index: 3;

        path {
            fill: ${({ theme }) => theme.color.nude};
        }
    }

    &::after {
        content: "";
        display: flex;
        background-color: #85465A;
        height: 36px;
        width: 36px;
        min-width: 36px;
        position: absolute;
        top: 5px;
        left: 5px;
        margin-bottom: auto;
        z-index: 2;
        border-radius: 1000px;
    }

    &::before {
        content: "";
        display: flex;
        background-color: #763147;
        height: 46px;
        width: 46px;
        min-width: 46px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 1000px;
    }
`;

export const Content = styled.div`
    margin-left: 0.625rem;
    padding-right: 15px;
    cursor: pointer;
`;

export const Title = styled.h1`
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.white};
    text-align: left;
    margin-bottom: 0.375rem;
`;

export const Subtitle = styled.p`
    font-size: 0.6875rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.white};
    text-align: left;
    margin-bottom: 0;
`;

export const Button = styled.button`
    background-color: ${({ theme }) => theme.color.transparent};
    padding: 5px;
    min-width: 30px;
    border: 0;
    position: absolute;
    top: 8px;
    right: 8px;

    svg {
        height: 14px;
        width: auto !important;

        path {
            fill: rgba(255,255,255,0.5);
        }
    }
`;
