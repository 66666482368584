import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Chat from "./Chat";
import MessageItem from "../../../components/account/messenger/MessagesList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';

// Sweet Alert
import Swal from "sweetalert";

// Icons
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import Helmet from 'react-helmet';

// Fake Data
const received_messages = [
  {
    _id: 1,
    status: "read",
    image: "https://picsum.photos/600/600",
    message_date: "19/05/2020",
    username: "Marina",
    type: "proposal",
    product_title: "short listrado jeans",
  },
  {
    _id: 2,
    status: "read",
    image: "https://picsum.photos/600/600",
    message_date: "18/05/2020",
    username: "Marina",
    type: "doubt",
    product_title: "short listrado jeans",
  },
  {
    _id: 3,
    status: "read",
    image: "https://picsum.photos/600/600",
    message_date: "17/05/2020",
    username: "paula",
    type: "purchase",
    product_title: "vestido preto estampado",
  },
];

class Messenger extends Component {
  constructor(props) {
    super(props);
    this.chatMessengerChild = React.createRef();
    this.state = {
      chatMessenger: true,
      toggleClassName: false,
    };
  }

  showMessenger = () => {
	  
    const currentClass = this.state.true;
    this.chatMessengerChild.current.showMessenger();

    this.setState({
      toggleClassName: !currentClass
    });
  };

  closeMessenger = () => {
	this.chatMessengerChild.current.closeMessenger();
  }

  confirmDelete = () => {
      Swal({
        title: "Deletar mensagem?",
        text:
          "Ao clicar em confirmar todo o historico de mensagens com este usuário será deletado",
        icon: "info",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {

          Swal({
            title: "Mensagem apagada!",
            icon: "success",
            button: "Ok",
            dangerMode: true,
          });
        }
      });
  };
  render() {
    return (
      <section id="view_messenger">
        <Helmet title={`${this.props.site_properties?.title} - Mensagens`} />
        <section className="_wrap-messages">
          <Tabs defaultActiveKey="mensagens" onClick={this.closeMessenger}>
            <Tab eventKey="mensagens" title="Mensagens" id="wrap-messages-items">

              {received_messages.map(
                (
                  message
                ) => (
                  <MessageItem
                    onClick={this.showMessenger}
                    key={message._id}
                    status={message.status}
                    image={message.image}
                    message_date={message.message_date}
                    username={message.username}
                    type={message.type}
                    product_title={message.product_title}
                  >
                    <button type="button" className="btn btn-transparent" onClick={this.confirmDelete}>
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </MessageItem>
                )
              )}
            </Tab>

          </Tabs>
        </section>

        {this.state.chatMessenger ? (
          <Chat
            ref={this.chatMessengerChild}
            className={
              this.state.toggleClassName ? "_wrap-messenger _max-width" : null
            }
          ></Chat>
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  site_properties: state.websiteReducer.site_properties
})

export default connect(mapStateToProps, {})(Messenger);
