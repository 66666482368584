//===========================================================
// #1 - Base Imports
//===========================================================
import React from "react";

export const CurrentPage = ({icon, title, subtitle, className}) => {

    return (

        <div className={`_title-box ${className ? className : ""}`}>
            <span>
                {icon}
            </span>
            
            <div className="title-and-subtitle">
                
                <div className="title">
                {title}
                </div>
                
                {subtitle && (
                <div className="subtitle">
                    {subtitle}
                </div>
                )}
            </div>
        </div>
    )
}