//===============================================================================================================
// #1 - Import * Styled Components
//===============================================================================================================
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width: 240px) {
        padding: 30px 0;
    }

    @media(min-width: 768px) {
        padding: 100px 0;
    }

    @media(min-width: 1200px) {
        padding: 150px 0;
    }
`;

export const Main = styled.div`
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    background-color: #FFFFFF;
    max-width: 426px;
    width: 100%;
    -webkit-box-shadow: 0 6px 8px 4px rgba(100,100,100,0.1);
    box-shadow: 0 6px 8px 4px rgba(100,100,100,0.1);
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
`;

export const Content = styled.div`
    color: #3D3D40;
    text-align: center;
    padding: 70px 15px;
`;

export const IconChecked = styled.div`
    width: 70px;
    height: 70px;
    min-width: 70px;
    border-radius: 100px;
    border: 2px solid #A5DC86;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    #checked {
        width: 36px;
        height: auto;

        path {
            fill: #A5DC86;
        }
    }
`;

export const Title = styled.h1 `
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 0.9375rem;
`;

export const Text = styled.p `
    font-size: 0.8125rem;
    font-weight: 700;
    margin-top: ${props => props.marginTop ? props.marginTop+"px" : "0"};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom+"px" : "0"};
    line-height: 1.8;
`;