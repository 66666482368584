//===============================================================================================================
// #1 - Import * Styled Components
//===============================================================================================================
import styled from 'styled-components';

//===============================================================================================================
// #1 - Import * Exit Intent Popup 
//===============================================================================================================
import { Container } from "../../../components/design/layout/container";

export const ContainerCards = styled(Container)`

  background-color: ${props => props.theme.color.iceLight};
  padding: 30px 0;

    ._med-container {
      .hdline { 
        ${({ theme }) => theme.media("xs", `
            margin-bottom: 15px !important;

            div {

              h1 {
                font-size: 1.625rem !important;
                font-family: 'Oswald', sans-serif !important;
                text-transform: uppercase;
              }

              h2 {
                font-size: 0.8125rem !important;
                line-height: 1.6;
                max-width: 800px;
                width: 100%;
              }
            }
        `)}
        
        ${({ theme }) => theme.media("md", `
          margin-bottom: 25px !important;

            div {
                h1 {
                  font-size: 2.25rem !important;
                }

                h2 {
                  font-size: 0.875rem !important;
                }
              }
        `)}
      }
    }
`;

export const WrapHeadline = styled.div`
  text-align: center; 
`;

export const ContainerPartners = styled(Container)`

  background-color: ${props => props.theme.color.white};
  padding: 40px 0;

    ._med-container {
        .hdline {
            margin-bottom: 20px;
        }
    }
`;

export const WrapCards = styled.div((props) => {

  const { theme } = props;
  const { media } = { ...theme };

  return `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${media("xs", `
        flex-direction: column;
    `)}

    ${media("md", `
        flex-direction: row;
    `)}

    .m-card {

      &:nth-child(2) {
        
        ${media("xs", `
          margin: 15px 0;
        `)}
        
        ${media("sm", `
          margin: 0 15px;
        `)}
      }
    }
`
}) ;