import Service from './Service';

class Favorito extends Service {

    constructor() {
        super()
    }

    list( limit, website ) {
        website = null;
        return this.axiosInstance.get(`/favorite?limit=${limit}${website?'&website=' + website:''}`);
    }

    delete( idFavorite ) {
        return this.axiosInstance.delete(`/favorite`, {data:{idFavorite}});
    }

    find( idAd ){
        return this.axiosInstance.get(`/favorite/unique?idAd=${idAd}`);
    }

    create( idAd ){
        return this.axiosInstance.post(`/favorite`, idAd);
    }

}

export default Favorito;