//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const WrapperContent = styled.div`

    @media (min-width: 240px) {
        margin-top: 30px;
    }

    @media (min-width: 768px) {
        margin-top: 40px;
    }
`;