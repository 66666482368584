import styled from "styled-components";

export const OrderDetailsList = styled.div``;

export const OrderDetailItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    border-bottom: 0.0625rem dashed ${({ theme }) => theme.color.border};
    background-color: ${props => props.bgColor || "#FFFFFF"};

    &:last-child {
        border-bottom: 0;
    }
`;

export const Column = styled.div`
    &:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0.9375rem;
    }
    
    &:last-child {
        padding-right: 0.9375rem;
        padding-left: 0.625rem;

        ._product-price._1x.points,
        ._price.real {
            margin-top: 0;
            position: relative;
            top: 2px;
        } 
    }
`;

export const Title = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    display: flex;
    padding-right: 0.9375rem;
`;

export const Button = styled.button`
    border: 0;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 0;
    background-color: ${({ theme }) => theme.color.transparent};
    
    svg {
        height: 13px;
        width: auto !important;

        path {
            fill: ${({ theme }) => theme.color.black};
            opacity: 0.6;
        }
    }
`;

export const Value = styled.div`

    ._product-price._1x.points {

        .ui-item__price {
            span {
                color: ${props => props.valueColor || props.theme.color.black}
            }

            .price-tag-fraction {
                font-size: 16px;
            }

            .price-tag-symbol {
                font-size: 10px;
                top: 1px;
            }
        }
    }
`;

export const WrapperSkippedStitches = styled.div`
    background-color: ${({ theme }) => theme.color.light};
`;

export const WColumns = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0.625rem;
    padding-bottom: 0.9375rem;
`;

export const WColumn = styled.div`
    &:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0.9375rem;
    }
    
    &:last-child {
        padding-right: 0.9375rem;
    }
`;

export const ButtonIcon = styled.button`
    border: 0;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 0;
    background-color: rgba(43, 57, 81, 0.15);
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-top: 6px;
    margin-right: 10px;
    
    svg {
        height: 13px;
        width: auto;

        path {
            fill: ${({ theme }) => theme.color.blue};
        }
    }
`;

export const InfoTitle = styled.div`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    ._product-price._1x.points {
        position: relative;
        top: 3px;
        margin-left: 6px;
        margin-top: 0;

        .ui-item__price {
            span {
                color: ${props => props.valueColor || props.theme.color.black}
            }

            .price-tag-fraction {
                font-size: 16px;
            }

            .price-tag-symbol {
                font-size: 10px;
                top: 1px;
            }
        }
    }
`;

export const InfoSubTitle = styled(InfoTitle)``;
