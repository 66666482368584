//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Wrapper = styled.div `
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: ${props => props.type === "big" && "center" || props.type === "medium" && "space-between"};
`;

export const Column = styled.div `

    &:nth-child(1) {
        text-align: ${props => props.textAlign === "center" && "center" || props.textAlign === "right" && "right" || props.textAlign === "left" && "left"};

        h1, h2 {
            font-weight: 700;
        }

        h1 {
            color: ${props => props.titleColor};
            font-family: 'Oswald', sans-serif !important;
            text-transform: uppercase;

            @media (min-width: 240px) {
                font-size: ${props => props.type === "big" && "1.5rem" || props.type === "medium" && "0.875rem"};
            }
            
            @media (min-width: 768px) {
                font-size: ${props => props.type === "big" && "1.8125rem" || props.type === "medium" && "1.125rem"};
            }
        }
        
        h2 {
            color: ${props => props.subTitleColor};
            
            @media (min-width: 240px) {
                font-size: ${props => props.type === "big" && "0.875rem" || props.type === "medium" && "0.6875rem"};
                margin-top: ${props => props.type === "big" && "0.375rem" || props.type === "medium" && "0.25rem"};
            }
            
            @media (min-width: 768px) {
                font-size: ${props => props.type === "big" && "0.875rem" || props.type === "medium" && "0.6875rem"};
                margin-top: ${props => props.type === "big" && "0.625rem" || props.type === "medium" && "0.25rem"};
            }
        }

    }

    &:nth-child(2) {
        a {
            font-size: 0.8125rem;
            white-space: nowrap;
            padding-left: 0.9375rem;

            &:hover {
                color: ${props => props.theme.color.nude}
            }
        }
    }
`;