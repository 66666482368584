//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Wrap = styled.div `
    background-color: ${props => props.theme.color.white};
    padding: 40px 25px;
    border: 1px solid #F1F1F1;
    border-radius: 0.3125rem;
    /* min-width: 342px; */
    min-height: 262px;
    width: 100%;
`;

export const Container = styled.div `
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 340px;

    justify-content: space-between;
    flex-direction: column;
`;

export const Icon = styled.div `
    text-align: center;
    margin-bottom: 18px;

    img {
        height: auto;
        width: auto;
    }
`;

export const Title = styled.h1((props) => {

    const { theme } = props;
    const { color, media, fontWeight } = {...theme};

    return `
        text-align: center;
        color: ${color.black};
        font-weight: ${fontWeight.fw700};
        margin-bottom: 18px;
        
        ${media("xs", `
            font-size: 1rem;
        `)}
        
        ${media("sm", `
            font-size: 1.125rem;
        `)}
    `

});

export const Text = styled.p((props) => {

    const { theme } = props;
    const { color, media, fontWeight } = {...theme};

    return `
        text-align: center;
        font-size: 0.8125rem;
        color: ${color.gray};
        font-weight: ${fontWeight.fw700};
        margin-bottom: 0.669rem;
        max-width: 300px;
        width: 100%;
        
        ${media("md", `
            min-height: 116px;
        `)}
    `
});