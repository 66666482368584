//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #1 - Import * React Router Dom
//===============================================================================================================
import { Button } from "./styles";

//===============================================================================================================
// #1 - Import * React Router Dom
//===============================================================================================================
import { Link } from "react-router-dom";

export const BuyPointsButton = ({ link }) => {

    return (
        <Button>
            <Link to={link ? link : "#link-undefined"}> Comprar pontos </Link>
        </Button>
    )
}