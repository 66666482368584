import React from "react";

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faChevronRight, faChevronLeft, faTimes, faWallet, faCreditCardBlank, faCoins, faBookmark, faCalendarAlt, faEllipsisV, faFilter, faSearch, faFileInvoiceDollar } from "@fortawesome/pro-light-svg-icons";


export const PriceDefault = ({size, value}) => {

    return (
        <div className={"_price real " + size}>
            <div className="ui-item__price">
                <span className="price-tag-fraction"> {value} </span>
            </div>
        </div>
    )

};

export const Price = ({size, value}) => {

    return (
        <div className={"_price real " + size}>
            <div className="ui-item__price">
                <span className="price-tag-fraction"> {value} </span>
                <span className="price-tag-symbol">r$</span>
            </div>
        </div>
    )

};

export const PricePoints = (props) => {

    return (
        <>
            <div className={"_product-price _1x points " + props.size + " " + props.className}>

                {props.oldPrice && (
                    <div className="ui-item__discount-price">
                        <span className="price-tag-fraction"> {props.oldPrice} </span>
                        <span className="price-tag-symbol">pts</span>
                    </div>
                )}

                <div className="ui-item__price">
                    <span className="price-tag-fraction">{props.value}</span>
                    <span className="price-tag-symbol">pts</span>
                </div>
            </div>
        </>
    )

};

export const PricePointsIcon = (props) => {

    return (
        <>
            <div className={`_product-price with-icon _1x points ${props.color ? props.color : ""}`}>

                <div className="icon-coins">
                    <Icon icon={faCoins} />
                </div>
                <div className="ui-item__price">
                    <span className="price-tag-fraction">{props.value}</span>
                    <span className="price-tag-symbol">pts</span>
                </div>
            </div>
        </>
    )

};