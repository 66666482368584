import Service from './Service';

class Ad extends Service {

  constructor(){
    super();
  }

  insert( adInfo, website ) {
    return this.axiosInstance.post(`/ad${website? '?website=' + website: ''}`, adInfo, { headers: {'Content-Type': 'multipart/form-data'} });
  }

  update( adInfo, idAd, website ) {
    return this.axiosInstance.put(`/ad/update/${idAd}${website? '?website=' + website: ''}`, adInfo, { headers: {'Content-Type': 'multipart/form-data'} });
  }

  listActive( website ) {
    return this.axiosInstance.get(`/ad/active${website? '?website=' + website: ''}`);
  }

  listInactive( website ) {
    return this.axiosInstance.get(`/ad/inactive${website? '?website=' + website: ''}`);
  }

  find( idAd ) {
    return this.axiosInstance.get(`/ad/find/${idAd}`);
  }

  setState( adInfo ) {
    return this.axiosInstance.put(`/ad/setState/`, adInfo);
  }

  findForAll( idAd, website ){
    return this.axiosInstance.get(`/ad/find/all/${idAd}${website?'?website=' + website:''}`);
  }

  findOtherAdsOfSeller( idAd, website ){
    return this.axiosInstance.get(`/ad/find/scamberAds/${idAd}${website?'?website=' + website:''}`);
  }

  findAdByStoreName( storeUrl, limit ){
    return this.axiosInstance.get(`/ad/find/scamberStore/${storeUrl}?limit=${limit}`);
  }

  findAdsByUserSearch( searchString, limit, category, tag, aleatory, pageNumber, price, points, condition, categoryList, brand, deliveryType, storeUrl, size, website, showcaseId){
    //parametro removido ${pageNumber? '&pageNumber=' + pageNumber : ''}
    return this.axiosInstance.get(`ad/find/products/?limit=${limit}${searchString? '&key=' + searchString : ''}${category? '&category=' + category : ''}${tag? '&tag=' + tag : ''}${aleatory? '&aleatory=' + aleatory : ''}${price? '&price=' + price : ''}${points? '&points=' + points : ''}${condition? '&condition=' + condition : ''}${categoryList? '&categoryList=' + categoryList : ''}${brand? '&brand=' + brand : ''}${deliveryType? '&deliveryType=' + deliveryType : ''}${storeUrl? '&storeUrl=' + storeUrl : ''}${size? '&size=' + size : ''}${website? '&website=' + website : ''}${showcaseId ? '&showcaseId=' + showcaseId : ''}`);
  }

  findSpecificAdInfo(idAd){
    return this.axiosInstance.get(`/ad/find/all/` + idAd);
  }

  calculateCashbackAndTax(value, website) {
    return this.axiosInstance.get(`/ad/calculate/cashback_tax?adValue=${value}${website?'&website=' + website:''}`);
  }

}

export default Ad;
