//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React from "react";

//=====================================================================================
// #2 - Import * React Router Dom
//=====================================================================================
import { Link } from "react-router-dom";

//=====================================================================================
// #3 - Import * Styled Components Style
//=====================================================================================
import { Menu, MenuBars as Bars } from "./styles";

export const MenuBars = () => {

    return (
        <>
            <Menu>
                <Bars />
                <Bars />
                <Bars />
            </Menu>
        </>
    )
}