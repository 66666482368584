//===============================================================================================================
// #1 - Import * React
//===============================================================================================================
import React from "react";
import Swal from "sweetalert";
import Timer from "react-compound-timer";

//===============================================================================================================
// #2 - Import * React Router Dom
//===============================================================================================================
import { Link, withRouter, useHistory } from "react-router-dom";

//===============================================================================================================
// #3 - Import * Hooks
//===============================================================================================================
import useOutsideClick from "../../hooks/useOutsideClick";

//===============================================================================================================
// #4 - Import * Services
//===============================================================================================================
import Scamber from "../../services/Scamber";
import Loyalty from "../../services/Loyalty";
import EventEmitter from "../../services/Events";
import Cart from "../../services/Cart";
import Authentication from "../../services/Authentication";

//===============================================================================================================
// #5 - Import * Redux
//===============================================================================================================
import { connect } from "react-redux";
import { setBalance } from "../../redux/actions/balanceActions";
import { setScamber } from "../../redux/actions/scamberActions";
import { setCart } from "../../redux/actions/cartActions";

//===============================================================================================================
// #6 - Import * Header Pattern
//===============================================================================================================
import Header, { Column, ColumnItem } from "../../components/patterns/header";

//===============================================================================================================
// #7 - Import * Icons
//===============================================================================================================
import { MenuBars } from "../../components/design/foundation/icon";
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

//===============================================================================================================
// #8 - Import * UI Components
//===============================================================================================================
import { Logo } from "../../components/design/layout/logo";
import { ButtonLabel } from "../../components/design/form/button";
import { BuyPointsButton } from "../../components/patterns/header/components/form/button";
import { BadgeIcon } from "../../components/design/data-dispay/badge/styles";
import { CategoryMenuDialog } from "./components/feedback/modal";
import { HeaderTabs, Column as TabColumn, TabButton, Icon } from "../../components/patterns/header-tabs";
import { Avatar } from "../../components/design/data-dispay/avatar";
import { Dropdown } from "../../components/design/navigation/dropdown";
import { Menu, MenuItem as Item } from "../../components/design/navigation/menu";
import SearchForm, { SearchField, SubmitSearch, Switch, Label, SwitchField, Mark } from "../../components/design/layout/search";
import ScamberPoints from "../../components/design/data-dispay/scamb-points";
import Button from "../../components/design/form/button"

//===============================================================================================================
// #9 - Import * Images
//===============================================================================================================
import logoImage from "../../assets/images/logo.png";

//===============================================================================================================
// #10 - Import * Category Menu
//===============================================================================================================
import { WrapperMegaMenu } from "./components/navigation/mega-menu";
import MegaMenu from "./components/navigation/mega-menu/menu";
import CategoryMenu from "../../components/CategoryMenu";

import Cookie from './../../utils/Cookie';
import PopupMobile from "./components/popup-mobile";

//=====================================================================================
// #2 - Import * Universal Cookie
//=====================================================================================
import Cookies from "universal-cookie";

//===============================================================================================================
// #11 - Instantiated Services
//===============================================================================================================
const scamber = new Scamber();
const loyalty = new Loyalty();
const cart = new Cart();
const auth = new Authentication();
const cookies = new Cookies();

function HeaderScamb(props) {

    // * History * React Router Dom
    const history = useHistory();

    // * Refs
    const outsideClickRef = React.useRef();
    const categoryMenuDialog = React.useRef();

    // * States
    const [switchVisibility, setSwitchVisibility] = React.useState(false);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [checkbox, setCheckbox] = React.useState(true);
    const [dateTimer, setDateTimer] = React.useState(0);
    const [popup, setPopup] = React.useState(false);

    const cookie_popup = cookies.get("_popup_mobile_xs521")

    const searchKey = (e) => {
        e.preventDefault();

        if (checkbox) {
            history.push({ pathname: '/resultados', search: `?key=${search.replace(/&/g, 'ampersand')}&limit=8` });
        } else {
            history.push({ pathname: '/resultados', search: `?loja=${search.replace(/&/g, 'ampersand')}&limit=8` });
        }
    };

    const logoutUser = async () => {
        const response = await auth.doLogout();

        if (response.status === 200) {
            Cookie.setCookie('token', "", -1);
            setIsAuthenticated(false);
            props.setScamber(undefined);
            props.setBalance(undefined);
            props.history.push("/");
        }
    }

    // *  Click Outside To Close Notifications
    useOutsideClick(outsideClickRef, () => {
        if (switchVisibility === true) {
            setCheckbox(true);
            setSwitchVisibility(false);
        }
    });

    const generateSession = () => {
        const auth = new Authentication();

        auth.createUserSession().then(() => { }).catch((error) => {
            auth.formatErrorMessage(error.response, 'HCS');
        });
    };

    const loadTimer = React.useCallback(() => {
        cart
            .getTimer(props.website)
            .then((response) => {
                if (response.data.payload !== 0) {
                    setDateTimer(response.data.payload);
                }
            })
            .catch((error) => {
                cart.formatErrorMessage(error.response, 'BLOTMR')
            });
    }, [props.website]);


    // * Effects
    React.useEffect(() => {
        generateSession();

        // * Get Local Storage Token
        const getToken = Cookie.getCookie("token") ? true : false;

        EventEmitter.subscribe("loginSucess", () => {
            setIsAuthenticated(true);
            loadScamber();
        });

        EventEmitter.subscribe("cartChange", () => {
            loadCart();
        });

        // * Load Scamber Data
        const loadScamber = async () => {

            try {
                const response = await scamber.findScamber();
                const dataScamber = response.data.payload;

                if (response.status === 200) {

                    props.setScamber(dataScamber);

                    const responseLoyalty = await loyalty.balance();
                    const data = await responseLoyalty.data.payload;

                    if (responseLoyalty.status === 200) {
                        props.setBalance(data);

                    }
                }
            } catch (error) {
                console.error("ERR LOAD SCAMBER: ", error)
            }
        }

        const loadCart = async () => {
            try {
                const response = await cart.findCart(props.website).catch((e) => setDateTimer(0));
                const data = await response.data.payload;
                props.setCart(data);
                EventEmitter.dispatch("cartLoaded", data);
                if (data && data.tempo_expiracao) {
                    loadTimer();
                }

            } catch (error) {
                console.error("ERR CART: ", error);
                props.setCart(undefined);
            }

        }

        // * Init
        loadCart();

        // * If The Token Is Different From Null
        if (getToken === true) setIsAuthenticated(true);

        // * If isAuthenticated Is Equal To True
        if (getToken) {
            loadScamber();
        }
    }, []);

    const redirectWebSite = (websiteToGo) => {

        const url = window.location.href;
        const urlToGO = websiteToGo === 'marcas' ? 'marcas.' : '';

        if (url.indexOf('localhost') >= 0) {
            localStorage.setItem('website', websiteToGo);
            window.location.href = `http://localhost:3000`;
            return false;
        } else if (url.indexOf('d1') >= 0) {
            window.location.href = `https://${urlToGO}d1.scamb.com.vc`;
        } else if (url.indexOf('d2') >= 0) {
            window.location.href = `https://${urlToGO}d2.scamb.com.vc`;
        } else if (url.indexOf('h1') >= 0) {
            window.location.href = `https://${urlToGO}h1.scamb.com.vc`;
        } else if (url.indexOf('h2') >= 0) {
            window.location.href = `https://${urlToGO}h2.scamb.com.vc`;
        } else {
            window.location.href = `https://${urlToGO}scamb.com.vc`;
        }

    }

    const AlertSwall = async () => {
        return Swal({
            title: "Minha Sacola",
            text: "Sua sacola de compras expira em alguns minutos.",
            icon: "info",
            buttons: ["Fechar", "Finalizar compra"],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                history.push("/comprar");
            }
        });
    }

    const handleCookie = () => {

        // * Cookie Expires
        var date = new Date();
        var minutes = 1200;
        date.setTime(date.getTime() + (minutes * 60 * 1000));

        cookies.set("_popup_mobile_xs521", "true", { path: "/", expires: date });

        // * Close Popup
        setPopup(false);
    }

    const handlePopup = () => {

        if (cookie_popup === undefined) {
            setPopup(!popup);
        } else {
            setPopup(popup);
        }
    }

    return (
        <>
            <div style={{ display: "none", visibility: "hidden", opacity: "0" }} >
                {dateTimer ? (
                    <Timer
                        initialTime={dateTimer}
                        direction="backward"
                        checkpoints={[
                            {
                                time: 300000,
                                callback: () => AlertSwall(),
                            },
                        ]}
                    >
                        {({ start, stop }) => (
                            <React.Fragment>
                                <Timer.Minutes
                                    formatValue={(text) =>
                                        text.toString().length > 1 ? text : "0" + text
                                    }
                                />
                                :
                                <Timer.Seconds
                                    formatValue={(text) =>
                                        text.toString().length > 1 ? text : "0" + text
                                    }
                                />
                                <button hidden onClick={start}>
                                    Start
                                </button>
                            </React.Fragment>
                        )}
                    </Timer>
                ) : (
                    <></>
                )}
            </div>


            <Timer
                initialTime={7000}
                direction="backward"
                style={{ backgroundColor: "red" }}
                checkpoints={[
                    {
                        time: 3000,
                        callback: () => handlePopup()
                    }
                ]}
            >
                <div style={{ display: "none !important", visibility: "hidden !important", opacity: "0 !important" }} >

                    {() => (
                        <React.Fragment>
                            <Timer.Minutes /> minutes
                            <Timer.Seconds /> seconds
                            <Timer.Milliseconds /> milliseconds
                        </React.Fragment>
                    )}
                </div>
            </Timer>

            {popup && (
                <PopupMobile title="Novidade!" subTitle="Conheça as lojas das Marcas que Circulam no Scamb!" onClick={handleCookie} />
            )}

            <HeaderTabs tabColor="#101B31">
                <TabColumn>
                    <TabButton active="active" activeColor="#14233E">
                        <Link to="#!" onClick={() => redirectWebSite('scamb')} style={{ textAlign: 'left' }}>
                            <Icon> <I icon={["far", "store"]} /> </Icon>
                            <div className="text">
                                Pessoas <span>que circulam</span>
                            </div>
                        </Link>
                    </TabButton>

                    <TabButton>
                        <Link to="#!" onClick={() => redirectWebSite('marcas')} style={{ textAlign: 'left' }}>
                            <Icon> <I icon={["fal", "bags-shopping"]} /> </Icon>
                            <div className="text">
                                Marcas <span>que circulam</span>
                            </div>
                        </Link>
                    </TabButton>
                </TabColumn>

                <TabColumn>
                    {isAuthenticated && props.scamber?.cnpj === undefined && (
                        <ScamberPoints link="./minha-carteira" title="Meus pontos" points={props.balance?.currentBalance || 0} />
                    )}

                    <ColumnItem>
                        {props.scamber && props.scamber.imagens?.avatar ? (
                            <Avatar size="small" image={scamber.loadImage(props.scamber.imagens?.avatar)} />
                        ) : isAuthenticated && <Avatar size="small" />}

                        <Dropdown>
                            <Menu>
                                <Item link={"/loja/" + props.scamber?.minha_loja?.url} icon={<I icon={["far", "eye"]} />} title="Loja" subtitle="Ver minha loja" />
                                <Item link="/meus-anuncios" icon={<I icon={["far", "bullhorn"]} />} title="Anúncios" subtitle="Meus anúncios" />
                                <Item link="/minha-carteira" icon={<I icon={["far", "wallet"]} />} title="Carteira" subtitle="Minha carteira" />

                                {props.scamber?.cnpj === undefined && (
                                    <Item link="/metodos-de-pagamento" icon={<I icon={["far", "credit-card-blank"]} />} title="Formas de pagamento" subtitle="Minhas formas de pagamento" />
                                )}

                                {props.scamber?.cnpj === undefined && (
                                    <Item link="/minhas-compras" icon={<I icon={["far", "shopping-cart"]} />} title="Compras" subtitle="Minhas compras" />
                                )}

                                <Item link="/minhas-vendas" icon={<I icon={["far", "store"]} />} title="Vendas" subtitle="Minhas vendas" />

                                {props.scamber?.cnpj === undefined && (
                                    <Item link="/meus-favoritos" icon={<I icon={["far", "heart"]} />} title="Favoritos" subtitle="Meus Favoritos" />
                                )}

                                <Item link="/meu-perfil" icon={<I icon={["far", "cog"]} />} title="Perfil" subtitle="Meu perfil" />
                                <Item link="/meus-enderecos" icon={<I icon={["far", "id-card"]} />} title="Endereços" subtitle="Meus endereços" />

                                {props.scamber?.cnpj === undefined && (
                                    <Item link="/convidar-amigos" icon={<I icon={["far", "user-friends"]} />} title="Convide amigos" subtitle="Convide amigos e ganhe pontos" />
                                )}

                                <Item link="/ajuda" icon={<I icon={["far", "question-circle"]} />} title="Ajuda" subtitle="Central de ajuda" />
                                <Item link="#!" icon={<I icon={["far", "sign-out"]} />} title="Sair" subtitle="" onClick={(e) => { e.preventDefault(); logoutUser() }} />
                            </Menu>
                        </Dropdown>
                    </ColumnItem>

                </TabColumn>

            </HeaderTabs>

            <Header theme="scamb">

                <Column className="column-1">

                    <ColumnItem>
                        <ButtonLabel icon={<MenuBars />} text="Menu" onClick={() => categoryMenuDialog.current.openModal()} />
                    </ColumnItem>

                    <ColumnItem>
                        <Logo link="/" image={logoImage} alt="Scamb" />
                    </ColumnItem>

                    <ColumnItem>
                        {props.scamber?.cnpj === undefined && (
                            <BuyPointsButton link="./comprar-pontos" />
                        )}

                        {isAuthenticated && props.scamber?.cnpj === undefined && (
                            <ScamberPoints link="./minha-carteira" title="Meus pontos" points={props.balance?.currentBalance || 0} />
                        )}

                        {!isAuthenticated && (
                            <Button type="default"
                                buttonSize="small"
                                buttonColor="#FFFFFF"
                                textColor="#3D3D40"
                                buttonText="Entrar"
                                buttonLink="/login"
                                maxWidth={200}
                            />
                        )}
                    </ColumnItem>

                </Column>

                <Column className="column-2">

                    <ColumnItem>
                        {/* Hack To Close Switch With Outside Click */}
                        <span ref={outsideClickRef}>
                            <SearchForm onSubmit={(e) => searchKey(e)}>
                                <SearchField
                                    placeholder={`Busque por "Blusas"`}
                                    type="text"
                                    name="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onClick={() => setSwitchVisibility(true)}
                                />

                                {switchVisibility && (
                                    <Switch>
                                        <Label>
                                            <SwitchField
                                                type="radio"
                                                name="switch"
                                                id="product"
                                                defaultChecked={checkbox}
                                                onChange={() => setCheckbox(true)}
                                            />
                                            <Mark text="Produtos" />
                                        </Label>
                                        <Label>
                                            <SwitchField type="radio" name="switch" id="stores" onChange={() => setCheckbox(false)} />
                                            <Mark text="Lojas" />
                                        </Label>
                                    </Switch>
                                )}

                                <SubmitSearch type="submit" />
                            </SearchForm>
                        </span>
                    </ColumnItem>

                </Column>

                <Column className="column-3">

                    {!isAuthenticated && (
                        <ColumnItem>
                            <Button
                                type="default"
                                buttonSize="small"
                                buttonColor="#FFFFFF"
                                iconColor="#3D3D40"
                                buttonText={<I icon={["fal", "question-circle"]} />}
                                buttonLink="/ajuda"
                                maxWidth={200}
                            />
                        </ColumnItem>
                    )}

                    {!isAuthenticated && (
                        <ColumnItem>
                            <Button
                                type="default"
                                buttonSize="small"
                                buttonColor="#FFFFFF"
                                textColor="#3D3D40"
                                buttonText="Entrar"
                                buttonLink="/login"
                                maxWidth={200}
                            />
                        </ColumnItem>
                    )}

                    {props.scamber?.cnpj === undefined && (
                        <ColumnItem>
                            <Button
                                type="default"
                                buttonSize="medium"
                                buttonColor="#C2403F"
                                textColor="#FFFFFF"
                                buttonText="Quero vender"
                                buttonLink="/criar-novo"
                                maxWidth={200}
                            />
                        </ColumnItem>
                    )}

                    {props.scamber?.cnpj === undefined && (
                        <ColumnItem>
                            <Button
                                type="bordered"
                                buttonSize="medium"
                                buttonColor="#FFFFFF"
                                textColor="#C2403F"
                                buttonText="Comprar pontos"
                                buttonLink="/comprar-pontos"
                                borderColor="#C2403F"
                                maxWidth={200}
                            />
                        </ColumnItem>
                    )}

                    {isAuthenticated && (
                        <ColumnItem>
                            <Button
                                type="default"
                                buttonSize="small"
                                buttonColor="#FFFFFF"
                                iconColor="#3D3D40"
                                buttonText={<I icon={["fal", "heart"]} />}
                                buttonLink="/meus-favoritos"
                                maxWidth={200}
                            />
                        </ColumnItem>
                    )}

                    <ColumnItem>
                        <Button
                            type="default"
                            buttonSize="small"
                            buttonColor="#FFFFFF"
                            iconColor="#3D3D40"
                            buttonText={
                                <>
                                    <I icon={["fal", "shopping-bag"]} />
                                    {props.cart?.itens?.length > 0 && (
                                        <BadgeIcon />
                                    )}
                                </>
                            }
                            buttonLink="/minha-sacola"
                            maxWidth={200}
                        />
                    </ColumnItem>

                </Column>
            </Header>

            <WrapperMegaMenu>
                <MegaMenu></MegaMenu>
            </WrapperMegaMenu>

            <CategoryMenuDialog ref={categoryMenuDialog}>
                <div className="categories-menu">
                    <CategoryMenu onCloseModal={() => categoryMenuDialog.current.closeModal()} />
                </div>
            </CategoryMenuDialog>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        scamber: state.scamberReducer.scamber,
        cart: state.cartReducer.cart,
        balance: state.balanceReducer.balance,
        site_properties: state.websiteReducer.site_properties,
        website: state.websiteReducer.website
    };
};

export default withRouter(
    connect(mapStateToProps, { setScamber, setBalance, setCart })(HeaderScamb)
);