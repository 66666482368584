import React from 'react';

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Price, PricePoints } from "../../../../../components/ui/price";

import { Wrapper, Head, Label, RadioInput, WrapIcon, Title, ValueInfo, Mark, ButtonChevronDown, Content, Footer } from "./styles";

function Shipping({ shippingType, shippingValue, footerText, inputName, inputChecked, onChange, children }) {

    const [showContent, setShowContent] = React.useState(false);

    const handleContent = () => setShowContent(content => content = !showContent)

    return (
        <Wrapper className="shipping">
            <Head>
                <Label>
                    <RadioInput type="radio" name={inputName} checked={inputChecked} onChange={onChange} />
                    <Mark className="mark"><Icon icon={["far", "check"]} /></Mark>
                    <WrapIcon>
                        {shippingType === "Correios" ? <Icon className="truck" icon={["fal", "truck"]} /> : <Icon className="truck" icon={["fal", "hand-holding-box"]} />}
                    </WrapIcon>
                    <ValueInfo>
                        <Title>{shippingType}</Title>
                        <Price value={shippingValue} />
                    </ValueInfo>
                </Label>

                <ButtonChevronDown onClick={handleContent}>
                    {showContent ? <Icon icon={["far", "chevron-up"]} /> : <Icon icon={["far", "chevron-down"]} />}

                </ButtonChevronDown>
            </Head>

            {showContent && (
                <Content>
                    {children}
                </Content>
            )}

            {footerText && (
                <Footer>
                    {footerText}
                </Footer>
            )}
        </Wrapper>
    )
}

export default Shipping
