
//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Link, withRouter } from "react-router-dom";

//===============================================================================================================
// #1 - Import * Utils
//===============================================================================================================
import "jquery-mask-plugin";
import $ from "jquery";

//===============================================================================================================
// #1 - Import * Redux
//===============================================================================================================
import { connect } from 'react-redux';

//===============================================================================================================
// #1 - Import * Services
//===============================================================================================================
import Extract from './../../services/Extract';
import Pedido from './../../services/Pedido';

//===============================================================================================================
// #1 - Import * React Hook Forms
//===============================================================================================================
import { useForm } from 'react-hook-form';

//===============================================================================================================
// #2 - Import * Account Layout Base
//===============================================================================================================
import Layout from "../../components/account/Layout";

//===============================================================================================================
// #3 - Import * Sweet Alert
//===============================================================================================================
import Swal from "sweetalert2";

//===============================================================================================================
// #4 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";

//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================

import formatTitle from "../../utils/FormatTitle";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { PricePoints, PricePointsIcon, Price, PriceDefault } from "../../components/ui/price";
import { Accordion } from "../../components/ui/accordion";
import { ListContent, ListItem } from "../../components/ui/list-content";
import { CurrentPage } from "../../components/ui/current-page";
import { TimelinePoints, StatusExtract } from "../../components/ui/order-timeline";
import { ProductThumbnail } from "../../components/ui/product-thumbnail";
import { ProductThumbnailItems } from "../../components/ui/product-thumbnail";

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faChevronLeft, faTimes, faWallet, faCoins, faBookmark, faCalendarAlt, faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { faPlus, faCheck, faCircle, faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { NothingMessage } from "../../components/ui/nothing-message";

//===============================================================================================================
// #8 - Import * Material UI Components
//===============================================================================================================
import { Dialog, DialogContent, DialogTitle, useMediaQuery, Slide, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconLabel from "../../components/ui/form/icon-label";

import { subMonths, addMonths, format } from 'date-fns';

//===============================================================================================================
// #9 - Create Material UI Modal Dialog
//===============================================================================================================
const ModalDialog = forwardRef((props, ref) => {

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile 
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <button type="button" className="btn" onClick={handleClose}>
              <Icon
                icon={matches ? faTimes : faChevronLeft}
              ></Icon>
            </button>
            <div className="_title-view">{props.dialogTitle}</div>
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <div className="subtitle">
              {props.subTitle}
            </div>

            {props.children}

          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

//===============================================================================================================
// #10 - Create Material UI Modal Dialog
//===============================================================================================================
const DetailModal = forwardRef((props, ref) => {

  // * Material UI Styles - useMediaQuery
  const matches = useMediaQuery("(min-width:600px)");

  // # Define States
  const [open, setOpen] = React.useState(false);

  // * Material UI Styles - useTheme
  const theme = useTheme();

  // # Define Material UI Modal Dialog Fullscreen on mobile 
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


  // # Define Functions

  // * Set Material UI Modal Dialog Open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // * Set Material UI Modal Dialog Close
  const handleClose = () => {
    setOpen(false);
  };

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    openModal: () => handleClickOpen(),
    closeModal: () => handleClose(),
  }));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        maxWidth={"sm"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={`DialogExtractDetail`}
      >
        <DialogTitle className="dialog-title" id="responsive-dialog-title">
          <div className="_view-head-content">
            <div className="_title-view">{props.dialogTitle}</div>
            <IconLabel icon={<Icon icon={faTimes} />} text="Fechar" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent className={props.className}>
          <Typography component={"span"} variant={"body2"}>
            <div className="subtitle">
              {props.subTitle}
            </div>

            {props.children}

          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

// * Material UI Dialog Slide Transition Effect
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//===========================================================
// #11 - Material UI Set Panel
//===========================================================
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const ActionBox = (props) => {

  return (
    <Link to={props.link} className="action-box">
      <div className="top">
        <span className="icon">
          {props.icon}
          <span>
            <Icon icon={faPlus} />
          </span>
        </span>
      </div>
      <div className="bottom">
        <h1 className="title">
          {props.title}
        </h1>
      </div>
    </Link>
  )
}

const HistoryBlock = (props) => {

  const checkStatus = (status) => {
    switch (status) {
      case 'PAGAMENTO_PENDENTE':
        return 'Pagamento Pendente';
      case 'POSTADO':
        return 'Postado';
      case 'ETIQUETA_GERADA':
        return 'Aguardando Envio';
      case 'PAGO':
        return 'Pago';
      case 'PONTUADO':
        return 'Pontuado';
      case 'FINALIZADO':
        return 'Finalizado';
      case 'CANCELADO':
        return 'Cancelado';
      default:
        return 'Status Não Mapeado'
    }
  }

  return (

    <div className="_history-block" onClick={props.onClick}>
      <div className={`punctuated ${props.punctuated ? "active" : "no-active"}`}>
        <div className="icon">
          <Icon icon={faCoins} />
          <div className="check">
            <Icon icon={faCheck} />
          </div>
        </div>
      </div>
      <div className="_cols">
        <div className="_col">
          <h1 className="title"> {formatTitle(props.title)} </h1>

          <div className="items">
            <div className="item">
              <div className="icon">
                <Icon icon={faBookmark} />
              </div>
              <h4 className="title">
                {formatTitle(props.type)}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCalendarAlt} />
              </div>
              <h4 className="title">
                {props.date}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <Icon icon={faCoins} />
              </div>
              <h4 className="title">
                <PricePoints value={props.points} className={props.color} />
              </h4>
            </div>
            {/* <div className="item">
              <div className="icon">
                <Icon icon={faFileInvoiceDollar} />
              </div>
              <h4 className="title">
                {checkStatus(props.status)}
              </h4>
            </div> */}
          </div>
        </div>
        <div className="_col">
          <button type="button" className="btn">
            <Icon icon={faEllipsisV} />
          </button>
        </div>
      </div>
    </div>
  )
}

const HistoryBlockPJ = (props) => {

  let currentStatus;
  let statusTitle;
  let status = props.status;

  switch (status) {
    case 'pending':
      currentStatus = "pending";
      statusTitle = "Pendente";
      break;
    case 'processing':
      currentStatus = "processing";
      statusTitle = "Processando";
      break;
    case 'accepted':
      currentStatus = "accepted";
      statusTitle = "Concluído";
      break;
    case 'rejected':
      currentStatus = "rejected";
      statusTitle = "Cancelado";
      break;
    default:
      currentStatus = "Concluído";
      statusTitle = "accepted";
  }

  return (

    <div className="_history-block" onClick={props.onClick}>
      <div className={`punctuated ${status === "accepted" ? "active" : "no-active"}`}>
        <div className="icon">
          <Icon icon={["far", "university"]} />
          <div className="check">
            <Icon icon={faCheck} />
          </div>
        </div>
      </div>
      <div className="_cols">
        <div className="_col">
          <h1 className="title"> {formatTitle(props.title)} </h1>

          <div className="items">
            {currentStatus && (
              <div className="item">
                <div className={`icon status ${currentStatus}`}>
                  <Icon icon={faBookmarkSolid} />
                </div>
                <h4 className="title">
                  {formatTitle(statusTitle)}
                </h4>
              </div>
            )}
            <div className="item">
              <div className="icon">
                <Icon icon={faCalendarAlt} />
              </div>
              <h4 className="title">
                {props.date}
              </h4>
            </div>
            <div className="item">
              <div className="icon">
                <span className="currency">R$</span>
              </div>
              <h4 className="title">
                <PriceDefault value={props.price} className={props.color} size="small" />
              </h4>
            </div>
          </div>
        </div>
        <div className="_col">
          <button type="button" className="btn">
            <Icon icon={faEllipsisV} />
          </button>
        </div>
      </div>
    </div>
  )
}

const DateField = forwardRef((props, ref) => {

  // * States
  const [fieldDate, setFieldDate] = useState(false);

  const handleField = () => setFieldDate(true);

  // * Set Function Using useImperativeHandle();
  useImperativeHandle(ref, () => ({
    changeTypeField: () => handleField(),
  }));

  return (
    <div className="_wrap-input-group">
      <label className="_title-label-input"> {props.title} </label>
      <div className="input-group">

        {props.children}

        {!fieldDate && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="start-date">
              <span className="icon" />
            </span>
          </div>
        )}
      </div>
    </div>
  )
})

const SelectFilter = ({ title, children }) => {

  return (
    <div className="select-filter">
      <h2 className="title">{title}</h2>
      <div className="wrap-filters-button">
        {children}
      </div>
    </div>
  )
}

const ButtonFilter = ({ inputType, inputName, buttonText, onClick, onChange, isChecked, id }) => {

  return (
    <div className="filter-button">
      <label>
        <input type={inputType} name={inputName} defaultChecked={isChecked} id={id ? id : ""} onChange={onChange} onClick={onClick} />
        <div className="text">
          {buttonText}
        </div>
        <span className="overlay"></span>
      </label>
    </div>
  )
}

const AccountBankItem = ({ title, value }) => {
  return (
    <li>
      {title}: <span>{value}</span>
    </li>
  )
}

const AccountBankCard = ({ bank, children }) => {
  return (
    <div className="address-card buy-page active">
      <label>

        <div className="_cols">
          <div className="_col">
            <div className="icon">
              <Icon icon={["far", "check"]} />
            </div>
          </div>

          <div className="_col">
            <span className="tag"> {bank} </span>

            <ul>
              {children}
            </ul>
          </div>
        </div>
      </label>
    </div>
  );
};

function MyWallet(props) {

  // * Refs
  const refDateStart = useRef();
  const refDateEnd = useRef();
  const refDialogModal = useRef();
  const refDialogModalPoints = useRef();
  const refSellPointsDialogModal = useRef();

  // * New Refs
  const refDetailModalSale = useRef();
  const refDetailModalBuy = useRef();
  const refDetailModalBuyPoints = useRef();
  const refDetailModalBonusPoints = useRef();
  const refTransferMoney = useRef();

  // * States
  const [filterExtract, setFilterExtract] = useState(false);
  const [filterPending, setFilterPending] = useState(false);
  const [filterExpire, setFilterExpire] = useState(false);

  const [extractHistoric, setExtractHistoric] = useState([]);
  const [limit, setLimit] = useState(3);
  const [tabValue, setTabValue] = useState(0);
  const [modalValue, setmodalValue] = useState();
  const [findOrder, setFindOrder] = useState();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [historyFilter, setHistoryFilter] = useState();

  const [selectedHistoricItem, setSelectedHistoricItem] = useState();
  const [hideSeeMore, setHideSeeMore] = useState(false);

  const [extractItens, setExtractItens] = useState([]);
  const [extractExpirationItens, setExtractExpirationItens] = useState([]);
  const [pointsAwaitingApproval, setPointsAwaitingApproval] = useState(0);
  const [awpHistoric, setAwpHistoric] = useState([]);
  const [limitToShow, setLimitToShow] = useState(4);
  const [limitToShowAwp, setLimitToShowAwp] = useState(4);
  const [lastUpdate, setlastUpdate] = useState(new Date());
  const [limitToShowExpiration, setLimitToShowExpiration] = useState(4);

  // * PJ States
  const [pjBalance, setPJBalance] = useState([]);
  const [pjBankTransfers, setPJBankTransfers] = useState([]);
  const [pjReceivables, setPJReceivables] = useState([]);
  const [originalAmount, setOriginalAmount] = useState(0);
  const [checkMoney, setCheckMoney] = useState(0);

  // * State Data Loading
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, register, formState, errors, setValue } = useForm({ mode: "onChange" });

  const transferForm = (data) => {

    const { fieldMoney } = data;
    const formatMoney = fieldMoney.replace(",", ".");
    const money = fieldMoney.replace(/\./g, "").replace(",", ".");

    if (formatMoney < 5) {
      Swal.fire({
        title: 'Valor minimo para ser transferido e R$5.00 reais.',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Ok",
      });
    } else {
      requestWithdrawal({ amount: money });
    }

  };

  const handleMoney = (e) => {
    const { value } = e.target;
    const newValue = Number(value.replace(/\./g, "").replace(",", "."));

    if (newValue) {
      if (
        newValue >
        Number(originalAmount.replace(/\./g, "").replace(",", ".")).toFixed(2)
      ) {
        setValue("fieldMoney", originalAmount);
      }
    }
  };

  React.useEffect(() => {
    $(".money").mask("#.##0,00", { reverse: true });
  });

  const extract = new Extract();
  const pedido = new Pedido();

  const openDialogModal = (item) => {
    setSelectedHistoricItem(item);
    refDialogModal.current.openModal();
  }

  const openDialogModalPoints = () => refDialogModalPoints.current.openModal();
  const openSellPointsDialogModal = () => refSellPointsDialogModal.current.openModal();

  // Swall Infos
  const pointsAcquired = () => {
    Swal.fire({
      title: 'Pontos adquiridos',
      text: 'São pontos provenientes da venda de produtos na plataforma e têm validade de 2 anos, a contar da data da aquisição dos pontos.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Ok",
    })
  }

  const pointsPurchased = () => {
    Swal.fire({
      title: 'Pontos comprados',
      text: 'São pontos comprados na plataforma têm validade de 2 anos, a contar da compra dos pontos.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Ok",
    })
  }

  const bonusPoints = () => {
    Swal.fire({
      title: 'Pontos bonificados',
      text: 'São pontos recebidos como bônus em promoções e campanhas Scamb e têm validade de 4 meses a contar da data do recebimento dos pontos.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Ok",
    })
  }

  const spentPoints = () => {
    Swal.fire({
      title: 'Pontos utilizados',
      text: 'São pontos utilizados nas compras de produtos.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Ok",
    })
  }

  const awpPoints = () => {
    Swal.fire({
      title: 'Saldo aguardando liberação',
      text: 'Os pontos ficam bloqueados temporariamente, aguardando a conta compradora receber o produto para os pontos serem creditados na conta vendedora.',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Ok",
    })
  }

  const loadPointsExtract = (limit, startDate, endDate, filter) => {
    extract.loadPointExtract(limit, startDate, endDate, filter).then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setExtractHistoric(extractData.data.payload);

        if (extractData.data.payload.length < limit) {
          setHideSeeMore(true);
        } else {
          setHideSeeMore(false);
        }
      }

      if (extractData.data.payload.length === 0) return setIsLoading(true);


    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOPEX');
    })

    extract.loadPointsAwaitingApproval().then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setPointsAwaitingApproval(extractData.data.payload);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOPAEX')
    })

  }

  const loadLoyaltyExtract = () => {
    const actualDate = new Date();

    extract.loadLoyaltyExtract(undefined, undefined, 'ALL').then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
        setExtractItens(extractData.data.payload.listExtractItems);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOEXALL')
    })

    let dataInicial = new Date();
    let dataFinal = addMonths(dataInicial, 12);
    dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
    dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');

    extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal).then((extractExpirationData) => {
      if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
        setExtractExpirationItens(extractExpirationData.data.payload);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOEXEX')
    })

    extract.loadExtractAwaitingApproval(undefined, undefined).then((extractData) => {
      if (extractData.status === 200 && extractData.data.payload) {
        setAwpHistoric(extractData.data.payload);
        setLimitToShowAwp(4);
      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLOAEX')
    })

  }

  const loadIuguWallet = () => {

    extract.loadCashBalance().then((balanceData) => {
      if (balanceData.status === 200 && balanceData.data.payload) {
        const dataPayload = balanceData.data.payload;
        setPJBalance(data => [...data, dataPayload]);

        const moneyBalance = (type) => {
          let setMoney = "";
      
          const types = {
            balance: dataPayload?.balance
          }
      
          if (types[type] !== undefined) {
      
            if (types[type]?.indexOf("BRL") !== -1) {
              setMoney = types[type]?.split(" ")[0]
            } else {
              setMoney = types[type]?.split(" ")[1]
            }
          }

          return setMoney;

        }
        
        const money = moneyBalance("balance");
        setValue("fieldMoney", money);
        setOriginalAmount(money);
        setCheckMoney(money.replace(",","."));

      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLLIBL');
    })

    extract.loadBankTransfers().then((bankTransfers) => {
      if (bankTransfers.status === 200 && bankTransfers.data.payload) {
        const dataPayload = bankTransfers.data.payload;
        setPJBankTransfers(data => [...data, { type: "bank_transfers", dataPayload }]);

      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLBT')
    })

    extract.loadReceivables().then((receivables) => {
      if (receivables.status === 200 && receivables.data.payload) {
        const dataPayload = receivables.data.payload;
        setPJReceivables(data => [...data, { type: "receivables", dataPayload } ]);

      }
    }).catch((error) => {
      extract.formatErrorMessage(error.response, 'MWLRE');
    })

  }

  useEffect(() => {

    if (props.scamber && !props.scamber.cnpj) {
      loadPointsExtract(limit);
      loadLoyaltyExtract();
    } else if (props.scamber && props.scamber.cnpj) {
      loadIuguWallet();
    }

    setlastUpdate(new Date());
    $('.date').mask('00/00/0000');

  }, [props.scamber]); //eslint-disable-line

  const onSubmitFilter = (values) => {
    setStartDate(values.start_date);
    setEndDate(values.end_date);

    applyFilter(values.filter_type, null, true, values.start_date, values.end_date);

    setHistoryFilter(values.filter);
    setLimit(3)

    //loadPointsExtract(3, values.start_date, values.end_date, values.filter);
  }

  // * Tabs
  // * Material UI Tabs
  // * Props Tab
  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // * Material UI Tabs
  // * On Change Tab
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRadio = (e) => {

    const { id } = e.target;

    if (id === "extrato") {
      setFilterExtract(true);
    } else if (id === "pendentes") {
      setFilterPending(true);
    } else if (id === "expirar") {
      setFilterExpire(true);
    } else {
      setFilterExtract(false);
      setFilterPending(false);
      setFilterExpire(false);
    }
  }

  const openExtractItemDetail = (extractItem) => {
    setmodalValue(extractItem);

    switch (extractItem.type) {
      case 'Compra':
        pedido.findOrder(extractItem.orderID).then(response => {
          setFindOrder(response.data.payload);
        });
        return refDetailModalBuy.current.openModal();

      case 'Devolução':
        pedido.findOrder(extractItem.orderID).then(response => {
          setFindOrder(response.data.payload);
        });
        return refDetailModalBuy.current.openModal();

      case 'Venda':
        pedido.findOrder(extractItem.orderID).then(response => {
          setFindOrder(response.data.payload);
        });
        return refDetailModalSale.current.openModal();

      case 'Pontos':
        return refDetailModalBuyPoints.current.openModal();

      case 'Bonificação':
        return refDetailModalBonusPoints.current.openModal();

      default:
    }
  }

  const applyFilter = (filterType, months, isCustom, customStartDate, customEndDate) => {
    if (isCustom) {

      let dataInicial = customStartDate ? customStartDate + ' 00:00:00' : undefined;
      let dataFinal = customEndDate ? customEndDate + ' 23:59:59' : undefined;

      Swal.fire('Carregando... Aguarde')
      Swal.showLoading();

      if (filterType === 'extract') {
        extract.loadLoyaltyExtract(dataInicial, dataFinal, 'ALL').then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
            setLimitToShow(4);
            setExtractItens(extractData.data.payload.listExtractItems);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXALLFIC')
        })
      } else if (filterType === 'pending') {
        //PENDENTES
        extract.loadExtractAwaitingApproval(dataInicial, dataFinal).then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload) {
            setAwpHistoric(extractData.data.payload);
            setLimitToShowAwp(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOAEX')
        })
      } else {
        // A EXPIRAR
        extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal).then((extractExpirationData) => {
          Swal.close();
          if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
            setExtractExpirationItens(extractExpirationData.data.payload);
            setLimitToShowExpiration(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXEX')
        })
      }


    } else {

      let dataInicial, dataFinal = undefined;

      Swal.fire('Carregando... Aguarde')
      Swal.showLoading();

      if (filterType === 'extract') {

        if (months) {
          dataFinal = new Date();
          dataInicial = subMonths(dataFinal, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }

        extract.loadLoyaltyExtract(dataInicial, dataFinal, 'ALL').then((extractData) => {
          Swal.close();
          if (extractData.status === 200 && extractData.data.payload && extractData.data.payload.listExtractItems) {
            setLimitToShow(4);
            setExtractItens(extractData.data.payload.listExtractItems);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXALLFIC')
        })
      } else if (filterType === 'pending') {
        //PENDENTES
        if (months) {
          dataFinal = new Date();
          dataInicial = subMonths(dataFinal, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }
        extract.loadExtractAwaitingApproval(dataInicial, dataFinal).then((extractData) => {
          Swal.close();

          if (extractData.status === 200 && extractData.data.payload) {
            setAwpHistoric(extractData.data.payload);
            setLimitToShowAwp(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOAEX')
        })

      } else {
        // A EXPIRAR
        if (months) {
          dataInicial = new Date();
          dataFinal = addMonths(dataInicial, months);

          dataInicial = format(dataInicial, 'yyyy-MM-dd 00:00:00');
          dataFinal = format(dataFinal, 'yyyy-MM-dd 23:59:59');
        }


        extract.loadLoyaltyExtractExpiration(dataInicial, dataFinal).then((extractExpirationData) => {
          Swal.close();
          if (extractExpirationData.status === 200 && extractExpirationData.data.payload) {
            setExtractExpirationItens(extractExpirationData.data.payload);
            setLimitToShowExpiration(4);
          }
        }).catch((error) => {
          extract.formatErrorMessage(error.response, 'MWLOEXEX')
        })
      }

    }
  }

  const formatDateToShow = (date, format) => {
    if (date) {

      let onlyDate = date?.split(' ')[0];
      let onlyHour = date?.split(' ')[1];

      let [year, month, day] = onlyDate.split('-');
      let [hours, minutes, seconds] = onlyHour.split(':');

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      if (hours.length < 2)
        hours = '0' + hours;
      if (minutes.length < 2)
        minutes = '0' + minutes;
      if (seconds.length < 2)
        seconds = '0' + seconds;

      if (format === 'date-hour') return `${[day, month, year].join('/')} ${[hours, minutes, seconds].join(':')}`;
      if (format === 'date') return `${[day, month, year].join('/')}`;
      if (format === 'hour') return `${[hours, minutes, seconds].join(':')}`;
    } else {
      return '';
    }
  }

  const checkExpiration = (date) => {
    const expirationDate = new Date(date);
    const actualDate = new Date();

    if (actualDate <= expirationDate) {
      return false;
    } else {
      return true;
    }
  }

  //requestWithdrawal({ amount: 5 })
  const requestWithdrawal = (data) => {

    extract.requestWithdrawal(data).then(response => {
      if (response.status === 200 && response.data.payload) {
        window.location = "/transferencia";
      }

    }).catch(error => extract.formatErrorMessage(error.response, 'MWRWER'));
  }

  // * PJ Bank Informations
  let bank_info = [];
  if (props.scamber?.params_parceiro !== undefined) {
    bank_info.push({ ...props.scamber?.params_parceiro?.iugu?.validationObject })
  } else {
    bank_info = [];
  }

  const { bank, account_type, bank_ag, bank_cc, cnpj } = { ...bank_info[0] }

  function moneyFormat(money) {

    const current_money = money || "R$ 0.00";
    let my_money = "";
  
    if(current_money.indexOf("BRL") !== -1) {
      my_money += current_money.split(" ")[0]
    } else if(current_money.indexOf("R$") !== -1) {
      my_money += current_money.split(" ")[1]
    } else {
      return my_money = "0.00"
    }
  
    return my_money;
  }

  // * PJ Date Format
  const dateFormat = (date) => {
    const date_origin = date || "0000-00-00";
    const date_split = date_origin.split("-").reverse().join("/");
    return date_split;
  }

  function showOnlyDate (date) {
    const current_date = date || "0000-00-00T10:02:23-02:00";
    const format_date = current_date.split("T")[0];
    return dateFormat(format_date);
  }

  return (
    <>

      <ModalDialog ref={refDialogModal} dialogTitle="Detalhes" className="_dialog-details">

        {
          selectedHistoricItem ?
            (
              <ListContent bgColor="#FFFFFF">
                <ListItem title="Tipo de transação" className="flex">
                  <div className="detail-type">
                    <Icon icon={faBookmark} />
                    {selectedHistoricItem.type}
                  </div>
                </ListItem>

                <ListItem title="Status">
                  {formatTitle(selectedHistoricItem.status)}
                </ListItem>

                <ListItem title="Produto">
                  {selectedHistoricItem.title}
                </ListItem>

                { selectedHistoricItem.type === 'Pontos' ?
                  (<ListItem title={selectedHistoricItem.operation === 'C' ? "Data da compra" : "Data da venda"}>
                    {selectedHistoricItem.date}
                  </ListItem>)
                  :
                  (<ListItem title={selectedHistoricItem.operation === 'C' ? "Data da venda" : "Data da compra"}>
                    {selectedHistoricItem.date}
                  </ListItem>)
                }

                <ListItem title="Data aprovação do pagamento">
                  {selectedHistoricItem.status === 'PAGO' ? selectedHistoricItem.updated_date : '-'}
                </ListItem>

                <ListItem title={selectedHistoricItem.operation === 'C' ? "Pontos adquiridos" : "Pontos gastos"}>
                  <PricePoints value={selectedHistoricItem.points} />
                </ListItem>

                <ListItem title="Total gasto na transação" icon={<Button type="button" className="button-info"> <Icon icon={faQuestionCircle} /> </Button>}>
                  <Price value={parseFloat(selectedHistoricItem.value).toFixed(2)} />
                </ListItem>

              </ListContent>

            ) : <></>
        }

      </ModalDialog>

      {/* Dialog - Novas Modais */}

      {/*
        * Modal - Detalhes Venda de Produto
      */}
      <DetailModal ref={refDetailModalSale} dialogTitle="Venda de produto">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Total da venda">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Venda de produto
          </ListItem>
            <ListItem title="Data da venda">
              {modalValue.date.split(" ")[0]}
            </ListItem>
            {/* <ListItem title="Número do pedido ">
            {modalValue.orderID}
          </ListItem> */}
            <ListItem title="Produto">
              {findOrder && findOrder.itens.map(item => (
                <ProductThumbnail
                  key={item._id}
                  image={pedido.loadImage(item.fotos[0])}
                  title={item.titulo}
                  productUrl={`#!`}
                  onClick={() => refDetailModalBuy.current.closeModal()}
                >
                  {/*<Icon icon={faChevronRight} />*/}
                </ProductThumbnail>
              ))}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      {/*
        * Modal - Detalhes Compra de Produto
      */}
      <DetailModal ref={refDetailModalBuy} dialogTitle="Compra de produto">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Valor da compra">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Compra de produto
            </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
            {/* <ListItem title="Número do pedido ">
              {modalValue.orderID}
            </ListItem> */}
            <ListItem title="Produtos">
              {findOrder && findOrder.itens.map(item => (
                <ProductThumbnail
                  key={item._id}
                  image={pedido.loadImage(item.fotos[0])}
                  title={item.titulo}
                  productUrl={`#!`}
                  onClick={() => refDetailModalBuy.current.closeModal()}
                >
                  {/*<Icon icon={faChevronRight} />*/}
                </ProductThumbnail>
              ))}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      {/*
        * Modal - Detalhes Compra de Pontos
      */}
      <DetailModal ref={refDetailModalBuyPoints} dialogTitle="Compra de pontos">
        <h2 className="title">Detalhes</h2>
        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title="Total da compra">
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Compra de pontos
          </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      {/*
        * Modal - Detalhes Pontos Bonificados
      */}
      <DetailModal ref={refDetailModalBonusPoints} dialogTitle="Pontos bonificados">
        <h2 className="title">Detalhes</h2>

        {modalValue && (
          <ListContent bgColor="#FFFFFF">
            <ListItem title={`Quantidade de Pontos`}>
              <PricePointsIcon
                value={modalValue.points}
                color={modalValue.operation === 'C' ? "green" : "red"} />
            </ListItem>
            <ListItem title="Tipo">
              Pontos bonificados
            </ListItem>
            <ListItem title="Data da compra">
              {modalValue.date.split(" ")[0]}
            </ListItem>
          </ListContent>
        )}
      </DetailModal>

      {/*
        * Modal - Detalhes Pontos Bonificados
      */}
      <DetailModal ref={refTransferMoney} dialogTitle="Transferir dinheiro" className="__account-bank-details">

        <form className="form-transfer-money" onSubmit={handleSubmit(transferForm)} >
          <div className={`field-money ${errors.fieldMoney && "error"}`}>
            <div className="placeholder"> R$ </div>
            <input
              type="text"
              placeholder=""
              ref={register({ required: true })}
              defaultValue={originalAmount}
              onKeyUp={(e) => handleMoney(e)}
              name="fieldMoney"
              className="money"
            />
          </div>
          <span className="minimun-value-transfer">Valor minimo para ser transferido: <span>R$5.00</span> reais.</span>

          <AccountBankCard bank={String(bank)?.toUpperCase()} >

            <AccountBankItem title="Agência bancária" value={bank_ag} />
            <AccountBankItem title="Tipo de conta" value={String(account_type)?.toLowerCase()} />
            <AccountBankItem title="Número da conta" value={bank_cc} />
            <AccountBankItem title="Nome" value={props.scamber?.nome} />
            <AccountBankItem title="CNPJ" value={cnpj} />

          </AccountBankCard>

          <div className="wrap-submit">
            <button type="submit" className="btn-scamb-action color-red">
              Transferir dinheiro
            </button>
          </div>
        </form>
      </DetailModal>

      <Layout>
        <div id="view_my-wallet">
          <CurrentPage icon={<Icon icon={faWallet} />} title="Minha Carteira" subtitle={props.scamber?.cnpj === undefined && ("de pontos")} className={props.scamber?.cnpj === undefined && ("_i-wallet")} />

          <section className="_columns">

            <div className="_column _points">

              <section className="_block">

                <ListContent bgColor="#F9F9F9">
                  <ListItem title="Saldo disponível" onClick={openDialogModalPoints}>

                    {props.scamber?.cnpj === undefined && (
                      <PricePoints value={props.balance?.currentBalance || 0} />
                    )}

                    {props.scamber?.cnpj !== undefined && (
                      <Price value={moneyFormat(pjBalance[0]?.balance)} size="large" />
                    )}
                  </ListItem>
                </ListContent>

                {props.scamber?.cnpj === undefined && (
                  <button type="button" className="btn-scamb-action color-red">
                    <Link to="/comprar-pontos"> Comprar pontos </Link>
                  </button>
                )}

                {props.scamber?.cnpj !== undefined && (
                  <button type="button" className="btn-scamb-action color-red button-transfer" onClick={() => refTransferMoney.current.openModal()} disabled={!Number(checkMoney) >= 0.01 ? true : false}>
                    <Link to="#!"> Transferir </Link>
                  </button>
                )}
                
                {props.scamber?.cnpj !== undefined && (
                  <ListContent bgColor="#F1F1F1" className="__list-dsc">
                    <ListItem title="Bloqueado">
                      <Price value={moneyFormat(pjBalance[0]?.balance_in_protest)} />
                    </ListItem>
                    <ListItem title="A liberar">
                      <Price value={moneyFormat(pjBalance[0]?.receivable_balance)} />
                    </ListItem>
                  </ListContent>
                )}

                <div className="last-update">
                  {lastUpdate && `Atualizado às ${format(lastUpdate, 'HH:mm')} de ${format(lastUpdate, 'dd/MM/yyyy')}`}
                </div>

              </section>
            </div>

            <div className="_column _actions">
              <section className="_block">
                <div className="_med-container">

                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Tabs My Sales"
                    className={`${props.scamber?.cnpj ? "_material-tabs _material-tabs-pj" : "_material-tabs"}`}
                  >
                    <Tab label={props.scamber?.cnpj ? "Valores recebidos " : "Extrato"} {...tabProps(0)} />
                    <Tab label={props.scamber?.cnpj ? "Valores a receber" : "Pendentes"} {...tabProps(1)} />

                    {!props.scamber?.cnpj && (
                      <Tab label="A expirar" {...tabProps(2)} />
                    )}

                  </Tabs>

                  <TabPanel value={tabValue} index={0} className="tab-panel">

                    {props.scamber?.cnpj ? null : (
                      <div className="_filters">

                        <SelectFilter title="Selecione o período:">
                          <ButtonFilter inputType="radio" isChecked={true} inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('extract', null, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('extract', 1, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('extract', 3, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('extract', 6, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" onChange={(event) => { handleRadio(event); applyFilter('extract', 12, false); }} />
                          <ButtonFilter
                            inputType="radio"
                            inputName="perido"
                            id="extrato"
                            buttonText="Personalizado"
                            onChange={(event) => handleRadio(event)}
                          />
                        </SelectFilter>

                        {filterExtract && (
                          <div className="_filter-activity">
                            <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>

                              <div className="dates">
                                <DateField ref={refDateStart} title="Início">

                                  <input type='hidden' name="filter_type" value="extract" ref={register} />

                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="00/00/0000"
                                    name="start_date"
                                    aria-describedby="start-date"
                                    ref={register}
                                  />
                                </DateField>

                                <DateField ref={refDateEnd} title="Fim">

                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="00/00/0000"
                                    name="end_date"
                                    aria-describedby="start-date"
                                    ref={register}
                                  />
                                </DateField>
                              </div>

                              {/* <div className="form-group search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Procurar em atividades"
                                name="filter"
                                ref={register}
                              />
                              <button type="submit">
                                <Icon
                                  icon={faSearch}
                                  className="fa-question-circle"
                                />
                              </button>
                            </div> */}

                              <button type="submit" className="apply-filters btn-scamb-action color-blue">
                                Aplicar <Icon icon={faCheck} />
                              </button>

                            </form>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="content">
        
                      {pjBankTransfers[0]?.dataPayload?.length > 0 ? pjBankTransfers[0]?.dataPayload?.slice(0, limitToShow)?.map(({id, status, created_at, amount}) => (
                        <HistoryBlockPJ key={id} status={status} title="Transferência" date={showOnlyDate(created_at)} price={moneyFormat(amount)} />
                      )) : pjBankTransfers[0]?.type === "bank_transfers" && (<NothingMessage> Você ainda não possui nenhuma Transferência. </NothingMessage>)}

                      {extractItens?.slice(0, limitToShow).map((extractItem, index) => (

                        <HistoryBlock
                          key={index}
                          punctuated={true}
                          type={extractItem.type}
                          title={extractItem.title}
                          date={extractItem.date.split(" ")[0]}
                          points={extractItem.points}
                          color={extractItem.operation === 'C' ? "green" : "red"}
                          status={extractItem.status}
                          onClick={() => openExtractItemDetail(extractItem)}
                        />

                      ))}

                      {limitToShow < extractItens?.length && (
                        <div className="wrap-button-load-more">
                          {limitToShow < extractItens?.length ? (<button onClick={() => setLimitToShow(limitToShow + 4)} type="button" className="btn load-more">
                            Carregar mais
                          </button>) : <></>}
                        </div>
                      )}

                    </div>

                  </TabPanel>

                  <TabPanel value={tabValue} index={1} className="tab-panel">

                    {props.scamber?.cnpj ? null : (
                      <div className="_filters">

                        <SelectFilter title="Selecione o período:">
                          <ButtonFilter inputType="radio" isChecked={true} inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('pending', null, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('pending', 1, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('pending', 3, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('pending', 6, false); }} />
                          <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" onChange={(event) => { handleRadio(event); applyFilter('pending', 12, false); }} />
                          <ButtonFilter
                            inputType="radio"
                            inputName="perido"
                            id="pendentes"
                            buttonText="Personalizado"
                            onChange={(event) => handleRadio(event)}
                          />
                        </SelectFilter>

                        {filterPending && (
                          <div className="_filter-activity">
                            <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>

                              <div className="dates">
                                <DateField ref={refDateStart} title="Início">

                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="00/00/0000"
                                    name="start_date"
                                    aria-describedby="start-date"
                                    ref={register}
                                  />
                                </DateField>

                                <DateField ref={refDateEnd} title="Fim">

                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="00/00/0000"
                                    name="end_date"
                                    aria-describedby="start-date"
                                    ref={register}
                                  />
                                </DateField>
                              </div>

                              {/* <div className="form-group search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Procurar em atividades"
                                name="filter"
                                ref={register}
                              />
                              <button type="submit">
                                <Icon
                                  icon={faSearch}
                                  className="fa-question-circle"
                                />
                              </button>
                            </div> */}

                              <button type="submit" className="apply-filters btn-scamb-action color-blue">
                                Aplicar <Icon icon={faCheck} />
                              </button>

                            </form>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="content">
                      
                      {pjReceivables[0]?.dataPayload.length > 0 ? pjReceivables[0]?.dataPayload?.slice(0, limitToShowAwp)?.map(({id, status, client_share, scheduled_date}) => (

                          <HistoryBlockPJ key={id} status={status} title="Movimentação" date={dateFormat(scheduled_date)} price={moneyFormat(client_share)} />

                      )) : pjReceivables[0]?.type === "receivables" && (<NothingMessage> Você não possui nenhuma movimentação. </NothingMessage>)}

                      {awpHistoric?.slice(0, limitToShowAwp).map((extractItem, index) => (

                        <HistoryBlock
                          key={index}
                          punctuated={true}
                          type={extractItem.type}
                          title="Venda de Produto"
                          date={extractItem.date.split(" ")[0]}
                          points={extractItem.points}
                          color={extractItem.operation === 'C' ? "green" : "red"}
                          status={extractItem.status}
                          onClick={() => openExtractItemDetail(extractItem)}
                        />

                      ))}

                      {limitToShowAwp < awpHistoric?.length && (
                        <div className="wrap-button-load-more">
                          {limitToShowAwp < awpHistoric?.length ? (<button onClick={() => setLimitToShowAwp(limitToShowAwp + 4)} type="button" className="btn load-more">
                            Carregar mais
                          </button>) : <></>}
                        </div>
                      )}

                    </div>

                  </TabPanel>

                  <TabPanel value={tabValue} index={2} className="tab-panel">

                    <div className="_filters">
                      <SelectFilter title="Selecione o período:">
                        <ButtonFilter inputType="radio" inputName="perido" buttonText="Todos" onChange={(event) => { handleRadio(event); applyFilter('expiration', null, false); }} />
                        <ButtonFilter inputType="radio" inputName="perido" buttonText="1 mês" onChange={(event) => { handleRadio(event); applyFilter('expiration', 1, false); }} />
                        <ButtonFilter inputType="radio" inputName="perido" buttonText="3 meses" onChange={(event) => { handleRadio(event); applyFilter('expiration', 3, false); }} />
                        <ButtonFilter inputType="radio" inputName="perido" buttonText="6 meses" onChange={(event) => { handleRadio(event); applyFilter('expiration', 6, false); }} />
                        <ButtonFilter inputType="radio" inputName="perido" buttonText="1 ano" isChecked={true} onChange={(event) => { handleRadio(event); applyFilter('expiration', 12, false); }} />
                        <ButtonFilter
                          inputType="radio"
                          inputName="perido"
                          id="expirar"
                          buttonText="Personalizado"
                          onChange={(event) => handleRadio(event)}
                        />
                      </SelectFilter>

                      {filterExpire && (
                        <div className="_filter-activity">
                          <form className="_form form _search" onSubmit={handleSubmit(onSubmitFilter)}>

                            <div className="dates">
                              <DateField ref={refDateStart} title="Início">

                                <input type='hidden' name="filter_type" value="expiration" ref={register} />

                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="00/00/0000"
                                  name="start_date"
                                  aria-describedby="start-date"
                                  ref={register}
                                />
                              </DateField>

                              <DateField ref={refDateEnd} title="Fim">

                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="00/00/0000"
                                  name="end_date"
                                  aria-describedby="start-date"
                                  ref={register}
                                />
                              </DateField>
                            </div>

                            {/*<div className="form-group search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Procurar em atividades"
                                name="filter"
                                ref={register}
                              />
                              <button type="submit">
                                <Icon
                                  icon={faSearch}
                                  className="fa-question-circle"
                                />
                              </button>
                            </div>*/}

                            <button type="submit" className="apply-filters btn-scamb-action color-blue">
                              Aplicar <Icon icon={faCheck} />
                            </button>

                          </form>
                        </div>
                      )}
                    </div>

                    <div className="content">

                      <TimelinePoints>

                        {extractExpirationItens?.slice(0, limitToShowExpiration).map((itemExpiration) => (
                          <StatusExtract
                            key={itemExpiration.transactionID}
                            hasExpired={checkExpiration(itemExpiration.expirationPeriod)}
                            icon={<Icon icon={faCalendarAlt} />}
                            title="Finalizado"
                            className={checkExpiration(itemExpiration.expirationPeriod) ? 'no-active' : '_active'}
                            date={formatDateToShow(itemExpiration.expirationPeriod, 'date')}
                            hour="11:54:16"
                            points={itemExpiration.points} />
                        ))}
                        {/*<StatusExtract hasExpired={true} icon={<Icon icon={faCalendarAlt} />} title="Finalizado" className={false ? '_active' : 'no-active'} date="00/00/0000" hour="11:54:16" />
                        <StatusExtract hasExpired={false} icon={<Icon icon={faCalendarAlt} />} title="Finalizado" className={true ? '_active' : 'no-active'} date="00/00/0000" hour="11:54:16" />
                        <StatusExtract hasExpired={false} icon={<Icon icon={faCalendarAlt} />} title="Finalizado" className={true ? '_active' : 'no-active'} date="00/00/0000" hour="11:54:16" />
                        <StatusExtract hasExpired={false} icon={<Icon icon={faCalendarAlt} />} title="Finalizado" className={true ? '_active' : 'no-active'} date="00/00/0000" hour="11:54:16" />*/}
                      </TimelinePoints>

                      <div className="wrap-button-load-more">
                        {limitToShowExpiration < extractExpirationItens?.length ? (<button onClick={() => setLimitToShowExpiration(limitToShowExpiration + 4)} type="button" className="btn load-more">
                          Carregar mais
                        </button>) : <></>}
                      </div>

                    </div>
                  </TabPanel>

                </div>
              </section>
            </div>

            {/*<div className="_column _history">

            <section className="_block">

              <h6 className="_block-title">
                Histórico de atividades
                <Button 
                    className="filters"
                    color="primary"
                    autoFocus
                    type="button"
                    onClick={handleFilter}
                >
                  Filtros
                  <Icon icon={filter ? faTimes : faFilter} />
                </Button>  
              </h6>

              <div className={filter ? "_filters" : "_filters _is-hidden"}>
                <div className="_filter-activity">
                  <form className="_form form _search"  onSubmit={handleSubmit(onSubmitFilter)}>
                    
                    <div className="dates">
                    <DateField ref={refDateStart} title="Início">
                      
                      <input
                        type={dateStart ? "date" : "text"}
                        className="form-control"
                        placeholder="00/00/0000"
                        name="start_date"
                        aria-describedby="start-date"
                        //onClick={() => setDateStart(true)}
                        onFocus={() => { refDateStart.current.changeTypeField(); setDateStart(true) }}
                        ref={register}
                      />
                    </DateField>

                    <DateField ref={refDateEnd} title="Fim">
                      
                      <input
                        type={dateEnd ? "date" : "text"}
                        className="form-control"
                        placeholder="00/00/0000"
                        name="end_date"
                        aria-describedby="start-date"
                        //onClick={() => setDateEnd(true)}
                        onFocus={() => { refDateEnd.current.changeTypeField(); setDateEnd(true); }}
                        ref={register}
                      />
                    </DateField>
                    </div>

                    <div className="form-group search">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Procurar em atividades"
                        name="filter"
                        ref={register}
                      />
                      <button type="submit">
                        <Icon
                          icon={faSearch}
                          className="fa-question-circle"
                        />
                      </button>
                    </div>

                  </form>
                </div>
              </div>

              {isLoading && (
                <NothingMessage>
                  Você ainda não fez nenhuma transação.
                </NothingMessage>
              )}

              {extractHistoric.map((item, index) => (
                
                <HistoryBlock key={index} type={item.type} title={item.title} date={item.date.split(" ")[0]} 
                  points={item.operation === 'C'?'+' + item.points: '-' + item.points} color={item.operation === 'C'? "green": "red"} 
                  status={item.status} onClick={() => openDialogModal(item)}/>
              
              ))}

              <div className="_wrap-load-more">
                
                {!hideSeeMore?
                  (<Button 
                  className="load-more"
                  color="primary"
                  autoFocus
                  type="button"
                  disabled={hideSeeMore}
                  onClick={() => { setLimit(limit + 3); loadPointsExtract(limit + 3, startDate, endDate, historyFilter); }}
                  >
                    Carregar mais
                  </Button>): (<></>)
                }
                
              </div>
            </section>
          </div>*/}
          </section>

        </div>
      </Layout>
    </>
  );

}


const mapStateToProps = state => {
  return {
    scamber: state.scamberReducer.scamber,
    balance: state.balanceReducer.balance
  }
}

export default withRouter(connect(mapStateToProps, {})(MyWallet));
