import Service from './Service';

export default class Coupon extends Service {

  constructor() {
    super();
  }

  validateCouponCodeAndUpdataCart({ couponCode, cartId, website }) {
    return this.axiosInstance.get(`/coupon?couponCode=${couponCode}&cartId=${cartId}&website=${website}`);
  }

  findCoupons(couponsArray, website) {
    return this.axiosInstance.get(`/coupon/find?couponsArray=${couponsArray}&website=${website}`);
  }

  deleteCoupon(cartId, couponId) {
    return this.axiosInstance.delete(`/coupon`, { data: {cartId, couponId} }); 
  }

}
