import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// Alert
import Swal from "sweetalert";
import Swal2 from "sweetalert2";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faShoppingBag,
  faTimes
} from "@fortawesome/pro-light-svg-icons";

//=====================================================================================
// #8 - Import * Redux
//=====================================================================================
import { connect } from 'react-redux';

//=====================================================================================
// #8 - Import * Services
//=====================================================================================
import Favorito from '../services/Favorito';
import Scamber  from '../services/Scamber';
import Cart from '../services/Cart';
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

import formatNumberToBRL from './../utils/FormatNumberToBRL';


class FavoritesList extends Component {

  constructor(){
    super();

    // * Services
    this.favorito = new Favorito();
    this.scamber = new Scamber();
    this.cart = new Cart();

    this.state = {
      'favoritos':[],
      isChecked: false,
      checkedFavoriteOnCart: false,
    }
  }

  render() {

    const confirmDelete = () => {

      // Swal2.fire('Aguarde...');
      // Swal2.showLoading();
      this.favorito.delete(this.props.idFavorite)
        .then((response) => {Swal({title: "Anuncio removido dos favoritos!", icon: "success", buttons: 'Ok', dangerMode: true}); this.props.refresh()})
        .catch((error) => this.favorito.formatErrorMessage(error.response, 'DELFAV'));

      }

    const deleteInfo = () => {

        Swal({
          title: "Deletar favorito?",
          icon: "warning",
          buttons: [
            'Fechar',
            'Confirmar'
          ],
          dangerMode: true,
        }).then(function(isConfirm) {
          if (isConfirm) {
            confirmDelete()
          }
        });
      }

    const redirectWebSite = (websiteToGo, link) => {
        const url = window.location.href;
        const urlToGO = websiteToGo === 'marcas' ? 'marcas.' : '';
  
        if (url.indexOf('localhost') >= 0) {
            localStorage.setItem('website', websiteToGo);
            window.location.href = `http://localhost:3000${link}`;
            return false;
        } else if (url.indexOf('d1') >= 0) {
            window.location.href = `https://${urlToGO}d1.scamb.com.vc${link}`;
        } else if (url.indexOf('d2') >= 0) {
          window.location.href = `https://${urlToGO}d2.scamb.com.vc${link}`;
        } else if (url.indexOf('h1') >= 0) {
            window.location.href = `https://${urlToGO}h1.scamb.com.vc${link}`;
        } else if (url.indexOf('h2') >= 0) {
          window.location.href = `https://${urlToGO}h2.scamb.com.vc${link}`;
        } else {
            window.location.href = `https://${urlToGO}scamb.com.vc${link}`;
        }
  
    }

    const checkWhereRedirect = (link, type) => {
      type = type === 'shopping'? type: 'scamb';

      if(this.props.website === type) {
        this.props.history.push(link);
      } else {
        redirectWebSite(this.props.website === 'scamb' ? 'marcas' : 'scamb', link);
      }
    }


    return (
      <li>
        <a onClick={() => checkWhereRedirect(this.props.link, this.props.type)} className="product">
          <div className="product-image">
            <img src={this.props.image} alt=""></img>
          </div>
          <div className="product-info">
            <div className="product-info-title"> {this.props.title} </div>
            <div className={`__tag ${this.props.origin}`}> {this.props.origin === "shopping" && ("Marcas que circulam") || this.props.origin === "scamb" && ("Pessoas que circulam")} </div>
            <div className="_product-price _1x">
              {/* <div className="ui-item__discount-price">
                <span className="price-tag-fraction"> {this.props.oldPrice} </span>
                <span className="price-tag-symbol">pts</span>
              </div> */}

              {!this.props.type || this.props.type === 'scamb'?
              
                ( <div className="ui-item__price">
                    <span className="price-tag-fraction"> {this.props.price} </span>
                    <span className="price-tag-symbol">pts</span>
                  </div>
                ):
                (
                  <div className="ui-item__price">
                    <span className="price-tag-symbol">R$&nbsp;</span>
                    <span className="price-tag-fraction"> {formatNumberToBRL(this.props.cashPrice)} </span>
                  </div>
                )

              }

             
            </div>
          </div>
        </a>

        <div className="_actions">
          {this.props.checkFavoriteCart && !this.props.sold && (
          <button id="remover_sacola" type="button" className="btn btn-transparent" onClick={this.props.excludeFavorite}>
            <span className="check">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <FontAwesomeIcon icon={faShoppingBag} />
          </button>
          )}

          {!this.props.checkFavoriteCart && !this.props.sold && (
          <button id="adicionar_sacola" type="button" className="btn btn-transparent" onClick={this.props.clickFavorite}>
            <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
          </button>
          )}
          <button type="button" className="btn btn-transparent" onClick={deleteInfo}>
            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
          </button>
        </div>

        {this.props.sold && (
          <div className="sold">
            <span className="text">vendido</span>
          </div>
        )}
      </li>
    );
  }
}

const mapStateToProps = state => {
  return {
      scamber: state.scamberReducer.scamber,
      cart: state.cartReducer.cart,
      website: state.websiteReducer.website,
  }
}

export default withRouter(connect(mapStateToProps, {})(FavoritesList));