import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import { Wrapper, WrapperTitle, Title, Button, Content, FooterAction, ButtonAction, Text, } from "./styles";

function CheckoutBlock({ title = "", marginBottom = 0, buttonActionText = "", buttonAction, className, footerLink = "#!", footerLinkText, children }) {
    return (
        <Wrapper marginBottom={marginBottom}>
            <WrapperTitle>
                <Title> {title} </Title>
                {buttonActionText && (
                    <Button type="button" onClick={buttonAction}> {buttonActionText} </Button>
                )}
            </WrapperTitle>

            <Content className={className}>
                {children}
            </Content>
            {footerLinkText && (
                <FooterAction>
                    <ButtonAction>
                        <Link to={footerLink}>
                            <Text>{footerLinkText}</Text>
                            <Icon icon={["far", "chevron-right"]} />
                        </Link>
                    </ButtonAction>
                </FooterAction>
            )}
        </Wrapper>
    )
}

export default CheckoutBlock;
