//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";

//===============================================================================================================
// #3 - Import * Sweet Alert
//===============================================================================================================
import Swal1 from "sweetalert";
import Swal from 'sweetalert2';

//=====================================================================================
// #3 - Import * Imput Mask
//=====================================================================================
import InputMask from "react-input-mask";

//=====================================================================================
// #4 - Import * React Hook Form
//=====================================================================================
import { useForm } from "react-hook-form";

//===============================================================================================================
// #2 - Import * Account Layout Base
//===============================================================================================================
import Layout from "../../components/account/Layout";

//=====================================================================================
// #7 - Import * Boostrap Modals
//=====================================================================================
import { Modal } from "react-bootstrap";

//=====================================================================================
// #6 - Import * Material UI Components
//===================================================================================== 
import TextField from '@material-ui/core/TextField';
import {
  Grid,
  useMediaQuery,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";


//===============================================================================================================
// #5 - Import * Utils
//===============================================================================================================

import formatTitle from "../../utils/FormatTitle";

//===============================================================================================================
// #5 - Import * { Button } Bootstrap
//===============================================================================================================
import { Button } from "react-bootstrap";

//===============================================================================================================
// #6 - Import * UI Components
//===============================================================================================================
import { ListContent, ListItem } from "../../components/ui/list-content";
import { CurrentPage } from "../../components/ui/current-page";
import { NothingMessage } from "../../components/ui/nothing-message";

//===============================================================================================================
// #7 - Import * FontAwesome Icons
//===============================================================================================================
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt, faIdCard, faChevronLeft, faUserLock, faCheck } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt,  } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisV, faEllipsisH, faPlus } from "@fortawesome/pro-solid-svg-icons";
import Endereco from '../../services/Endereco';
import Scamber from '../../services/Scamber';

import { setScamber } from './../../redux/actions/scamberActions';
import {connect} from 'react-redux';
//===============================================================================================================
// #13 - Modal - Address
//===============================================================================================================
// JQuery
import $ from "jquery";
import "jquery-mask-plugin";


const ModalAddress = forwardRef((props, ref) => {

    // * States
    const [show, setShow] = useState(false);
  
    // * Function
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    useImperativeHandle(ref, () => ({
      openModal: () => handleShow(),
      closeModal: () => handleClose(),
    }));
  
    return (
      <>
        <Modal show={show} onHide={handleClose} className="full-width" centered>
          <Modal.Header closeButton>
            <section className="_view-head">
              <div className="_view-head-content">
                <Button className="_back-history btn" onClick={handleClose}>
                  <Icon icon={faChevronLeft} />
                </Button>
                <Modal.Title> {props.title} </Modal.Title>
                
              </div>
            </section>
          </Modal.Header>
          <Modal.Body>{props.children}</Modal.Body>
        </Modal>
      </>
    );
  });

//===============================================================================================================
// #14 - Component - Address Card
//===============================================================================================================
const AddressCard = (props) => {

    // * States
    const [options, setOptions] = useState(false);

    // * Functions
    const handleOptions = () => setOptions(!options);

    return (

        <div className="address-card">

            <div className="_cols">

                <div className="_col">

                    {options && (
                    <div className="actions">
                        <Button 
                            className="payment-action"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={props.edit}
                        >
                            <Icon icon={faEdit} />
                        </Button>
                        <Button 
                            className="payment-action"
                            color="primary"
                            autoFocus
                            type="button"
                            onClick={props.delete}
                        >
                            <Icon icon={faTrashAlt} />
                        </Button>
                    </div>
                    )}

                    <div className="payment-preview-info">
                        {!options && (
                        <div className="icon">
                            <Icon icon={faMapMarkerAlt} />
                        </div>
                        )}
                        <ListContent>
                        <span className="tag">
                            {formatTitle(props.nickname)}
                        </span>
                            <ListItem title={props.cep}> {props.address} </ListItem>
                        </ListContent>
                    </div>
                </div>

                <div className="_col">
                    
                    <Button 
                        className="address-action"
                        color="primary"
                        autoFocus
                        type="button"
                        onClick={handleOptions}
                    >
                        <Icon icon={options ? faEllipsisH : faEllipsisV} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

//===============================================================================================================
// #16 - Default
//===============================================================================================================
const useStyles = makeStyles({
    center: {
      margin: "auto",
    },
    resize:{
      fontSize: '10px'
    },
    inputSize: {
      height: 50
    }
  });

function MyAddress (props) {

    // * Styles
    const matches = useMediaQuery("(min-width:600px)");
    const classes = useStyles();

    // * Refs
    const refAddressModal = useRef();
    const refChangeAddressModal = useRef();

    // * States
    const [cep, setCep] = useState("");
    const [addressId, setAddressId] = useState("");

    let endereco = new Endereco();


    // * React Hook Form
    const { register, handleSubmit, errors, formState, reset } = useForm({mode: "onChange"});

    // * Functions Modals
    const closeModalAddress = () => refAddressModal.current.closeModal();
    const closeChangeModalAddress = () => refChangeAddressModal.current.closeModal();


    // * Delete Credit Card
    const deleteAddressCard = (item) => {
        
        Swal1({
            title: "Deletar endereço?",
            text: `Ao clicar em confirmar, o endereço
            será excluido da sua conta.`,
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                endereco.delete(item)
                    .then((response)=>{
                        Swal1({
                            title: "Endereço removido!",
                            icon: "success",
                            buttons: [false, "Ok"],
                            dangerMode: true,
                        })
                        loadScamberData();
                    })
                    .catch((error)=>{
                        Swal.fire({
                            type: "info",
                            html: error.response.data.message,
                            title: "",
                        });
                    })
            }
        });

    }

    let findAddressByCEP = () => {
        if (cep.length === 9) {
          endereco
            .findCep(cep)
            .then((address) => {
              let formatedAddress = {
                cep: address.data.cep,
                estado: address.data.estado,
                logradouro: address.data.logradouro,
                cidade: address.data.localidade,
                estado: address.data.uf,
                bairro: address.data.bairro,
                complemento: address.data.complemento,
              };
    
              if (address.data) {
                reset(formatedAddress);
              }
            })
            .catch((error) =>
              Swal.fire("info", "Não foi possível consultar o endereço", "")
            );
        }
    };

    useEffect(() => {
        $(".cep").mask("00000-000");
        $(".house_number").mask("00000");
    });

    const loadScamberData = () => {
        Swal.fire("Aguarde...");
        Swal.showLoading();
        const scamber = new Scamber();
        scamber
          .findScamber()
          .then((scamberData) => {
            Swal.close();
            if (scamberData.status === 200)
              props.setScamber(scamberData.data.payload);
          })
          .catch((error) => {
            Swal.fire({
              type: "info",
              html: error.response.data.message,
              title: "",
            });
          });
    };

    // * Functions - React Mask
    const handleCepChange = (e) => setCep(e.target.value);

    // * Create New Address
    const onSubmitAddress = (data, e) => {

        // * Close Modal
        closeModalAddress();
        
        reset();

        endereco.create(data)
            .then((response) => {

                // * Reset Form Data

                if (response.status === 200) {
                    Swal.fire({
                        title: "Endereço adicionado!",
                        icon: "success",
                        timer: 4000,
                        timerProgressBar: true,
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Ok",
                    }).then((its_ok) => {
                        // * Success Message
                        loadScamberData();
                    });
                }
            })
            .catch((error) =>
                endereco.formatErrorMessage(error.response, 'MECREN'),
            );
            
    };

    // * Change Address
    const onChangeSubmitAddress = (data) => {

        data.addressId = addressId;

        endereco.update(data)
            .then((response) => {
                if (response.status === 200) {
                    // * Close Modal
                    closeChangeModalAddress();

                    Swal.fire({
                        title: "Endereço alterado!",
                        icon: "success",
                        timer: 4000,
                        timerProgressBar: true,
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Ok",
                    }).then((its_ok) => {
                        // * Success Message
                        loadScamberData();
                    });
                }
            })
            .catch((error) =>
                endereco.formatErrorMessage(error.response, 'MAUPEN'),
            );
    };

    const openModalEditAddress = (address) => {
        
        setAddressId(address._id);
        
        let formatedAddress = {
            cep: address?.cep,
            estado: address?.estado,
            logradouro: address?.logradouro,
            cidade: address?.cidade,
            bairro: address?.bairro,
            apelido: address?.apelido,
            numero: address?.numero,
            complemento: address?.complemento,
        };
        
        reset(formatedAddress);
                
        refChangeAddressModal.current.openModal();
    }
    

    return (
        <>
        <ModalAddress ref={refAddressModal} title="Adicionar endereço">
        <form onSubmit={handleSubmit(onSubmitAddress)}>
        <h1 className="_content-title">
            Preencha seus dados de endereço abaixo.
        </h1>
          {/*  Form Body */}
          <section className="_form-body">
              
            <Grid container spacing={2}>
              
            <Grid item xs={12}>
            <TextField
                id="outlined-cc-number"
                label="Cep **"
                type="text"
                defaultValue={cep}
                onChange={(e) => setCep(e.target.value)}
                onBlur={findAddressByCEP}
                variant="outlined"
                name="cep"
                size={matches ? "small" : "small"}
                error={!!errors.cep}
                inputProps={{className:'cep'}}
                fullWidth
                inputRef={register({
                required: true,
                minLength: 8,
                })}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
                id="outlined-neighborhood"
                label="Estado *"
                variant="outlined"
                size={matches ? "small" : "small" }
                name="estado"
                error={!!errors.estado}
                fullWidth
                inputProps={{ maxLength: 2 }}
                inputRef={register({
                    required: true,
                })}
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Grid>
            <Grid item xs={6}>
            <TextField
                id="outlined-neighborhood"
                label="Cidade *"
                variant="outlined"
                size={matches ? "small" : "small" }
                name="cidade"
                error={!!errors.cidade}
                fullWidth
                inputRef={register({
                    required: true,
                })}
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Grid>
            <Grid item xs={8}>
            <TextField
                id="outlined-address"
                label="Endereço *"
                variant="outlined"
                size={matches ? "small" : "small" }
                name="logradouro"
                error={!!errors.logradouro}
                fullWidth
                inputRef={register({
                required: true,
                })}
                InputLabelProps={{
                shrink: true,
                }}
            />
            </Grid>
            <Grid item xs={4}>
            <TextField
                id="outlined-address-number"
                label="Número *"
                type="number"
                variant="outlined"
                inputProps={{className:'house_number'}}
                size={matches ? "small" : "small" }
                name="numero"
                error={!!errors.numero}
                fullWidth
                inputRef={register({
                required: true,
                })}
                InputLabelProps={{
                shrink: true,
                }}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                id="outlined-neighborhood"
                label="Bairro *"
                variant="outlined"
                size={matches ? "small" : "small" }
                name="bairro"
                error={!!errors.bairro}
                fullWidth
                inputRef={register({
                required: true,
                })}
                InputLabelProps={{
                shrink: true,
                }}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                id="outlined-complement"
                label="Complemento"
                variant="outlined"
                name="complemento"
                size={matches ? "small" : "small" }
                fullWidth
                inputRef={register({
                    required: false,
                  })}
                InputLabelProps={{
                shrink: true,
                }}
            />
            </Grid>
            <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Apelido *"
                    type="text"
                    variant="outlined"
                    name="apelido"
                    placeholder="Exemplo: Casa, Trabalho.."
                    size={matches ? "small" : "small"}
                    error={!!errors.apelido}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
        
            </Grid>

            {true && (
            <div className="mark-default-address">
                <label>
                    <input type="checkbox" ref={register} name="principal" />

                    <div className="text">
                        Marcar como meu novo endereço padrão
                    </div>

                    <div className="check">
                        <Icon icon={faCheck} />
                    </div>
                </label>
            </div>
            )}

        </section>

            <Button
            type="submit"
            className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
            color="primary"
            autoFocus
            style={{height: (matches ? 35 : 40)}}
            disabled={
                !formState.dirty || (formState.dirty && !formState.isValid)
            }
            >
                Confirmar
                <Icon icon={faCheck} />
            </Button>
            {/*  END ./ Form Body */}
        </form>
    </ModalAddress>

        <ModalAddress ref={refChangeAddressModal} title="Alterar endereço">
            <form onSubmit={handleSubmit(onChangeSubmitAddress)}>
            <h1 className="_content-title" />
            {/*  Form Body */}
            <section className="_form-body">
                
                <Grid container spacing={2}>
                
                <Grid item xs={12}>
                <TextField
                    id="outlined-cc-number"
                    label="Cep *"
                    onChange={handleCepChange}
                    defaultValue={cep}
                    onBlur={findAddressByCEP}
                    type="text"
                    variant="outlined"
                    name="cep"
                    size={matches ? "small" : "small"}
                    error={!!errors.cep}
                    inputProps={{className:'cep'}}
                    fullWidth
                    inputRef={register({
                    required: true,
                    minLength: 8,
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Estado *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="estado"
                    error={!!errors.estado}
                    fullWidth
                    inputProps={{ maxLength: 2 }}
                    inputRef={register({
                        required: true,
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    id="outlined-neighborhood"
                    label="Cidade *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="cidade"
                    error={!!errors.cidade}
                    fullWidth
                    inputRef={register({
                        required: true,
                    })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>
                <Grid item xs={8}>
                <TextField
                    id="outlined-address"
                    label="Endereço *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="logradouro"
                    error={!!errors.logradouro}
                    fullWidth
                    inputRef={register({
                    required: true,
                    })}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={4}>
                <TextField
                    id="outlined-address-number"
                    label="Número *"
                    type="number"
                    variant="outlined"
                    inputProps={{className:'house_number'}}
                    size={matches ? "small" : "small" }
                    name="numero"
                    error={!!errors.numero}
                    fullWidth
                    inputRef={register({
                    required: true,
                    })}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    id="outlined-neighborhood"
                    label="Bairro *"
                    variant="outlined"
                    size={matches ? "small" : "small" }
                    name="bairro"
                    error={!!errors.bairro}
                    fullWidth
                    inputRef={register({
                    required: true,
                    })}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    id="outlined-complement"
                    label="Complemento"
                    variant="outlined"
                    name="complemento"
                    size={matches ? "small" : "small" }
                    fullWidth
                    inputRef={register({
                        required: false,
                      })}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-cc-number"
                    label="Apelido *"
                    type="text"
                    variant="outlined"
                    name="apelido"
                    placeholder="Exemplo: Casa, Trabalho.."
                    size={matches ? "small" : "small"}
                    error={!!errors.apelido}
                    fullWidth
                    inputRef={register({
                      required: true,
                      minLength: 2,
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
            
                </Grid>

                <div className="mark-default-address">
                    <label>
                        <input type="checkbox" ref={register} name="principal" />

                        <div className="text">
                            Marcar como meu novo endereço padrão
                        </div>

                        <div className="check">
                            <Icon icon={faCheck} />
                        </div>
                    </label>
                </div>

            </section>

                <Button
                type="submit"
                className={"btn btn-primary btn-fluid btn-submit-modal "+classes.center}
                color="primary"
                autoFocus
                style={{height: (matches ? 35 : 40)}}
                >
                    Confirmar
                    <Icon icon={faCheck} />
                </Button>
                {/*  END ./ Form Body */}
            </form>
        </ModalAddress>

        <Layout>
        <div id="view_my-address">

        <div className="_block-title">
            <CurrentPage icon={<Icon icon={faIdCard} />} title="Meus Endereços" />

            <div className="_action">
                <Button className="btn-add-payment" onClick={() => {reset({cep: ''}); refAddressModal.current.openModal()}}>
                    Adicionar
                    <Icon icon={faPlus} />
                </Button>
            </div>
        </div>

        <section className="_columns">

            <div className="_column">

                <section className="_block">
                    {props.scamber?.endereco.length ? 
                        props.scamber.endereco.map((item,index)=>
                            <AddressCard
                                key={index}
                                delete={() => deleteAddressCard(item._id)}
                                edit={ () => { openModalEditAddress(item); }}
                                nickname={item.apelido? item.apelido:''}
                                cep={item.cep}
                                address="R Bartolomeu bueno da silva, n359 - Bairro Água Verde, Curitiba - Paraná"
                                address={`${item.logradouro}, n${item.numero} - ${item.bairro}, ${item.cidade} - ${item.estado}`}
                            />
                        )
                    :
                        <section className="_block">
                            <NothingMessage>
                                Você não tem endereços cadastrados.<br/> para cadastrar um novo endereço clique no botão adicionar
                            </NothingMessage>
                        </section>
                    }

                </section>
            </div>
        </section>
        </div>
        </Layout>
        </>
    )
}

const mapStateToProps = state => {
    return {
      scamber: state.scamberReducer.scamber,
  
    }
}

export default connect(mapStateToProps, { setScamber })(MyAddress);