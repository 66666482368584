import Service from './Service';

class CancelarPedidoShopping extends Service {

    constructor() {
        super()
    }

    reversalOrderShopping(orderId){
        return this.axiosInstance.post(`/cordershopping/reversalorder`, { orderId: orderId });
    }
}

export default CancelarPedidoShopping;