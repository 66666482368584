import styled from "styled-components";
import Button from "../../components/design/form/button";

export const Wrapper = styled.div`
    /* position: fixed;
    top: 0;
    left: 0; */
    background-color: ${({ theme }) => theme.color.white};
    height: 100%;
    width: 100%;
    z-index: 997 !important;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
`;

export const LandingContent = styled.div`

    #fullpage {
        margin-bottom: 0;

        .section.fp-section.fp-table {

            .fp-tableCell {
                vertical-align: middle;
            }
        }
    }
`;

export const FullPageSection = styled.div`
    
`;

export const Content = styled.div`
    @media (min-width: 240px) {
        margin-top: 130px;
    }
    
    @media (min-width: 360px) {
        margin-top: 140px;
    }

    .HeadlineBlock2 {
        
        @media (min-width: 240px) {
            margin-bottom: 0.5rem;
            font-size: 12px;
        }
        
        @media (min-width: 360px) {
            margin-bottom: 0.9375rem;
            font-size: 0.875rem;
        }
    }

    .MuiTabs-root._material-tabs {
        border-bottom: 1px solid ${({ theme }) => theme.color.light};
        margin-bottom: 0.9375rem;
        
        .MuiTabs-scroller.MuiTabs-fixed {

            .MuiTabs-flexContainer {

                justify-content: space-between;

                .MuiButtonBase-root.MuiTab-root {
                    width: 50%;

                    &:last-child {
                        .MuiTab-wrapper {
                            color: #681e36;
                        }

                    }
                }
            }
        }
    }
`;

export const MainHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0.9375rem;
    background-color: ${({ theme }) => theme.color.white};
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
`;

export const WrapperLogo = styled.div`
    margin-bottom: 0.9375rem;
`;

export const FixedHeadline = styled.div`
    width: 100%;
    a {
        display: block;
        font-family: ${({ theme }) => theme.font.nunito};
        background-color: ${({ theme }) => theme.color.blue};
        width: 100%;
        font-size: 0.75rem;
        font-weight: ${({ theme }) => theme.fontWeight.fw700};
        color: ${({ theme }) => theme.color.white};
        text-align: center;
        padding: 0.9375rem;
    }
`;

export const Headline = styled.h1`
    font-family: ${({ theme }) => theme.font.nunito} !important;
    background-color: ${({ theme }) => theme.color.white};
    font-size: 0.8125rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    text-align: center;
    line-height: 1.7;
`;

export const ImageBox = styled.div`
    border-radius: 0.25rem;
    overflow: hidden;
    margin-top: 0.9375rem;
    position: relative;
`;

export const ImageFull = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

const imageText = styled.p`
    font-size: 13px;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    text-align: center;
    margin: 0;
`;

export const ImageText = styled(imageText)`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.625rem;
    background: rgb(255,255,255);
background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 95%);
background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 95%);
background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 95%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
`;

export const ImageTextTitle = styled(imageText)`
    margin-top: 0.625rem;
`;

export const WrapperButton = styled.div`
    text-align: center;
    margin: 10px 0;

    @media (min-width: 360px) {
        margin-top: 30px;
    }
    
    button {
        padding: 0.5rem 1.264rem;
    }

`;

export const BannerWithText = styled.div`
    margin-bottom: ${props => props.marginBottom + "px" || 0 + "px"};
`;

export const WrapBanner = styled.div``;

export const Banner = styled(ImageFull)``;

export const BannerTitle = styled.h1`
    font-family: ${({ theme }) => theme.font.nunito} !important;
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    margin: 12px 0 4px 0;

    @media (min-width: 240px) {
        font-size: 13px;
    }
    
    @media (min-width: 360px) {
        font-size: 0.8125rem;
    }
`;

export const BannerText = styled.p`
    text-align: center;
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    text-align: center;
    line-height: 1.7;
    margin-bottom: 0;

    @media (min-width: 240px) {
        font-size: 11px;
    }
    
    @media (min-width: 360px) {
        font-size: 12px;
    }
`;

export const IconTextBlock = styled.div`
    text-align: center;
    margin-bottom: ${props => props.marginBottomBlock + "px" || 0 + "px"};
`;

export const IconImage = styled.div`
    margin-bottom: 0.625rem;
`;

export const Icone = styled.img`
    width: auto;
    height: auto;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

export const Title = styled.h1`
    font-family: ${({ theme }) => theme.font.nunito} !important;
    font-size: 13px;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2px;
`;

export const Subtitle = styled.p`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
`;

// *********************************************************************

export const LayoutBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0.625rem; */
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom + "px" : "0"};;

    ${({ theme }) => theme.media(`xs`, `
        flex-direction: column;
    `)}

    ${({ theme }) => theme.media(`sm`, `
        flex-direction: row;
    `)}

`;

export const Description = styled.div`
    width: 100%;
`;

const Styles = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
`;

export const Text = styled(Styles)`
    text-align: ${({ textAlign }) => textAlign ? textAlign : "left"};
    max-width: 440px;

    ${({ theme }) => theme.media(`xs`, `
        font-size: 12px;
    `)}

    ${({ theme }) => theme.media(`sm`, `
        font-size: 16px;
    `)}
`;