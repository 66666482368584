import styled from "styled-components";

export const LayoutBox = styled.div `

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom+"px" : "0"};;

    ${({ theme }) => theme.media(`xs`, `
        flex-direction: column;
    `)}

    ${({ theme }) => theme.media(`sm`, `
        flex-direction: row;
    `)}
`;

export const Description = styled.div `
    width: 100%;
`;

const Styles = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.blue};
`;

export const Title = styled(Styles) `
    text-align: ${({textAlign}) => textAlign ? textAlign : "left"};

    ${({ theme }) => theme.media(`xs`, `
        font-size: 16px;
        margin-bottom: 8px;
        `)}

    ${({ theme }) => theme.media(`sm`, `
        font-size: 24px;
        margin-bottom: 10px;
    `)}
`;

export const Text = styled(Styles) `
    text-align: ${({textAlign}) => textAlign ? textAlign : "left"};
    max-width: 440px;

    ${({ theme }) => theme.media(`xs`, `
        font-size: 12px;
    `)}

    ${({ theme }) => theme.media(`sm`, `
        font-size: 16px;
    `)}
`;

export const Image = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
`;

export const WrapHeroText = styled.div`
    background-color: ${({theme}) => theme.color.light};
    width: 100%;
    margin-bottom: 15px;
    padding: 30px 15px;
`;

export const HeroText = styled(Text)`
    text-align: center !important;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    color: ${props => props.color}
`;