//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import React from "react";

//===============================================================================================================
// #1 - Base Imports
//===============================================================================================================
import { Link } from "react-router-dom";

//===============================================================================================================
// #1 - Import * Material UI
//===============================================================================================================
import useMediaQuery from '@material-ui/core/useMediaQuery';

//===============================================================================================================
// #4 - Import * Icons
//===============================================================================================================
import { FontAwesomeIcon as I } from "@fortawesome/react-fontawesome";

//===============================================================================================================
// #2 - Import * UI Components
//===============================================================================================================
import { Container } from "../../design/layout/container";

//===============================================================================================================
// #2 - Import * Styled Components
//===============================================================================================================
import { Tab, TabButton as HTabButton, Icon as TabIcon, Column as HColumn } from "./styles";

export const Column = ({ children }) => <HColumn className="column">{children}</HColumn>;
export const TabButton = ({ active, activeColor, children }) => <HTabButton activeColor={activeColor} active={active}>{children}</HTabButton>;
export const Icon = ({ children }) => <TabIcon>{children}</TabIcon>;
 
export const HeaderTabs = ({ tabColor, children }) => {

    // * Matches Media Query
    const matches = useMediaQuery('(min-width:800px)');

    return (
        <Tab tabColor={tabColor}>
            <Container withContainer={matches ? true : false}>
                {children}
            </Container>
        </Tab>
    )
}