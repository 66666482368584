import styled from "styled-components";

export const Wrapper = styled.div`
    border: 1px solid #E3E3E3;
    border-radius: 0.1875rem;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.3125rem 0.9375rem;
`;

export const Mark = styled.div`
    height: 20px;
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.iceBold};
    border-radius: 100px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.color.iceBold};

    svg {
        height: 13px;
        width: auto;

        path {
            fill: ${({ theme }) => theme.color.gray};
            opacity: 0.5;
        }
    }
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0;

    input {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    input:checked ~ .mark {
        background-color: ${({ theme }) => theme.color.blue};
        border-color: ${({ theme }) => theme.color.blue};

        svg {
            path {
                fill: ${({ theme }) => theme.color.white};
                opacity: 1;
            }
        }
    }
`;

export const RadioInput = styled.input``;

export const WrapIcon = styled.div`
    margin-bottom: 0;
    line-height: 0;
    padding-left: 15px;
    padding-right: 15px;

    svg.truck {
        height: 18px;
        width: auto;
        path {
            fill: ${({ theme }) => theme.color.blue};
        }
    }
`;


export const Title = styled.h1`
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-size: 0.625rem;
    `;

export const ValueInfo = styled.div`
    position: relative;
    top: 2px;
`;

export const ButtonChevronDown = styled.div`
    width: 44px;
    height: 44px;
    background-color: ${({ theme }) => theme.color.transparent};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    svg {
        height: 14px;
        width: auto;

        path {
            fill: ${({ theme }) => theme.color.blue};
            opacity: 0.6;
        }
    }
`;

export const Footer = styled.div`
    background-color: ${({ theme }) => theme.color.iceBold};
    font-family: ${({ theme }) => theme.font.nunito} !important;
    font-weight: ${({ theme }) => theme.fontWeight.fw700};
    color: ${({ theme }) => theme.color.black};
    font-size: 0.75rem;
    text-align: center;
    padding: 0.1875rem 0.9375rem;
    `
export const Content = styled.div`
    /* padding-left: 0.9375rem; */
    /* padding-right: 0.9375rem; */
    border-top: 0.0625rem solid ${({ theme }) => theme.color.iceBold};
    
    ._product-thumbnail.small {
        /* padding-left: 0; */
        /* padding-right: 0; */
        border-bottom: 0.0625rem dashed ${({ theme }) => theme.color.iceBold};

        &:last-child {
            border-bottom: 0;
        }

        ._col {
            &:first-child {
                width: 100%;
            }
        }
    }

    .box-information {

        ._set-user-location {
            margin-bottom: 0;
        }
    }
`;