//=====================================================================================
// #1 - Base Imports
//=====================================================================================
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { createHashHistory } from 'history'

//=====================================================================================
// #2 - Import * Utils
//=====================================================================================
import Convert from "../utils/ConvertPointsToPrice"

//=====================================================================================
// #2 - Import * UI Components
//=====================================================================================
import { AdvertiserCard } from "../components/ui/advertiser-card";
import { ProductThumbnail } from "../components/ui/product-thumbnail";

//=====================================================================================
// #3 - Import * Sweet Alert
//=====================================================================================
import Swal from 'sweetalert2';
import CoverIMG from "./../assets/images/cover.png";

//=====================================================================================
// #4 - Import * FontAwesome Icons
//=====================================================================================
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingBag, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
//=====================================================================================
// #5 - Import * Services
//=====================================================================================
import Favorite from '../services/Favorito';
import Scamber from '../services/Scamber';
import Cart from '../services/Cart';
import Anuncios from '../services/Ad';

import { connect } from 'react-redux';

//=====================================================================================
// #6 - Import * Modals
//=====================================================================================
import ShoppingCartDialog from "../components/modals/shopping-cart";

//=====================================================================================
// #7 - Import * Bootstrap Components
//=====================================================================================
import Button from 'react-bootstrap/Button'
import EventEmitter from './../services/Events';

import formatNumberToBRL from './../utils/FormatNumberToBRL';

class GridProduct extends Component {

    constructor() {
        super();
        // * Create React Refs
        this.refShoppingCart = React.createRef();

        this.favorite = new Favorite();
        this.scamber = new Scamber();
        this.cart = new Cart();
        this.anuncios = new Anuncios();
        this.convert = new Convert();
        this.history = createHashHistory();

        this.state = {
            activeIconBag: false,
            activeIconHeart: false,
            isChecked: false,
            iconFavorite: true,
            iconShoppingBag: false,
            itensTick: [],
            checkedList: false,
            disableInsert: false
        }
    }

    // * Open Dialog Shopping Cart
    showShoppingCardDialog = () => {
        this.setState({ activeIconBag: !this.state.activeIconBag, isChecked: !this.state.isChecked });
    };

    componentDidMount() {
        if (this.props.scamber) {
            this.loadIfIsFavorited(this.props.idAd)
        };

        EventEmitter.subscribe('startItemCart', () => {
            this.setState({ disableInsert: true })
        })

        EventEmitter.subscribe('endItemCart', () => {
            this.setState({ disableInsert: false })
        })
    }

    componentDidUpdate() {
        if (this.props.cart && !this.state.checkedList) {
            this.setState({ checkedList: true })

            let ad = this.props.cart.itens?.filter(item => item._id === this.props.ad._id);

            if (ad.length > 0) {
                this.setState({ isChecked: true })
            }
        }
    }

    loadIfIsFavorited = (idAd) => {
        this.favorite.find(idAd)
            .then((response) => {

                this.setState({ iconFavorite: false })
                this.setState({ activeIconHeart: !false });
            })
            .catch((error) => {
                if (error.response.status === 404) return this.setState({ iconFavorite: true })
                this.favorito.formatErrorMessage(error.response, 'FIDFAV')
            })
    }

    // Delete Cart Item
    deleteCartItem = (itemASerRemovido) => {
        EventEmitter.dispatch('startItemCart');

        Swal.fire({
            title: "Deletar produto?",
            icon: "warning",
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            dangerMode: true
        })
            .then((isConfirm) => {
                if (isConfirm) {
                    this.cart.deleteCart(itemASerRemovido, null, this.props.website)
                        .then((result) => {
                            Swal.fire({ title: "Produto removido!", icon: "success", buttons: 'Ok', dangerMode: true });
                            EventEmitter.dispatch('cartChange');
                            EventEmitter.dispatch('endItemCart');
                            this.showShoppingCardDialog();
                        })
                        .catch((error) => {
                            this.cart.formatErrorMessage(error.response, 'DELCAR')
                            EventEmitter.dispatch('endItemCart');
                        })
                }
            });
    }

    handleFavorite = (idAd) => {

        this.favorite.create({ 'idAd': idAd })
            .then((response) => this.loadIfIsFavorited(idAd))
            .catch((error) => {
                this.favorito.formatErrorMessage(error.response, 'INSFAV')
            })
    }

    redirectLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
    }

    insertProductIntoCart = (ad) => {
        EventEmitter.dispatch('startItemCart');

        let scamberAd = typeof (ad.scamber) === 'string' ? ad.scamber : ad.scamber[0]._id;
        if (this.props.cart && (this.props.cart.scamber_vendedor._id !== scamberAd)) {

            return Swal.fire({
                title: 'Você já tem itens adicionados a sua sacola que são de outro vendedor. Porém, apenas é possível finalizar compra de produtos de um único vendedor por vez.',
                icon: 'info',
                html:
                    'Deseja limpar a sacola e inserir este novo item?',
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                    'Sim!',
                cancelButtonText:
                    'Não',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.insertProduct(ad);
                }
            })

        } else {
            this.insertProduct(ad);
        }

    }

    insertProduct = (ad) => {
        let requestObj = {
            ad: ad._id
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        this.cart.insertCart(requestObj, this.props.website)
            .then((response) => {
                //callback()
                Toast.fire({
                    icon: 'success',
                    title: 'Novos itens adicionados à sacola'
                })

                EventEmitter.dispatch('cartChange');

                EventEmitter.dispatch('endItemCart');
                this.showShoppingCardDialog();
            })
            .catch((error) => {
                this.cart.formatErrorMessage(error.response, 'INSCAR');
                EventEmitter.dispatch('endItemCart');
            })
    }

    handleCart = (ad) => {
        if (!this.state.activeIconBag) {
            this.insertProductIntoCart(ad);
        }
    }

    render() {

        return (
            <>

                <ShoppingCartDialog
                    ref={this.refShoppingCart}
                    dialogTitle="Minha Sacola"
                    className="shopping-cart-dialog"
                    checkoutUrl="/checkout/"
                >

                    <AdvertiserCard
                        storeLogo={this.scamber.loadImage(this.props.cart?.scamber_vendedor.imagens?.avatar ? this.props.cart?.scamber_vendedor.imagens.avatar : CoverIMG)}
                        storeLink={`/loja/${this.props.cart?.scamber_vendedor?.minha_loja?.url}`}
                        storeTitle={this.props.cart?.scamber_vendedor?.minha_loja?.titulo}
                        storeLocation={this.props.cart?.scamber_vendedor?.endereco[0].cidade + ' - ' + this.props.cart?.scamber_vendedor.endereco[0].estado}
                        button={true}
                    />

                    {this.props.cart?.itens ?
                        this.props.cart.itens.map((product) => (

                            <ProductThumbnail
                                image={this.scamber.loadImage(product.fotos[0] ? product.fotos[0] : CoverIMG)}
                                title={product.titulo}
                                // oldPrice={product.}
                                price={product.pontos_por?.$numberDecimal}
                                freightType={product.operador_logistico ? 'correios' : 'entrega_maos'}
                                productUrl={`/produto?id=${product._id}`}
                            >

                                <Button
                                    className="trash-item"
                                    color="primary"
                                    autoFocus
                                    type="button"
                                    onClick={() => this.deleteCartItem(product._id, null, this.props.website)}
                                >

                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </ProductThumbnail>

                        ))
                        : <></>}

                </ShoppingCartDialog>

                <div className="_ui-item-wrapper">
                    <div className="_ui-item">
                        <Link to={this.props.link} style={{ backgroundImage: `url(${this.props.image})` }} className="_ui-item-image">
                            {/* <img src={this.props.image} className="_ui-item-image_block" alt="" /> */}

                            {this.props.weight && (
                                <div className="tag-weight">
                                    {this.props.weight}
                                </div>
                            )}
                        </Link>
                        <div className={`_ui-item-bottom ${this.props.cashback ? "cashback" : ""}`}>
                            <div className="_col-left">
                                <Link to={this.props.link} className="_product-title">
                                    {this.props.title}
                                </Link>
                            </div>

                            <div className="_col-right">

                                {!this.props.type || this.props.type === 'scamb' ?
                                    (
                                        <div className="_product-price _1x">
                                            <div className="ui-item__discount-price _is-hidden">
                                                <span className="price-tag-fraction">{this.props.discount}</span>
                                                <span className="price-tag-symbol">pts</span>
                                            </div>
                                            <div className="ui-item__price">
                                                <span className="price-tag-fraction">{this.props.price}</span>
                                                <span className="price-tag-symbol">pts</span>
                                            </div>

                                        </div>
                                    ) :
                                    (
                                        <div className="_product-price _1x real">
                                            <div className="ui-item__discount-price _is-hidden">
                                                <span className="price-tag-symbol">R$&nbsp;</span>
                                                <span className="price-tag-fraction">{this.props.cashDiscount}</span>
                                            </div>
                                            <div className="ui-item__price">
                                                <span className="price-tag-symbol">R$&nbsp;</span>
                                                <span className="price-tag-fraction">{formatNumberToBRL(this.props.cashPrice)}</span>
                                            </div>

                                        </div>
                                    )
                                }


                                <div className="actions">

                                    <button
                                        id={this.state.isChecked? 'remover_sacola': 'adicionar_sacola'}
                                        disabled={this.state.disableInsert}
                                        type="button"
                                        className={this.state.activeIconBag ? "btn btn-transparent active" : "btn btn-transparent"}
                                        onClick={() => { this.handleCart(this.props.ad) }}
                                    >
                                        {this.state.isChecked ?
                                            (
                                                <span onClick={() => this.deleteCartItem(this.props.ad._id, null, this.props.website)} className="check">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </span>
                                            ) : (<></>)
                                        }
                                        <FontAwesomeIcon icon={faShoppingBag} />
                                    </button>

                                    <button
                                        id={this.state.iconFavorite? 'adicionar_favoritos': 'remover_favoritos'}
                                        type="button"
                                        className={this.state.activeIconHeart ? "btn btn-transparent active" : "btn btn-transparent"}
                                        onClick={this.props.scamber ? () => this.handleFavorite(this.props.idAd) : (e) => this.redirectLogin(e)}
                                    >
                                        <FontAwesomeIcon icon={this.state.iconFavorite ? faHeart : faHeartSolid} />
                                    </button>
                                </div>

                            </div>

                            <div className="_col-bottom">

                                {this.props.website === 'scamb' ?
                                    (
                                        <>
                                            <div className="text">
                                                Equivalente a
                                            </div>
                                            <div className="price">
                                                R${this.convert.setConversion(Number(this.props.price))}
                                            </div>
                                        </>
                                    ) :
                                    this.props.cashback ?
                                        (
                                            <div className="wrap-cashback">
                                            <div className="cashback-tag">
                                                <div className="text">
                                                    Ganhe
                                                </div>
                                                <div className="price">
                                                    {/*formatNumberToBRL(this.props.cashback)*/}
                                                    { this.props.cashback }  pts
                                                </div>
                                            </div>
                                            </div>
                                        ) : (<div className="wrap-cashback"><div className="cashback-tag no"><div className="text">Não possui pointsback</div></div></div>)

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    };

};


const mapStateToProps = state => {
    return {
        scamber: state.scamberReducer.scamber,
        cart: state.cartReducer.cart,
        website: state.websiteReducer.website
    }
}

export default withRouter(connect(mapStateToProps, {})(GridProduct));