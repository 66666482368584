//=====================================================================================
// #1 - Import * Styled Components
//=====================================================================================
import styled from 'styled-components';

export const Main = styled.header `
    background-color: ${props => props.theme[props.site].color};
    color: #FFFFFF;
`; 

export const Columns = styled.div `
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
    
    ${props => props.theme.media("xs", `
        flex-direction: column;
    `)}
    
    ${props => props.theme.media("sm", `
        flex-direction: row;
    `)}

    .column-1,
    .column-2 {
        ${props => props.theme.media("xs", `
            width: 100%;
        `)}

        ${props => props.theme.media("sm", `
            width: auto;
        `)}
    }

    .column-1 {

        .column-item {

            &:nth-child(1), 
            &:nth-child(3) {
                
                ${props => props.theme.media("xs", `
                    max-width: 138px;
                    width: 100%;
                `)}
                
                ${props => props.theme.media("sm", `
                    max-width: none;
                    width: auto;
                `)}
            }

            &:nth-child(1) {
                text-align: left;
            }

            &:nth-child(3) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .column-2 {
        
        ${props => props.theme.media("xs", `
            margin-top: 20px;
        `)}
        
        ${props => props.theme.media("sm", `
            margin-top: 0;
            padding-right: 0.9375rem;
        `)}

        .column-item {
            width: 100%;
        }
    }
`;

export const Column = styled.div `
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    /* Column #1 */
    &:nth-child(1) {

        flex-grow: 1;

        .column-item {

            // * Mobile Menu Categories Toggle
            &:nth-child(1) {
                ${props => props.theme.media("sm", `
                    display: none;
                `)}
            }

            // * Buy Points
            &:nth-child(3) {

                ${props => props.theme.media("sm", `
                    display: none;
                `)}
            }

            // * Showing My Points
            &:nth-child(4) {

                ${props => props.theme.media("sm", `
                    display: none;
                `)}
            }

            // * Login
            &:nth-child(5) {

                ${props => props.theme.media("sm", `
                    display: none;
                `)}
            }
        }
    }

    &:nth-child(3) {

        ${props => props.theme.media("xs", `
            display: none;
        `)}

        ${props => props.theme.media("sm", `
            display: flex;
        `)}
    }
`;

export const ColumnItem = styled.div((props) =>  {

    const { theme } = props;
    const { color, media } = Object.assign({}, theme);
    
    return `
    background-color: ${color.white};
    font-size: 10px;
    text-align: center;
    color: ${color.black};
    
    ${media("md", `
        padding-left: 0.4688rem;
        padding-right: 0.4688rem;
    `)}
`});
